import React from 'react';
import * as yup from 'yup';
import cx from 'classnames';
import { Controller } from 'react-hook-form';
import { GrDocumentUpload } from 'react-icons/gr';
import { TbMessage2Plus } from 'react-icons/tb';
import { TiDelete } from 'react-icons/ti';
import { useGetTemplateMessagesQuery } from '@@/services/templateMessage';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectInput from '@@/components/Inputs/SelectInput';

export const schema = yup
  .object({
    message: yup.string(),
  })
  .required();

const MessageForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const { data: templateMessages } = useGetTemplateMessagesQuery();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const files = watch('files');

  return (
    <div>
      <StandardInput
        register={register}
        id="message"
        label="Nouveau message"
        placeholder="Nouveau message"
        error={errors?.message}
        type="textarea"
      />
      <label
        htmlFor="files"
        className="mt-4 flex flex-row items-center text-main font-main cursor-pointer"
      >
        <GrDocumentUpload size={30} />
        <span className={cx('ml-2 underline')}>Envoyer un document</span>

        <Controller
          control={control}
          name="files"
          render={({ field }) => {
            return (
              <input
                {...field}
                value={field.value?.fileName}
                onChange={(event) => {
                  field.onChange(event.target?.files);
                }}
                type="file"
                id="files"
                className="hidden"
              />
            );
          }}
        />
      </label>

      <div className="my-2">
        {!!files?.length &&
          Array.from(files).map((file: any, i) => (
            <div key={i} className="flex flex-row items-center">
              <p className="font-main text-main">{file.name}</p>
              <button
                className="ml-4"
                onClick={() => setValue('files', undefined)}
              >
                <TiDelete className="text-red-500" size={20} />
              </button>
            </div>
          ))}
      </div>

      {!!templateMessages?.length && (
        <div>
          <p className="flex flex-row items-center">
            <TbMessage2Plus size={30} />
            <span className="underline font-main text-main ml-2">
              Utiliser un message type
            </span>
          </p>
          <SelectInput
            register={register}
            id="template"
            options={[{ id: '', label: 'Sélectionnez un message' }].concat(
              templateMessages.map((message) => ({
                id: message.body,
                label: message.title,
              })),
            )}
            callback={(message: string) => {
              form.setValue('message', message);
              form.setValue('template', '');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MessageForm;
