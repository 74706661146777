import React, { useMemo } from 'react';
import QRCode from 'qrcode';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { translateSpeciality } from '@@/constants/practitioner';
import { mainColor, biggerFontSize, smallFontSize } from '@@/constants/pdf';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px',
    paddingBottom: '10px',
    borderBottom: `1px solid ${mainColor}`,
  },
  logo: {
    width: '130px',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    color: mainColor,
    fontFamily: 'Helvetica-Bold',
    fontSize: biggerFontSize,
  },
  speciality: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: smallFontSize,
    marginBottom: '4px',
  },
  text: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: '10px',
  },
  qr: {
    width: '80px',
    height: '80px',
  },
});

const Header: React.FC<{
  practitioner?: Unpatient.User;
}> = ({ practitioner }) => {
  const speciality = useMemo(
    () => translateSpeciality(practitioner?.speciality),
    [practitioner?.speciality],
  );
  return (
    <View style={styles.container} fixed>
      <View style={styles.col}>
        <Text style={styles.title}>
          Dr {practitioner?.firstName} {practitioner?.lastName}
        </Text>

        {!!speciality && <Text style={styles.text}>{speciality}</Text>}

        {!!practitioner?.rpps && (
          <Text style={styles.text}>RPPS : {practitioner.rpps}</Text>
        )}

        {!!practitioner?.am && (
          <Text style={styles.text}>AM : {practitioner.am}</Text>
        )}

        {!!practitioner?.cdom && (
          <Text style={styles.text}>CDOM : {practitioner.cdom}</Text>
        )}

        {!!practitioner?.secureEmail && (
          <Text style={styles.text}>{practitioner.secureEmail}</Text>
        )}
        <Text style={styles.text}>Tel: +33 7 56 95 47 18</Text>
        <Text style={styles.text}>Fax: +43-125-359-628</Text>
      </View>
      <Image style={styles.logo} src="/logo.png" />
      <Image
        src={QRCode.toDataURL('https://www.unpatient.ai', {
          margin: 0,
          errorCorrectionLevel: 'H',
        })}
        style={styles.qr}
      />
    </View>
  );
};

export default Header;
