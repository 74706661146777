import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { BsPrescription2 } from 'react-icons/bs';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { useGetMeQuery } from '@@/services/user';
import {
  useGetUserPrescriptionsQuery,
  useDeletePrescriptionMutation,
  usePatchPrescriptionMutation,
} from '@@/services/prescription';
import { translatePrescriptionType } from '@@/constants/prescription';
import SectionTitle from '@@/components/SectionTitle';

const PrescriptionSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: me } = useGetMeQuery();
  const { data: prescriptions } = useGetUserPrescriptionsQuery(userId, {
    skip: !userId,
  });
  const [
    deletePrescription,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeletePrescriptionMutation();

  const [patchPrescription, { isLoading: isPatchLoading }] =
    usePatchPrescriptionMutation();

  const onDelete = useCallback(
    (prescriptionId: string) => {
      if (confirm('Souhaitez vous vraiment supprimer cette ordonnance ?')) {
        deletePrescription(prescriptionId);
      }
    },
    [deletePrescription],
  );

  const toggleFavorite = useCallback(
    (prescription: Unpatient.Prescription) => {
      patchPrescription({
        id: prescription.id,
        patientId: prescription.patientId,
        isTemplate: !prescription.isTemplate,
      });
    },
    [patchPrescription],
  );

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`La prescription a bien été supprimée`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  return (
    <div>
      <SectionTitle title="Ordonnances" className="mt-12 mb-6" />
      <div className="flex flex-col space-y-4 my-4">
        {prescriptions?.map((prescription) => (
          <div key={prescription.id} className="flex flex-row items-center">
            <Link
              to={`/patients/${userId}/prescriptions/${prescription.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 border border-gray-400 rounded-lg flex flex-row items-center"
            >
              <BsPrescription2 size={24} />
              <span className="ml-1 font-main text-main">
                {moment(prescription.createdAt).format('DD/MM/YYYY')}
                &nbsp;-&nbsp;
                {prescription.title ||
                  translatePrescriptionType(prescription.type)}
              </span>
            </Link>

            {prescription.practitionerId === me?.id && (
              <button
                className="ml-2"
                onClick={() => toggleFavorite(prescription)}
                disabled={isPatchLoading || isDeletionLoading}
              >
                {prescription.isTemplate ? (
                  <MdFavorite size={24} />
                ) : (
                  <MdFavoriteBorder size={24} />
                )}
              </button>
            )}

            {prescription.practitionerId === me?.id && (
              <button
                className="ml-2"
                onClick={() => onDelete(prescription.id)}
                disabled={isDeletionLoading}
              >
                <RiDeleteBinLine size={24} />
              </button>
            )}
          </div>
        ))}
      </div>

      {!prescriptions?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucune ordonnance</span>
          <IoWarningOutline size={24} />
        </p>
      )}

      <Link
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white w-fit mt-8"
        to={`/patients/${userId}/prescriptions/new`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-1">Nouvelle ordonnance</span>
      </Link>
    </div>
  );
};

export default PrescriptionSection;
