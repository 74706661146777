export enum Genders {
  M = 'male',
  F = 'female',
  O = 'other',
}

export const selectInputGenders = [
  {
    id: Genders.M,
    label: 'Homme',
  },
  {
    id: Genders.F,
    label: 'Femme',
  },
  {
    id: Genders.O,
    label: 'Autre',
  },
];

export const translateGender = (gender: string) => {
  switch (gender) {
    case Genders.M:
      return 'Homme';
    case Genders.F:
      return 'Femme';
    case Genders.O:
      return 'Autre';
    default:
      return 'Non renseigné';
  }
};

export const getTitle = (gender: string) => {
  switch (gender) {
    case Genders.M:
      return 'M.';
    case Genders.F:
      return 'Mme';
    default:
      return 'M. ou Mme';
  }
};

export const translateAntecedentSection = (antededent: string) => {
  switch (antededent) {
    case 'cardiovascular':
      return 'Cardio-vasculaire';
    case 'neurology':
      return 'Neurologie';
    case 'pulmonology':
      return 'Pneumologie';
    case 'nephrology':
      return 'Néphrologie';
    case 'urology':
      return 'Urologie';
    case 'digestion':
      return 'Système digestif';
    case 'hematology':
      return 'Hématologie';
    case 'rheumatology':
      return 'Rhumatologie';
    case 'orthopedics':
      return 'Orthopédie';
    case 'dermatology':
      return 'Dermatologie';
    case 'endocrinology':
      return 'Endocrinologie';
    case 'gynecology':
      return 'Gynécologie';
    case 'ent':
      return 'O.R.L';
    case 'infectiousDiseases':
      return 'Maladies infectieuses';
    case 'ophthalmology':
      return 'Ophtalmologie';
    case 'allergies':
      return 'Allergies';
    case 'dental':
      return 'Dentaire';
    case 'psychiatry':
      return 'Santé mentale';
    case 'other':
      return 'Autre';

    default:
      return antededent;
  }
};
