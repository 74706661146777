import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { api } from '@@/services/api';
import {
  useGetChatsQuery,
  useGetChatsStatusQuery,
  useDisconnectMutation,
} from '@@/services/whatsapp';
import { Statuses } from '@@/constants/whatsapp';
import SectionTitle from '@@/components/SectionTitle';
import Loader from '@@/components/Loader';
import Message from '@@/components/Message';

const ConversationsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useGetChatsStatusQuery();

  const { data: chats } = useGetChatsQuery(null, {
    skip: data?.status !== Statuses.READY,
  });

  const [disconnect, { isSuccess }] = useDisconnectMutation();
  const reinitialize = useCallback(() => {
    disconnect();
    dispatch(api.util.invalidateTags(['Chats', 'ChatMessages', 'ChatContact']));
  }, [disconnect]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Reinitialisation en cours...');
    }
  }, [isSuccess]);

  const nukeBtn = useMemo(() => {
    return (
      <button
        className="bg-cyan-400 p-2 rounded-lg m-4 h-fit"
        onClick={() => reinitialize()}
      >
        <span className="text-white font-main">Reinitialiser la connexion</span>
      </button>
    );
  }, [reinitialize]);

  if (!data) {
    return null;
  }

  if (!chats?.length && !data.qr && data.status === Statuses.LOADING) {
    return (
      <div className="m-8 flex flex-row items-center justify-center w-full">
        <Loader />
        <p className="ml-2 font-main text-main text-lg">Chargement...</p>
      </div>
    );
  }

  if (!chats?.length && data.status === Statuses.LOADING) {
    return (
      <>
        <QRCode value={data.qr} className="mt-8 mx-auto" />
        {nukeBtn}
      </>
    );
  }

  if (!chats?.length) {
    return (
      <div className="flex flex-col">
        <div className="m-8 flex flex-row items-center justify-center w-full">
          <Loader />
          <p className="ml-2 font-main text-main text-lg">
            Chargement des conversations ...
          </p>
        </div>
        {nukeBtn}
      </div>
    );
  }

  return (
    <div className="m-4 w-full h-full overflow-scroll">
      <SectionTitle title={`Chats (${chats.length})`} className="mb-6" />
      <div className="absolute right-4 top-14">{nukeBtn}</div>

      <ul className="grid grid-cols-1 md:grid-cols-3">
        {chats.map((chat, i) => (
          <Link
            key={i}
            to={`/conversations/${chat.id.server}/${chat.id.user}`}
            className="block z-10 m-2 p-2 rounded-lg border-gray-400 border h-44 overflow-hidden"
          >
            <div className="flex flex-row items-center justify-between font-main text-main">
              <p className="font-bold">{chat.name}</p>
              <p className="ml-8 italic text-sm">
                {moment.unix(chat.timestamp).calendar()}
              </p>
            </div>
            <Message message={chat.lastMessage} disableLinks />
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default ConversationsPage;
