import { api } from '@@/services/api';

export const doctolibApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<Doctolib.Patient[], void>({
      query: () => `doctolib/patients`,
      providesTags: ['DoctolibPatients'],
    }),
    getAppointments: builder.query<Doctolib.Appointment[], void>({
      query: () => `doctolib/appointments`,
      providesTags: ['DoctolibAppointments'],
    }),
    getDocuments: builder.query<Doctolib.Document[], void>({
      query: () => `doctolib/documents`,
      providesTags: ['DoctolibDocuments'],
    }),
    getDocumentDoctolib: builder.query<string, string>({
      query: (documentId) => ({
        url: `doctolib/documents/${documentId}`,
        responseHandler: async (response) => {
          const contentType = response.headers.get('Content-Type');
          const blob = await response.blob();

          return URL.createObjectURL(
            contentType ? new Blob([blob], { type: contentType }) : blob,
          );
        },
      }),
    }),
    getConversations: builder.query<Doctolib.Conversation[], void>({
      query: () => `doctolib/conversations`,
      providesTags: ['DoctolibConversations'],
    }),
    getConversationMessages: builder.query<
      Doctolib.ConversationMessage[],
      string
    >({
      query: (conversationId) => `doctolib/conversations/${conversationId}`,
      providesTags: ['DoctolibConversationMessages'],
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useGetAppointmentsQuery,
  useGetDocumentsQuery,
  useGetDocumentDoctolibQuery,
  useGetConversationsQuery,
  useGetConversationMessagesQuery,
} = doctolibApi;
