import React from 'react';
import { FieldError, Controller } from 'react-hook-form';
import classNames from 'classnames';

type RadioOption = {
  id: string;
  label: string;
};

const RadioInput: React.FC<{
  id: string;
  control: any;
  options: RadioOption[];
  error?: FieldError;
  disabled?: boolean;
}> = ({ id, control, options, error, disabled }) => {
  const errorComponent = error?.message && (
    <p className="italic font-main text-sm text-red-500 m-1">{error.message}</p>
  );

  return (
    <div className="mt-4">
      <div className="flex flex-row">
        {options.map((opt: RadioOption) => (
          <Controller
            key={opt.id}
            control={control}
            name={id}
            render={({ field }) => {
              if (!opt.id) {
                return <div className="hidden" />;
              }

              const checked = field.value === opt.id;

              return (
                <button
                  onClick={() => {
                    field.onChange(opt.id);
                  }}
                  disabled={!!disabled}
                  className={classNames(
                    'hover:cursor-pointer font-main p-2 mr-4 rounded-lg border-2 border-cyan-400',
                    checked
                      ? 'bg-cyan-400 text-white'
                      : 'bg-white text-cyan-400',
                    disabled && 'hover:cursor-not-allowed',
                  )}
                >
                  {opt.label}
                </button>
              );
            }}
          />
        ))}
      </div>
      {errorComponent}
    </div>
  );
};

export default RadioInput;
