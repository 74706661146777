import { Svg, Rect, Line } from '@react-pdf/renderer';

const CalendarIcon = ({ size, color }: { size: number; color: string }) => (
  <Svg height={size} width={size}>
    <Rect
      width="18"
      height="18"
      x="3"
      y="4"
      rx="2"
      ry="2"
      stroke={color}
      fill="none"
      strokeWidth="2"
    />
    <Line x1="16" y1="2" x2="16" y2="6" stroke={color} strokeWidth="2" />
    <Line x1="8" y1="2" x2="8" y2="6" stroke={color} strokeWidth="2" />
    <Line x1="3" y1="10" x2="21" y2="10" stroke={color} strokeWidth="2" />
  </Svg>
);

export default CalendarIcon;
