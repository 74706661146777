import { api } from '@@/services/api';
import { Roles } from '@@/constants/user';
import { convertToFormData } from '@@/utils/formData';
import { useRevalidator } from 'react-router-dom';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<Unpatient.User, void>({
      query: () => `users/me`,
      providesTags: ['Users'],
    }),
    getAllPatients: builder.query<Unpatient.User[], void>({
      query: () => `users?role=${Roles.PATIENT}`,
      providesTags: ['Users'],
    }),
    getPatient: builder.query<Unpatient.User, string>({
      query: (patientId) => `/users/${patientId}`,
      providesTags: ['Users'],
    }),
    getPopulatedPatient: builder.query<Unpatient.User, string>({
      query: (patientId) => `/users/${patientId}?populate=1`,
      providesTags: ['Users'],
    }),
    searchPatients: builder.query<Unpatient.User[], string>({
      query: (s) => `/users/search?s=${s}&role=${Roles.PATIENT}`,
      providesTags: ['Users'],
    }),
    findPatientByPhone: builder.query<Unpatient.User, string>({
      query: (phone) => `/users/find-by-phone?phone=${phone}`,
      providesTags: ['Users'],
    }),
    createPatient: builder.mutation<Unpatient.User, Partial<Unpatient.User>>({
      query: ({ photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.set('file', photo);
        }

        return {
          url: `/users`,
          method: 'POST',
          body: convertToFormData(
            {
              ...body,
              role: Roles.PATIENT,
            },
            formData,
          ),
          formData: true,
        };
      },
      invalidatesTags: ['Users'],
    }),
    patchUser: builder.mutation<Unpatient.User, Partial<Unpatient.User>>({
      query: ({ id, photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.set('file', photo);
        }

        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Users', 'Histories'],
    }),
    handleSignature: builder.mutation<
      Unpatient.User,
      { id: string; blob: Blob }
    >({
      query: ({ id, blob }) => {
        const formData = new FormData();

        if (blob) {
          formData.set('file', blob);
        }

        return {
          url: `/users/${id}/signature`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Users', 'Histories'],
    }),
    sendPaymentLink: builder.mutation<
      void,
      { userId: string; trialPeriodInDays?: number; coupon: string }
    >({
      query: ({ userId, ...body }) => {
        return {
          url: `/users/${userId}/payment-link`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [],
    }),
    getAllPractitioners: builder.query<Unpatient.User[], void>({
      query: () => `users?role=${Roles.PRACTITIONER}`,
      providesTags: ['Practitioners'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetPopulatedPatientQuery,
  useGetAllPatientsQuery,
  useGetPatientQuery,
  useSearchPatientsQuery,
  useCreatePatientMutation,
  usePatchUserMutation,
  useHandleSignatureMutation,
  useSendPaymentLinkMutation,
  useFindPatientByPhoneQuery,
  useGetAllPractitionersQuery,
} = userApi;
