import { api } from '@@/services/api';

export const stripeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStripeMetrics: builder.query<Unpatient.StripeMetrics, void>({
      query: () => `stripe/metrics`,
    }),
    getStripeCoupons: builder.query<{ id: string; name: string }[], void>({
      query: () => `stripe/coupons`,
    }),
  }),
});

export const { useGetStripeMetricsQuery, useGetStripeCouponsQuery } = stripeApi;
