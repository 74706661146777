export enum ScheduledMessageStatuses {
  SCHEDULED = 'scheduled',
  CANCELED = 'canceled',
  ERROR = 'error',
  SENT = 'sent',
}

export const translateScheduledMessageStatus = (
  status: ScheduledMessageStatuses,
) => {
  switch (status) {
    case ScheduledMessageStatuses.SCHEDULED:
      return 'En attente';

    case ScheduledMessageStatuses.CANCELED:
      return 'Annulé';

    case ScheduledMessageStatuses.SENT:
      return 'Envoyé';

    case ScheduledMessageStatuses.ERROR:
      return 'En erreur';

    default:
      return 'Inconnu';
  }
};
