import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useGetEventsQuery } from '@@/services/event';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: events } = useGetEventsQuery();

  return (
    <div className="mx-auto my-4">
      <Calendar
        localizer={localizer}
        events={events?.map((event) => ({
          title: event.userId?.firstName
            ? `[${event.userId.firstName.slice(0, 1)}. ${event.userId.lastName}] - ${event.name}`
            : event.name,
          start: moment(event.startDate).toDate(),
          end: moment(event.endDate || event.startDate).toDate(),
          userId: event.userId,
          url: event.url,
        }))}
        startAccessor="start"
        endAccessor="end"
        defaultView="agenda"
        messages={{
          week: 'Semaine',
          work_week: 'Semaine de travail',
          day: 'Jour',
          month: 'Mois',
          previous: 'Antérieur',
          next: 'Prochain',
          today: `Aujourd'hui`,
          agenda: 'Ordre du jour',

          showMore: (total) => `+${total} plus`,
        }}
        onSelectEvent={(event) => {
          if (event.url) {
            window.open(event.url, 'newtab');
          } else {
            navigate(`/patients/${event.userId._id}`);
          }
        }}
      />
    </div>
  );
};

export default CalendarPage;
