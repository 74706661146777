export enum InvoiceStatuses {
  OPEN = 'open',
  PAID = 'paid',
  DRAFT = 'draft',
  DELETED = 'deleted',
  VOID = 'void',
}

export const translateInvoiceStatus = (status: string) => {
  switch (status) {
    case InvoiceStatuses.PAID:
      return 'Payée';
    case InvoiceStatuses.OPEN:
      return 'En attente de paiement';
    case InvoiceStatuses.DRAFT:
      return 'Brouillon';
    case InvoiceStatuses.DELETED:
      return 'Supprimée';
    case InvoiceStatuses.VOID:
      return 'Annulée';

    default:
      return status;
  }
};
