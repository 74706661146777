import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CiExport } from 'react-icons/ci';
import { useGetPopulatedPatientQuery, useGetMeQuery } from '@@/services/user';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import PatientSummary from '@@/components/PDF/PatientSummary';

const PatientSummaryPage: React.FC = () => {
  const { patientId } = useParams();
  const { data: patient } = useGetPopulatedPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: me } = useGetMeQuery();

  const summaryPDF = useMemo(
    () => <PatientSummary patient={patient} practitioner={me} />,
    [patient, me],
  );

  const [pdf, updatePdf] = usePDF({
    document: summaryPDF,
  });

  useEffect(() => {
    updatePdf(summaryPDF);
  }, [updatePdf, summaryPDF]);

  const downloadButton = useMemo(() => {
    if (pdf.loading || !pdf.url) {
      return null;
    }

    return (
      <a
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        download={`medical-record-${patient?.firstName}-${patient?.lastName}.pdf`}
        href={pdf.url}
      >
        <CiExport size={22} />
        <span className="ml-1">{pdf.error ? pdf.error : 'Exporter'}</span>
      </a>
    );
  }, [pdf]);

  return (
    <div className="my-4 w-full font-main relative h-full">
      <div className="flex flex-row justify-between mx-4 text-main h-full">
        <PDFViewer style={{ width: '800px', height: '100%' }}>
          {summaryPDF}
        </PDFViewer>
        <div className="flex flex-row space-x-4">{downloadButton}</div>
      </div>
    </div>
  );
};

export default PatientSummaryPage;
