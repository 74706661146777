import React from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectCouponInput from '@@/components/Inputs/SelectCouponInput';

export const schema = yup.object({
  trialPeriodInDays: yup.number(),
  coupon: yup.string(),
});

const InvoiceForm: React.FC<{ form: any }> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <form className="flex flex-col space-y-8 w-[250px]">
      <StandardInput
        register={register}
        id="trialPeriodInDays"
        label="Période d'essai en jours"
        type="text"
        placeholder="trialPeriodInDays (facultatif)"
        error={errors?.trialPeriodInDays}
      />
      <SelectCouponInput
        register={register}
        id="coupon"
        label="Discount"
        placeholder="Discount"
        error={errors?.coupon}
      />
    </form>
  );
};

export default InvoiceForm;
