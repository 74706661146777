import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { SlEnvolopeLetter } from 'react-icons/sl';
import {
  useGetUserReferralsQuery,
  useDeleteReferralMutation,
} from '@@/services/referral';
import { useGetPractitionerContactQuery } from '@@/services/practitionerContact';
import { translateSpeciality } from '@@/constants/practitioner';
import SectionTitle from '@@/components/SectionTitle';

const Referral: React.FC<{ referral: Unpatient.Referral }> = ({ referral }) => {
  const { data: practitionerContact } = useGetPractitionerContactQuery(
    referral.referredPractitioner?.id,
    { skip: !referral.referredPractitioner?.id },
  );

  const [
    deleteReferral,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeleteReferralMutation();

  const onDelete = useCallback(
    (referralId: string) => {
      if (
        confirm("Souhaitez vous vraiment supprimer cette lettre d'adressage ?")
      ) {
        deleteReferral(referralId);
      }
    },
    [deleteReferral],
  );

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`L'adressage a bien été supprimé`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  if (!practitionerContact) {
    return null;
  }

  let name = `${moment(referral.createdAt).format('DD/MM/YYYY')} - ${
    practitionerContact.title || 'Dr'
  } ${practitionerContact.name}`;

  if (practitionerContact.speciality) {
    name += ` - ${translateSpeciality(practitionerContact.speciality)}`;
  }

  return (
    <div key={referral.id} className="flex flex-row items-center">
      <Link
        to={`/patients/${referral.patientId}/referrals/${referral.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="p-2 border border-gray-400 rounded-lg flex flex-row items-center"
      >
        <SlEnvolopeLetter size={24} />
        <span className="ml-1 font-main text-main">{name}</span>
      </Link>

      <button
        className="ml-2"
        onClick={() => onDelete(referral.id)}
        disabled={isDeletionLoading}
      >
        <RiDeleteBinLine size={24} />
      </button>
    </div>
  );
};

const ReferralSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: referrals } = useGetUserReferralsQuery(userId, {
    skip: !userId,
  });

  return (
    <div>
      <SectionTitle title="Adressages" className="mt-12 mb-6" />
      <div className="flex flex-col space-y-4 my-4">
        {referrals?.map((referral) => (
          <Referral key={referral.id} referral={referral} />
        ))}
      </div>

      {!referrals?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucun adressage</span>
          <IoWarningOutline size={24} />
        </p>
      )}

      <Link
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white w-fit mt-8"
        to={`/patients/${userId}/referrals/new`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-1">Nouvel Adressage</span>
      </Link>
    </div>
  );
};

export default ReferralSection;
