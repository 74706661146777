import React from 'react';
import moment from 'moment';
import { useGetPatientsQuery } from '@@/services/doctolib';
import { getAge } from '@@/utils/date';

const PatientCard: React.FC<{ patient: Doctolib.Patient }> = ({ patient }) => {
  return (
    <div className="inline-block align-top m-2 w-[300px] p-2 border border-gray-200 rounded-xl">
      <p className="font-bold">
        {patient.first_name} {patient.last_name}
      </p>

      <p>
        Né(e) à {patient.place_of_birth_name} le&nbsp;
        {moment(patient.birthdate).calendar()}
      </p>
      <p>{getAge(patient.birthdate)} ans</p>

      <p>{patient.email}</p>
      <p>{patient.phone_number}</p>
    </div>
  );
};

const PatientsPage: React.FC = () => {
  const { data: patients, isLoading } = useGetPatientsQuery();

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      {patients?.map((patient) => (
        <PatientCard key={patient.id} patient={patient} />
      ))}
    </div>
  );
};

export default PatientsPage;
