import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetMyPrescriptionTemplatesQuery } from '@@/services/prescription';
import { translatePrescriptionType } from '@@/constants/prescription';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPrescriptionTemplateInput: React.FC<{
  id: string;
  register: any;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  type?: string;
  cb?: (arg: any) => void;
}> = ({ id, label, placeholder, error, register, type, cb }) => {
  const { data: prescriptionTemplates } = useGetMyPrescriptionTemplatesQuery();

  const selectPrescriptionTemplatesInput = useMemo(() => {
    if (prescriptionTemplates) {
      return [...prescriptionTemplates]
        .filter((template) => (type ? template.type === type : true))
        .sort((c1, c2) => {
          const c1Title = c1.title || '';
          const c2Title = c2.title || '';

          if (c1Title < c2Title) {
            return -1;
          }

          if (c1Title > c2Title) {
            return 1;
          }

          return 0;
        })
        .map((template) => ({
          label: template.title || translatePrescriptionType(template.type),
          ...template,
        }));
    }

    return [];
  }, [prescriptionTemplates]);

  if (!prescriptionTemplates) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectPrescriptionTemplatesInput}
      placeholder={placeholder}
      error={error}
      callback={(templateId: string) => {
        if (typeof cb === 'function') {
          const template = prescriptionTemplates.find(
            (template) => template.id === templateId,
          );

          if (template) {
            cb(template);
          }
        }
      }}
    />
  );
};

export default SelectPrescriptionTemplateInput;
