import React from 'react';
import moment from 'moment';
import { useGetUserTimelineQuery } from '@@/services/timeline';

const TimelinePage: React.FC<{ targetPatientId: string }> = ({
  targetPatientId,
}) => {
  const { data: timeline } = useGetUserTimelineQuery(targetPatientId || '', {
    skip: !targetPatientId,
  });

  if (!timeline) {
    return null;
  }

  return (
    <div className="mx-auto px-6 py-8 overflow-scroll">
      <div className="relative border-l-2 border-gray-200">
        {timeline.map((item) => (
          <div key={item.id} className="mb-8 ml-4 font-main">
            <div className="absolute -left-3.5 h-6 w-6 rounded-full bg-cyan-500"></div>
            <p className="text-cyan-500 font-semibold">
              {moment(item.date).format('DD/MM/YYYY')}
            </p>
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold">
                {item.cim11?.length ? (
                  <span className="mr-1">{`[${item.cim11.join(' - ')}]`}</span>
                ) : null}

                {item.title}
              </h3>
              <p className="text-gray-700">{item.summary}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelinePage;
