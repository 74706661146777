import React from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { useGetInvoiceQuery } from '@@/services/invoice';
import { translateInvoiceStatus } from '@@/constants/stripe';

const InvoicePage: React.FC = () => {
  const { invoiceId } = useParams();
  const { data: invoice, isLoading } = useGetInvoiceQuery(invoiceId || '', {
    skip: !invoiceId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!invoice) {
    return null;
  }

  return (
    <div className="m-4 font-main text-main">
      <p>
        Client : {invoice.customer_name} - {invoice.customer_email}
      </p>
      <p>
        Période: Du {moment.unix(invoice.period_start).format('DD/MM/YYYY')}
        &nbsp;au&nbsp;
        {moment.unix(invoice.period_end).format('DD/MM/YYYY')}
      </p>
      <p className="mt-4">Statut : {translateInvoiceStatus(invoice.status)}</p>
      <p>Montant total: {invoice.subtotal / 100} €</p>
      <p>Montant facturé: {invoice.amount_due / 100} €</p>
      <p>Remise: {(invoice.subtotal - invoice.amount_due) / 100} €</p>
      <p className="mt-2">Montant payé: {invoice.amount_paid / 100} €</p>
      <div className="flex flex-row space-x-4 mt-8">
        {!!invoice.hosted_invoice_url && (
          <Link
            to={invoice.hosted_invoice_url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-cyan-400 p-2 rounded-lg flex flex-row items-center text-white"
          >
            <IoEyeOutline size={24} />
            <span className="ml-1">Visualiser la facture Stripe</span>
          </Link>
        )}
        {!!invoice.invoice_pdf && (
          <Link
            to={invoice.invoice_pdf}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-cyan-400 p-2 rounded-lg flex flex-row items-center text-white"
          >
            <IoCloudDownloadOutline size={24} />
            <span className="ml-1">Télécharger la facture Stripe</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default InvoicePage;
