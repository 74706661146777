import React, { useMemo } from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';
import SelectPatientInput from '@@/components/Inputs/SelectPatientInput';
import SelectPatientEventInput from '@@/components/Inputs/SelectPatientEventInput';

export const schema = yup
  .object({
    userId: yup
      .object({
        id: yup.string().required('Veuillez sélectioner un destinataire'),
      })
      .required(),
    eventId: yup.string(),
    body: yup.string().required('Veuillez renseigner le message'),
    scheduledAt: yup.date().required("Veuillez renseigner la date d'envoi"),
  })
  .required();

const ScheduledMessageForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const userId = watch('userId');

  return (
    <div className="m-10 flex flex-row">
      <div className="mr-20">
        <div className="w-[300px] mr-8 -mt-7">
          <SelectPatientInput
            control={control}
            values={userId}
            id="userId"
            label="Patient"
            placeholder="Choisissez un patient"
          />
        </div>

        {!!userId?.id && (
          <div className="mt-8 w-[300px]">
            <SelectPatientEventInput
              register={register}
              patientId={userId?.id}
              id="eventId"
              label="Evènement lié"
              placeholder="Choisissez un évènement"
            />
          </div>
        )}

        <div className="mt-12 w-[350px]">
          <DatePickerInput
            id="scheduledAt"
            control={control}
            label="Date d'envoi (*)"
            showTimeSelect
            error={errors?.scheduledAt}
          />
        </div>
      </div>

      <div className="w-[600px]">
        <StandardInput
          register={register}
          id="body"
          label="Message (*)"
          type="textarea"
          placeholder="Message (*)"
          error={errors.body}
        />
      </div>
    </div>
  );
};

export default ScheduledMessageForm;
