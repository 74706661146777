import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetMeQuery, useGetPatientQuery } from '@@/services/user';
import { getAge } from '@@/utils/date';
import { BiConversation } from 'react-icons/bi';
import AuthenticatedImage from '@@/components/AuthenticatedImage';

const NavBar: React.FC = () => {
  const { patientId } = useParams();
  const { data: me } = useGetMeQuery();
  const { data: patient } = useGetPatientQuery(patientId || '', {
    skip: !patientId,
  });

  const patientInfo = useMemo(() => {
    if (!patient || !patientId) {
      return null;
    }

    const age =
      patient.medicalRecord?.birthDate &&
      ` (${getAge(patient.medicalRecord.birthDate)} ans)`;

    const phone = patient?.wsPhone || patient?.phone;

    return (
      <a
        className="text-white font-main font-semibold flex flex-row items-center underline"
        href={phone ? `/conversations/c.us/${phone}` : `/patients/${patientId}`}
      >
        <BiConversation size={24} />
        <span className="ml-1">
          {patient.firstName} {patient.lastName}
          {age}
        </span>
      </a>
    );
  }, [patient, patientId]);

  return (
    <div className="bg-cyan-400 fixed top-0 w-full z-20 flex flex-row px-4 py-3 justify-between items-center">
      <Link to="/home" className="text-white">
        <img src="/logo.png" className="w-28" />
      </Link>

      {patientInfo}

      <Link className="flex flex-row items-center cursor-pointer" to="/me">
        <AuthenticatedImage
          documentId={me?.photoDocumentId}
          placeholder="/user-profile-placeholder.jpg"
          alt={`${me?.firstName} ${me?.lastName}`}
          className="w-6 sm:w-10 bg-white font-main rounded-full"
        />
        <span className="ml-2 mr-1 text-white font-main underline">
          {me?.firstName} {me?.lastName}
        </span>
      </Link>
    </div>
  );
};

export default NavBar;
