import React from 'react';
import * as yup from 'yup';
import { selectInputDocumentTypes } from '@@/constants/document';
import SelectInput from '@@/components/Inputs/SelectInput';
import StandardInput from '@@/components/Inputs/StandardInput';
import CheckboxInput from '@@/components/Inputs/CheckboxInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';

export const schema = yup
  .object({
    type: yup.string(),
    label: yup.string(),
    isPrivate: yup.boolean(),
  })
  .required();

const DocumentEditionForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <div className="flex flex-col space-y-10 py-4" key="event-form">
      <StandardInput
        register={register}
        id="label"
        label="Nom du document"
        placeholder="Nom du document"
        error={errors?.label}
        type="text"
      />
      <SelectInput
        register={register}
        id="type"
        label="Categorie de l'examen"
        options={selectInputDocumentTypes}
      />

      <DatePickerInput
        id="documentCreationDate"
        control={control}
        label="Date de création du document"
        showTimeSelect
        error={errors?.documentCreationDate}
      />

      <CheckboxInput
        register={register}
        id="isPrivate"
        label="Rendre le document privé"
      />
    </div>
  );
};

export default DocumentEditionForm;
