import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useViewFaxQuery } from '@@/services/fax';
import Loader from '@@/components/Loader';

const FaxPage: React.FC = () => {
  const { faxId } = useParams();
  const { data: faxUrl } = useViewFaxQuery(faxId || '', {
    skip: !faxId,
  });

  useEffect(() => {
    if (faxUrl) {
      window.location.href = faxUrl;
    }
  }, [faxUrl]);

  return (
    <div className="m-8 flex flex-row items-center justify-center w-full">
      <Loader />
      <p className="ml-2 font-main text-main text-lg">Chargement...</p>
    </div>
  );
};

export default FaxPage;
