import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IoWarningOutline } from 'react-icons/io5';
import { BsPrescription2 } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  useGetMyPrescriptionTemplatesQuery,
  usePatchPrescriptionMutation,
} from '@@/services/prescription';
import { translatePrescriptionType } from '@@/constants/prescription';
import SectionTitle from '@@/components/SectionTitle';

const PrescriptionTemplateSection: React.FC = () => {
  const { data: prescriptionTemplates } = useGetMyPrescriptionTemplatesQuery();
  const [patchPrescription, { isSuccess, isLoading }] =
    usePatchPrescriptionMutation();

  const removeFavorite = useCallback(
    (prescription: Unpatient.Prescription) => {
      if (
        confirm('Souhaitez vous vraiment supprimer cette ordonnance type ?')
      ) {
        patchPrescription({
          id: prescription.id,
          patientId: prescription.patientId,
          isTemplate: false,
        });
      }
    },
    [patchPrescription],
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(`La prescription type a bien été supprimée`, {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  console.log(prescriptionTemplates);

  return (
    <div>
      <SectionTitle title="Ordonnances types" className="mt-12 mb-6" />
      <div className="flex flex-col space-y-4 my-4">
        {prescriptionTemplates?.map((prescription) => (
          <div key={prescription.id} className="flex flex-row items-center">
            <Link
              to={`/patients/${prescription.patientId}/prescriptions/${prescription.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 border border-gray-400 rounded-lg flex flex-row items-center"
            >
              <BsPrescription2 size={24} />
              <span className="ml-1 font-main text-main">
                {moment(prescription.createdAt).format('DD/MM/YYYY')}
                &nbsp;-&nbsp;
                {prescription.title ||
                  translatePrescriptionType(prescription.type)}
              </span>
            </Link>

            <button
              className="ml-2"
              onClick={() => removeFavorite(prescription)}
              disabled={isLoading}
            >
              <RiDeleteBinLine size={24} />
            </button>
          </div>
        ))}
      </div>

      {!prescriptionTemplates?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucune ordonnance type</span>
          <IoWarningOutline size={24} />
        </p>
      )}
    </div>
  );
};

export default PrescriptionTemplateSection;
