import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline } from 'react-icons/io5';
import { FaHouseMedicalCircleCheck } from 'react-icons/fa6';
import {
  useGetUserPlansQuery,
  useCreateUserPlanMutation,
  useDeletePlanMutation,
} from '@@/services/plan';
import SectionTitle from '@@/components/SectionTitle';

const PlanSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: plans } = useGetUserPlansQuery(userId, {
    skip: !userId,
  });
  const [createPlan, { isSuccess, isLoading }] = useCreateUserPlanMutation();
  const [
    deletePlan,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeletePlanMutation();

  const onDelete = useCallback(
    (planId: string) => {
      if (confirm('Souhaitez vous vraiment supprimer ce plan de soins ?')) {
        deletePlan(planId);
      }
    },
    [deletePlan],
  );

  const onSubmit = useCallback(async () => {
    if (userId) {
      createPlan(userId);
    }
  }, [createPlan, userId]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        `Le plan de soins est en cours de création. Cela peut prendre plusieurs minutes...`,
        {
          position: 'top-right',
        },
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`Le plan de soins a bien été supprimé`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  return (
    <div>
      <SectionTitle title="Plans de soins" className="mt-12 mb-6" />
      <div className="flex flex-col space-y-4 my-4">
        {plans?.map((plan) => (
          <div key={plan.id} className="flex flex-row items-center">
            <Link
              to={`/patients/${userId}/plans/${plan.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 border border-gray-400 rounded-lg flex flex-row items-center"
            >
              <FaHouseMedicalCircleCheck size={24} />
              <span className="ml-1 font-main text-main">
                {moment(plan.startDate).format('DD/MM/YYYY')} -{' '}
                {moment(plan.endDate).format('DD/MM/YYYY')}
              </span>
            </Link>

            <button
              className="ml-2"
              onClick={() => onDelete(plan.id)}
              disabled={isDeletionLoading}
            >
              <RiDeleteBinLine size={24} />
            </button>
          </div>
        ))}
      </div>

      {!plans?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucun plan de soins</span>
          <IoWarningOutline size={24} />
        </p>
      )}

      <button
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white  w-fit mt-8"
        onClick={() => onSubmit()}
        disabled={isLoading}
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-1">Ajouter un plan de soins</span>
      </button>
    </div>
  );
};

export default PlanSection;
