import React from 'react';
import moment from 'moment';
import { useGetDocumentsQuery } from '@@/services/doctolib';
// import AuthenticatedLinkDoctolib from '@@/components/AuthenticatedLinkDoctolib';

const translateKind = (kind: string) => {
  switch (kind) {
    case 'medical_imaging_prescription':
      return "Ordonnance d'imagerie médicale";
    case 'referral':
      return "Courrier d'Adressage";
    case 'other':
      return 'Autre';
    case 'treatment_form':
      return 'Feuille de soins';
    case 'lab_results':
      return 'Résultats de laboratoire';
    case 'medical_imaging':
      return 'Imagerie médicale';
    case 'report':
      return 'Compte-rendu';
    case 'paramedics_prescription':
      return 'prescription paramédicale';
    default:
      return kind;
  }
};

const DocumentsPage: React.FC = () => {
  const { data: documents, isLoading } = useGetDocumentsQuery();

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  console.log(documents);

  return (
    <div>
      <p className="m-2 mb-4 font main text-main italic">
        Disclaimer : Les documents sont chiffrés de bout en bout via Tanker ;
        par conséquent il est difficile de les récupérer.
      </p>
      {documents?.map((document) => (
        <div
          key={document.id}
          className="inline-block align-top w-[300px] p-2 rounded-xl border border-gray-200 m-1"
        >
          <p className="font-bold">
            {document.master_patient.first_name}&nbsp;
            {document.master_patient.last_name}
          </p>
          <p className="mb-1 text-cyan-900">{translateKind(document.kind)}</p>
          <p className="text-sm italic">
            Créé le {moment(document.created_at).calendar()} par&nbsp;
            {document.creator_label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DocumentsPage;
