import React from 'react';
import * as yup from 'yup';
import { selectInputSpecialities } from '@@/constants/practitioner';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectInput from '@@/components/Inputs/SelectInput';

export const schema = yup
  .object({
    firstName: yup.string().required('Veuillez entrer votre prénom'),
    lastName: yup.string().required('Veuillez entrer votre nom'),
    email: yup.string().email().required('Veuillez entrer votre email'),
    speciality: yup.string(),
  })
  .required();

const PractitionerWishListForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-6">
      <StandardInput
        register={register}
        id="firstName"
        label="Prénom (*)"
        placeholder="Prénom"
        error={errors?.firstName}
        type="text"
        inputClassName="peer block w-full ring-1 ring-cyan-500 shadow-sm py-3 px-4 border-cyan-500 rounded-md placeholder-transparent bg-transparent font-main text-white text-sm"
        labelClassName="absolute pointer-events-none font-main text-white text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:italic"
        errorClassName="italic font-main text-sm text-white m-1"
      />
      <StandardInput
        register={register}
        id="lastName"
        label="Nom (*)"
        placeholder="Nom"
        error={errors?.lastName}
        type="text"
        inputClassName="peer block w-full ring-1 ring-cyan-500 shadow-sm py-3 px-4 border-cyan-500 rounded-md placeholder-transparent bg-transparent font-main text-white text-sm"
        labelClassName="absolute pointer-events-none font-main text-white text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:italic"
        errorClassName="italic font-main text-sm text-white m-1"
      />

      <div className="col-span-2 sm:col-span-1">
        <StandardInput
          register={register}
          id="email"
          label="Email (*)"
          placeholder="Email"
          error={errors?.email}
          type="text"
          inputClassName="peer block w-full ring-1 ring-cyan-500 shadow-sm py-3 px-4 border-cyan-500 rounded-md placeholder-transparent bg-transparent font-main text-white text-sm"
          labelClassName="absolute pointer-events-none font-main text-white text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:italic"
          errorClassName="italic font-main text-sm text-white m-1"
        />
      </div>

      <div className="col-span-2 sm:col-span-1">
        <SelectInput
          register={register}
          id="speciality"
          options={selectInputSpecialities}
          placeholder="Spécialité"
          selectClassName="block font-main text-white text-sm appearance-none w-full bg-transparent border border-cyan-500 pl-4 pr-8 rounded-lg shadow-sm focus:outline-none focus:shadow-outline h-12"
        />
      </div>
    </div>
  );
};

export default PractitionerWishListForm;
