import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetPatientQuery } from '@@/services/user';
import { useGetUserHistoriesQuery } from '@@/services/history';
import SectionTitle from '@@/components/SectionTitle';

const History: React.FC<{ history: Unpatient.History }> = ({ history }) => {
  const requester = history.requester
    ? `${history.requester?.firstName} ${history.requester?.lastName}`
    : 'GPT-4';
  return (
    <div className="font-main text-main mt-4 mb-6">
      <p className="font-semibold mb-1">
        {moment(history.createdAt).format('llll')} par {requester}{' '}
      </p>
      <pre>{JSON.stringify(history.diffData, null, 4)}</pre>
    </div>
  );
};

const AuditPage: React.FC = () => {
  const { patientId } = useParams();
  const { data: patient } = useGetPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: histories } = useGetUserHistoriesQuery(patientId || '', {
    skip: !patientId,
  });
  return (
    <div className="m-4 w-full">
      <SectionTitle
        title={`Modifications pour le patient ${patient?.firstName} ${patient?.lastName}`}
        className="mb-8"
      />

      {histories?.map((h, i) => (
        <History key={i} history={h} />
      ))}
    </div>
  );
};

export default AuditPage;
