export enum PrescriptionTypes {
  DRUG = 'drug',
  BIOLOGY = 'biology',
  IMAGERY = 'imagery',
  FREE = 'free',
}

export const translatePrescriptionType = (type: string) => {
  switch (type) {
    case PrescriptionTypes.DRUG:
      return 'Ordonnance médicamenteuse';
    case PrescriptionTypes.BIOLOGY:
      return 'Examen(s) biologique(s)';
    case PrescriptionTypes.IMAGERY:
      return "Examen(s) d'imagerie";
    case PrescriptionTypes.FREE:
      return 'Ordonnance libre';
    default:
      return type;
  }
};

export const selectPrescriptionTypes = [
  { id: '', label: '' },
  /*
  {
    id: PrescriptionTypes.DRUG,
    label: 'Médicament',
  },
  */
  {
    id: PrescriptionTypes.BIOLOGY,
    label: 'Biologie',
  },
  {
    id: PrescriptionTypes.IMAGERY,
    label: 'Imagerie',
  },
  {
    id: PrescriptionTypes.FREE,
    label: 'Libre',
  },
];

export enum ImageryExamCategories {
  ECHOGRAPHY = 'ECHOGRAPHY',
  MRI = 'MRI',
  OSTEODENSIMETRY = 'OSTEODENSIMETRY',
  X_RAY_WITH_CONTRAST = 'X-RAY WITH CONTRAST',
  X_RAY_THORAX_PELVIS_ABDO = 'X-RAY THORAX-PELVIS-ABDO',
  OTHER_X_RAY = 'OTHER X-RAY',
  X_RAY_OF_THE_HEAD = 'X-RAY OF THE HEAD',
  X_RAY_OF_THE_LOWER_LIMB = 'X-RAY OF THE LOWER LIMB',
  X_RAY_OF_THE_UPPER_LIMB = 'X-RAY OF THE UPPER LIMB',
  SPINE_X_RAY = 'SPINE X-RAY',
  SCANNER = 'SCANNER',
  SCINTIGRAPHY = 'SCINTIGRAPHY',
}

export enum BiologyExamCategories {
  ADRENAL = 'ADRENAL',
  ALLERGY = 'ALLERGY',
  BACTERIOLOGY = 'BACTERIOLOGY',
  BLOOD_BIOCHEMISTRY = 'BLOOD BIOCHEMISTRY',
  DIABETOLOGY = 'DIABETOLOGY',
  DIGESTIVE_TRACK = 'DIGESTIVE TRACK',
  DRUGS_AND_TOXIC_SUBSTANCES = 'DRUGS AND TOXIC SUBSTANCES',
  ENDOCRINOLOGY = 'ENDOCRINOLOGY',
  ENZYMES = 'ENZYMES',
  HEMATOLOGY = 'HEMATOLOGY',
  HEMOSTASIS_AND_COAGULATION = 'HEMOSTASIS AND COAGULATION',
  IMMUNOHEMATOLOGY = 'IMMUNOHEMATOLOGY',
  INFECTIOLOGY = 'INFECTIOLOGY',
  KIDNEY_STONES = 'KIDNEY STONES',
  LIPID_PANEL = 'LIPID PANEL',
  NEUROMUSCULAR_SYSTEM = 'NEUROMUSCULAR SYSTEM',
  OTHER_SAMPLINGS = 'OTHER SAMPLINGS',
  PARASITOLOGY_AND_MYCOLOGY = 'PARASITOLOGY AND MYCOLOGY',
  PROTEINS_AND_AUTOIMMUNITY = 'PROTEINS AND AUTOIMMUNITY',
  SEMEN_ANALYSIS = 'SEMEN ANALYSIS',
  STDS = 'STDS',
  STOOL_TEST = 'STOOL TEST',
  THYROID_AND_PARATHYROID = 'THYROID AND PARATHYROID',
  TUMORAL_BIOMARKERS = 'TUMORAL BIOMARKERS',
  URINE_TEST = 'URINE TEST',
  VIROLOGY = 'VIROLOGY',
  VITAMINS_AND_MINERALS = 'VITAMINS AND MINERALS',
}

export const imageryExams = {
  [ImageryExamCategories.ECHOGRAPHY]: {
    denominations: [
      { fr: 'Abdominale', en: 'Abdominal ultrasound' },
      { fr: 'Abdomino-pelvienne', en: 'Abdomino pelvic ultrasound' },
      { fr: 'Articulaire', en: 'Articular ultrasound' },
      {
        fr: 'Cardiaque transoesophagienne',
        en: 'Transesophageal cardiac ultrasound',
      },
      {
        fr: 'Cardiaque transthoracique',
        en: 'Transthoracic cardiac ultrasound',
      },
      { fr: "De l'appareil urinaire", en: 'Urinary tract ultrasound' },
      {
        fr: 'De la peau, des ongles et/ou des tissus mous',
        en: 'Skin, nails and/or soft tissue ultrasound ',
      },
      {
        fr: 'De muscle et/ou tendon',
        en: 'Muscular and/or tendon ultrasound',
      },
      {
        fr: 'Doppler membre inférieur',
        en: 'Lower limb doppler ultrasound',
      },
      {
        fr: 'Doppler membre supérieur',
        en: 'Upper limb doppler ultrasound',
      },
      {
        fr: 'Doppler troncs supra-aortiques',
        en: 'Doppler supra-aortic trunks ',
      },
      {
        fr: 'Du foie et des conduits biliaires',
        en: 'Liver and biliary ducts ultrasound',
      },
      {
        fr: 'Du rein et de la région lombale',
        en: 'Kidney and the lumbar region ultrasound ',
      },
      { fr: 'Endopelvienne', en: 'Endopelvic' },
      { fr: 'Mammaire', en: 'Breast ultrasound' },
      { fr: 'Obstétricale', en: 'Pregnancy ultrasound' },
      { fr: 'Pelvienne', en: 'Pelvic ultrasound' },
      {
        fr: 'Prostatique transcutanée',
        en: 'Transcutaneous prostatic echography',
      },
      { fr: 'Testiculaire', en: 'Testicule ultrasound' },
      { fr: 'Thyroïdienne', en: 'Thyroid ultrasound' },
    ],
    en: 'ECHOGRAPHY',
    fr: 'ECHOGRAPHIE',
  },
  [ImageryExamCategories.MRI]: {
    denominations: [
      { fr: 'Abdominale', en: 'Abdominal mri' },
      {
        fr: 'Angio-irm abdominopelvien',
        en: 'Angio-mri abdominopelvic',
      },
      { fr: 'Angio-irm cérébrale', en: 'Cerebral angio-mri' },
      {
        fr: 'Angio-irm des membres supérieurs ou inférieurs',
        en: 'Angio-mri of the upper or lower limbs',
      },
      { fr: 'Angio-irm thoracique', en: 'Thoracic angio-mri' },
      { fr: 'Articulaire', en: 'Articular mri' },
      { fr: 'Cérébrale', en: 'Brain mri' },
      { fr: 'Crâne', en: 'Skull mri' },
      { fr: 'Face', en: 'Face mri' },
      { fr: 'Hépatique', en: 'Hepatic mri' },
      { fr: 'Massif facial', en: 'Massive facial mri' },
      { fr: 'Membre inferieur', en: 'Lower member mri' },
      { fr: 'Membre supérieur', en: 'Upper extremity mri' },
      { fr: 'Pelvienne', en: 'Pelvic mri' },
      { fr: 'Rachidien', en: 'Spinal mri' },
      { fr: 'Rachis cervical', en: 'Cervical spine mri' },
      { fr: 'Rachis dorsal', en: 'Dorsal spine mri' },
      { fr: 'Rachis lombaire', en: 'Lumbar spine mri' },
      { fr: 'Rachis lombosacré', en: 'Lumbosacral spin mri' },
      { fr: 'Sein', en: 'Breast mri' },
      { fr: 'Thoracique', en: 'Thoracic mri' },
      { fr: 'Tissus mous du cou', en: 'Soft tissue of the neck mri' },
    ],
    en: 'MRI',
    fr: 'IRM',
  },
  [ImageryExamCategories.OSTEODENSIMETRY]: {
    denominations: [
      { fr: 'Ostéodensitométrie osseuse', en: 'Bone densitometry' },
    ],
    en: 'OSTEODENSIMETRY',
    fr: 'OSTEODENSIMETRIE',
  },
  [ImageryExamCategories.X_RAY_WITH_CONTRAST]: {
    denominations: [
      {
        fr: 'Angiographie des membres inférieurs',
        en: 'Angiography of the lower limbs',
      },
      {
        fr: 'Arthrographie interapophysaire postérieure',
        en: 'Posterior intervertebral arthrography ',
      },
      { fr: 'Cystographie rétrograde', en: 'Retrograde cystography' },
      { fr: 'Discographie', en: 'Discography' },
      { fr: 'Fluoroscopie', en: 'Fluoroscopy' },
      { fr: 'Hystérosalpingographie', en: 'Hysterosalpingographia' },
      {
        fr: 'Lavement aux hydrosolubles',
        en: 'Enema with water-soluble',
      },
      { fr: 'Myelographie', en: 'Myelogram' },
      {
        fr: 'Phlébographie des membres inférieurs',
        en: 'Venography of the lower limbs',
      },
      { fr: 'Sialographie', en: 'Sialogram' },
      { fr: 'Transit du grêle', en: 'Follow through small intestin ' },
      {
        fr: 'Transit oeso-gastro-duodénal',
        en: 'Follow through oeso gastro duodenal',
      },
      { fr: 'Urographie intraveineuse', en: 'Intravenous urography ' },
    ],
    en: 'X-RAY WITH CONTRAST',
    fr: 'RADIOGRAPHIES PAR CONTRASTE',
  },
  [ImageryExamCategories.X_RAY_THORAX_PELVIS_ABDO]: {
    denominations: [
      {
        fr: 'Asp face assis',
        en: 'Abdomen without contrast seated face',
      },
      { fr: 'Asp face couché', en: 'Awc lying face' },
      { fr: 'Asp face debout', en: 'Awc standing face' },
      {
        fr: 'Asp face debout centré sur les coupoles',
        en: 'Awc standing face centered on the domes',
      },
      { fr: 'Asp face en décubitus dorsal', en: 'Awc face supine' },
      {
        fr: 'Asp rayon horizontal en décubitus dorsal',
        en: 'Awc horizontal radius in supine position',
      },
      {
        fr: 'Asp rayon horizontal en décubitus latéral',
        en: 'Awc horizontal radius in lateral decubitus',
      },
      {
        fr: 'Bassin 3/4 obturateur et 3/4 alaire (incidences obliques)',
        en: 'Basin 3/4 shutter and 3/4 wing (oblique effects)',
      },
      {
        fr: 'Bassin centré sur les articulations sacro-iliaques',
        en: 'Basin centered on the sacroiliac joints',
      },
      { fr: 'Bassin de face', en: 'Basin face ' },
      { fr: 'Bassin de face couché', en: 'Basin lying down' },
      {
        fr: 'Bassin de face debout/en charge (incidence de de sèze)',
        en: 'Basin face upright / in charge face (incidence of sèze)',
      },
      {
        fr: 'Bassin de profil centré sur l5 (mesure incidence pelvienne)',
        en: 'Pelvis profile centered on l5 (pelvic incidence measurement)',
      },
      {
        fr: 'Bassin de profil centré sur le sacrum avec rayons mous',
        en: 'Basin of profile centered on the sacrum with soft rays',
      },
      { fr: 'Bassin de profils', en: 'Basin of profiles' },
      {
        fr: 'Bassin inlet + outlet (incidences pennal)',
        en: 'Inlet basin + outlet basin (pennal bearings)',
      },
      {
        fr: `Bassin profil  de lauenstein (cliché "grenouille" pour l'enfant)`,
        en: 'Basin profile lauenstein (picture "frog" for the child)',
      },
      {
        fr: 'Grill costal face + oblique',
        en: 'Grill costal face + oblique',
      },
      {
        fr: 'Sternum face (avec articulations sterno-claviculaires) + profil',
        en: 'Sternum face (with sterno-clavicular joints) + profile',
      },
      { fr: 'Thorax assis', en: 'Sitting chest' },
      { fr: 'Thorax couché', en: 'Lying chest' },
      { fr: 'Thorax debout', en: 'Standing thorax' },
      { fr: 'Thorax en cyphose', en: 'Kyphosis thorax' },
      {
        fr: 'Thorax en décubitus latéral avec rayon horizontal',
        en: 'Thorax in lateral decubitus with horizontal radius',
      },
      {
        fr: 'Thorax en face inspiratoire et face expiratoire',
        en: 'Chest in the inspiratory face and expiratory face',
      },
      { fr: 'Thorax en lordose', en: 'Thorax in lordosis' },
      { fr: 'Thorax en oblique droit ', en: 'Thorax right angle' },
      { fr: 'Thorax en oblique gauche', en: 'Thorax left angle' },
      { fr: 'Thorax en valsalva', en: 'Chest in valsalva' },
      { fr: 'Thorax profil droit', en: 'Right profile thorax' },
      { fr: 'Thorax profil gauche', en: 'Left profile thorax' },
    ],
    en: 'X-RAY THORAX-PELVIS-ABDO',
    fr: 'RADIOGRAPHIES THORAX-ABDO-BASSIN',
  },
  [ImageryExamCategories.OTHER_X_RAY]: {
    denominations: [
      { fr: 'Mammographie', en: 'Mammography' },
      {
        fr: 'Radio à rayons mous (recherche de corps étranger) face + profil',
        en: 'Soft-ray radio (foreign body search)',
      },
      { fr: 'Radiopelvimétrie', en: 'Pelvimetry' },
    ],
    en: 'OTHER X-RAY',
    fr: 'RADIOGRAPHIES AUTRES',
  },
  [ImageryExamCategories.X_RAY_OF_THE_HEAD]: {
    denominations: [
      {
        fr: 'Crâne base du crâne (incidence hirtz)',
        en: 'Skull base of the skull (hirtz bearing)',
      },
      { fr: 'Crâne malaire', en: 'Malarial skull' },
      { fr: 'Crâne profil', en: 'Skull profile' },
      { fr: 'Nez incidence grosserez', en: 'Nose incidence grosserez' },
      { fr: 'Os propres du nez', en: 'Bone own nose' },
      {
        fr: 'Panoramique dentaire (orthopantomogramme)',
        en: 'Panoramic dental (orthopantomogram)',
      },
      {
        fr: 'Sinus face basse (incidence blondeau "nez-menton"plaque")',
        en: 'Sinus face down (incidence blondeau "nose-chin" plate ")',
      },
      {
        fr: 'Sinus face haute (incidence caldwell "nez-menton-plaque")',
        en: 'Sinus high face (caldwell "nose-chin-plate" bearing)',
      },
    ],
    en: 'X-RAY OF THE HEAD',
    fr: 'RADIOGRAPHIES DE LA TETE',
  },
  [ImageryExamCategories.X_RAY_OF_THE_LOWER_LIMB]: {
    denominations: [
      { fr: '1er orteil face + profil', en: '1st toe face + profile' },
      { fr: '2ème orteil face + profil', en: '2nd toe face + profile' },
      { fr: '3ème orteil face + profil', en: '3d toe face + profile' },
      { fr: '4ème orteil face + profil', en: '4th toe face + profile' },
      { fr: '5ème orteil face + profil', en: '5th toe face + profile' },
      {
        fr: 'Cheville de face en rotation interne',
        en: 'Ankle face  in internal rotation',
      },
      {
        fr: 'Cheville face + profil',
        en: 'Ankle radiographs face + profile',
      },
      {
        fr: 'Cheville face + profil en charge',
        en: 'Ankle  face + profile in charge',
      },
      {
        fr: 'Cheville incidence ami view',
        en: 'Impact ankle ami view',
      },
      {
        fr: 'Cheville incidence de méary',
        en: 'Ankle incidence of meary',
      },
      {
        fr: 'Cheville incidence de saltzman',
        en: 'Saltzman incidence ankle',
      },
      {
        fr: 'Cheville incidence rétro-tibiale de böhler (calcanéum)',
        en: 'Böhler retro-tibial bearing ankle (calcaneus)',
      },
      {
        fr: 'Défilé fémoro-patellaire (bilatéral)',
        en: 'Patellofemoral procession (bilateral)',
      },
      {
        fr: 'Fémur face + profil',
        en: 'Femur radiography face + profile',
      },
      { fr: 'Genou 2 incidences de 3/4', en: 'Knee 2 bearings 3/4' },
      { fr: 'Genou en vue axiale', en: 'Knee in axial view' },
      {
        fr: 'Genou face + profil',
        en: 'Knee radiographs face+profile',
      },
      {
        fr: 'Genou incidence de schuss (de face à 30° flexion)',
        en: 'Schuss knee incidence (from face to 30 ° flexion)',
      },
      { fr: 'Genou profil externe', en: 'Knee outer profile' },
      { fr: 'Genou profil interne', en: 'Knee internal profile' },
      {
        fr: 'Genou rayon horizontal en décubitus dorsal',
        en: 'Knee horizontal radius in supine position',
      },
      {
        fr: 'Hanche "fausse face" (face avec rayon ascendant de 30°)',
        en: 'Hip "false face" (face with ascending radius of 30 °)',
      },
      {
        fr: 'Hanche 3/4 oblique et 3/4 alaire (incidences obliques)',
        en: 'Hip 3/4 oblique and 3/4 wing (obliques)',
      },
      { fr: 'Hanche face', en: 'Hips face ' },
      {
        fr: 'Hanche face en légère rotation externe et flexion du fémur (rayon ascendant)',
        en: 'Hip facing in slight external rotation and bending of the femur (ascending radius)',
      },
      {
        fr: 'Hanche faux profil de lequesne',
        en: 'False profile hip of lequesne',
      },
      {
        fr: "Hanche profil de ducrocquet ou d'arcelin (col du fémur)",
        en: 'Hip profile of ducrocquet or arcelin (femoral neck)',
      },
      {
        fr: 'Hanche profil urétral ou médical de cochin',
        en: 'Hip profile urethral or medical of cochin',
      },
      {
        fr: 'Jambe face + profil',
        en: 'Leg radiographs face + profile',
      },
      {
        fr: 'Pied face + profil',
        en: 'Foot radiographs face + profile',
      },
      {
        fr: 'Pied face + profil en charge',
        en: 'Foot radiographs face + profile in charge',
      },
      { fr: 'Pied incidence de chevrot', en: 'Foot chevrot bearing' },
      {
        fr: 'Pied incidence métatarso-sésamoïdienne',
        en: 'Foot metatarsal-sesamoidal bearing',
      },
      {
        fr: 'Pied incidence sous talienne de broden',
        en: 'Foot subtalar bearing of broden',
      },
    ],
    en: 'X-RAY OF THE LOWER LIMB',
    fr: 'RADIOGRAPHIES DU MEMBRE INFERIEUR',
  },
  [ImageryExamCategories.X_RAY_OF_THE_UPPER_LIMB]: {
    denominations: [
      {
        fr: 'Acromio-claviculaire: face bilatérale ("mauvaise épaule")',
        en: 'Acromioclavicular: bilateral face ("bad shoulder")',
      },
      {
        fr: 'Annulaire (4ème doigt) face + profil',
        en: 'Ring finger (4th finger) face + profile',
      },
      {
        fr: 'Auriculaire (5ème doigt) face + profil',
        en: 'Auricular (5th finger) face + profile',
      },
      {
        fr: 'Avant-bras/radius-ulna: face+ profil interne',
        en: 'Forearm / radius-ulna: face + internal profile',
      },
      {
        fr: 'Clavicule "face vraie" (incidence de charotti)',
        en: 'Clavicle "true face" (incidence of charotti)',
      },
      { fr: 'Clavicule de face stricte', en: 'Clavicle strict face ' },
      {
        fr: 'Clavicule face ascendante à 15-20° (profil claviculaire de clark)',
        en: 'Clavicle ascending face at 15-20 ° (clavicular profile of clark)',
      },
      {
        fr: 'Clavicule face ascendante à 45° (incidence de porcher)',
        en: 'Clavicle ascending face at 45 ° (incidence of porcher)',
      },
      {
        fr: 'Clavicule face descendante (15-20°)',
        en: 'Clavicle downward face (15-20 °)',
      },
      {
        fr: 'Clavicule incidences de quesada',
        en: 'Clavicle incidences of quesada',
      },
      {
        fr: 'Coude 3/4 centré sur apophyse coronoïde',
        en: 'Elbow 3/4 centered on coronoid process',
      },
      {
        fr: 'Coude double face (si extension impossible)',
        en: 'Double-sided elbow (if extension impossible)',
      },
      {
        fr: 'Coude face + profil (pénétrance des parties molles)',
        en: 'Elbow face + profile (penetrance of the soft parts)',
      },
      {
        fr: 'Coude face en extension et supination',
        en: 'Elbow face in extension and supination',
      },
      {
        fr: 'Coude incidence 3/4 tête radiale',
        en: 'Elbow 3/4 radial head bearing ',
      },
      { fr: 'Coude oblique', en: 'Oblique elbow' },
      {
        fr: 'Coude profil en extension à 90° et supination',
        en: 'Elbow profile in extension at 90 ° and supination',
      },
      {
        fr: 'Épaule face en double obliquité',
        en: 'Shoulder face in double obliquity',
      },
      {
        fr: 'Épaule face stricte en décubitus avec rayon droit (dit railhac)',
        en: 'Shoulder, strict face in decubitus with right ray (says railhac)',
      },
      {
        fr: 'Épaule profil axillaire',
        en: 'Shoulder axillary profile',
      },
      {
        fr: 'Épaule profil de bloom-obata',
        en: 'Shoulder profile of bloom-obata',
      },
      {
        fr: 'Épaule profil glénoïdien (incidence de bernageau)',
        en: 'Shoulder glenoid profile (incidence of bernageau)',
      },
      {
        fr: 'Épaule profil sous acromial (profil de lamy)',
        en: 'Shoulder profile under acromial (profile of lamy)',
      },
      {
        fr: 'Épaule: face en rotation interne + rotation externe',
        en: 'Shoulder: internal rotation face + external rotation',
      },
      {
        fr: 'Épaule: face en rotation neutre + faux profil de lamy',
        en: 'Shoulder: neutral rotating face + false profile of lamy',
      },
      {
        fr: 'Épaule: incidence de garth',
        en: 'Shoulder: garth incidence',
      },
      { fr: 'Humérus face + profil', en: 'Humerus face + profile' },
      {
        fr: 'Index (2ème doigt) face + profil',
        en: 'Index (2nd finger) face + profile',
      },
      {
        fr: 'Main de face en semi-supination',
        en: 'Facial hand in semi-supine',
      },
      { fr: 'Main en semi-pronation', en: 'Hand in semi-pronation' },
      {
        fr: 'Main face + profil oblique dorso-palmaire',
        en: 'Hand f ace+ dorso-palmar oblique profile',
      },
      { fr: 'Main incidence 3/4', en: 'Hand incidence 3/4' },
      {
        fr: 'Main incidence colonne du pouce: face avec scaphoïde',
        en: 'Hand thumb column incidence: face with scaphoid',
      },
      {
        fr: 'Main incidence profil vrai',
        en: 'Hand incidence true profile',
      },
      {
        fr: 'Main supination radiale/base de m5',
        en: 'Hand radial / base supination from m5',
      },
      {
        fr: 'Main supination ulnaire/base de m1',
        en: 'Ulnar supine hand / m1 base',
      },
      {
        fr: 'Majeur (3ème doigt) face + profil',
        en: 'Major (3rd finger) face + profile',
      },
      { fr: 'Omoplate/scapula face', en: 'Scapula face' },
      {
        fr: 'Poignet 2 incidences triquetrum',
        en: 'Wrist 2 triquetrum incidences ',
      },
      {
        fr: 'Poignet de face en supination (ou dos-plaque)',
        en: 'Facial wrist in supine (or back-plate)',
      },
      { fr: 'Poignet face + profil', en: 'Wrist face + profile' },
      {
        fr: 'Poignet incidence gouttière carpienne',
        en: 'Wrist carpal gutter incidence',
      },
      {
        fr: 'Poignet incidence hamatum + pisiforme',
        en: 'Wrist, hamatum + pisiform bearing',
      },
      {
        fr: 'Poignet incidence trapézo-métacarpienne ',
        en: 'Wrist trapezio-metacarpal incidence',
      },
      {
        fr: 'Poignet manoeuvres dynamiques en inclinaison radiale et ulnaire',
        en: 'Wrist dynamic maneuvers in radial inclination and ulnar',
      },
      { fr: 'Pouce face + profil', en: 'Inch face + profil' },
      {
        fr: 'Scaphoïde: poignet 2 incidences scaphoïde (incidence de schneck)',
        en: 'Scaphoid: wrist 2 scaphoid incidences (schneck incidence)',
      },
      {
        fr: 'Scaphoïde: poignet incidence de face, pronation, poing serré avec serrement actif du poignet ',
        en: 'Scaphoid: wrist frontal impact, pronation, tight fist with active wrist tightening',
      },
      {
        fr: 'Scaphoïde: poignet incidence de larsen',
        en: 'Scaphoid: wrist incidence of larsen',
      },
      {
        fr: 'Scaphoïde: poignet incidence de ziter',
        en: 'Scaphoid: wrist incidence of ziter',
      },
    ],
    en: 'X-RAY OF THE UPPER LIMB',
    fr: 'RADIOGRAPHIES DU MEMBRE SUPERIEUR',
  },
  [ImageryExamCategories.SPINE_X_RAY]: {
    denominations: [
      { fr: 'Profil sacro-coccygien', en: 'Sacro-coccygeal profile' },
      {
        fr: 'Rachis cervical 3/4 droit + 3/4 gauche',
        en: 'Cervical spine 3/4 right + 3/4 left',
      },
      {
        fr: 'Rachis cervical bouche ouverte',
        en: 'Cervical spine open mouth',
      },
      {
        fr: 'Rachis cervical clichés dynamiques',
        en: 'Cervical spine dynamic snapshots',
      },
      {
        fr: 'Rachis cervical face + profil',
        en: 'Cervical spine face + profile',
      },
      {
        fr: 'Rachis cervical incidence de dorland bilatérale',
        en: 'Cervical spine bilateral dorland incidence',
      },
      {
        fr: 'Rachis cervical incidence de dorland unilatérale',
        en: 'Cervical spine incidence of unilateral dorland',
      },
      {
        fr: 'Rachis cervical incidence du faux profil de roy camille',
        en: 'Cervical spine incidence of the false profile of roy camille',
      },
      {
        fr: 'Rachis cliché centre sur l5-s1',
        en: 'Spine shot center on l5-s1',
      },
      {
        fr: 'Rachis dorsal 3/4 droit + 3/4 gauche',
        en: 'Dorsal spine 3/4 right + 3/4 left',
      },
      {
        fr: 'Rachis dorsal face + profil',
        en: 'Dorsal spine face + profile',
      },
      {
        fr: 'Rachis dorsale clichés dynamiques',
        en: 'Dorsal spine dynamic snapshots',
      },
      {
        fr: 'Rachis lombaire 3/4 droit + 3/4 gauche',
        en: 'Lumbar spine 3/4 right + 3/4 left',
      },
      {
        fr: 'Rachis lombaire clichés dynamiques',
        en: 'Lumbar spine dynamic snapshots',
      },
      {
        fr: 'Rachis lombaire face + profil',
        en: 'Lumbar spine face + profil',
      },
    ],
    en: 'SPINE X-RAY',
    fr: 'RADIOGRAPHIES DU RACHIS',
  },
  [ImageryExamCategories.SCANNER]: {
    denominations: [
      { fr: 'Abdominal', en: 'Abdominal scan' },
      { fr: 'Arthroscanner cheville', en: 'Arthroscan ankle' },
      { fr: 'Arthroscanner coude', en: 'Arthroscan elbow' },
      { fr: 'Arthroscanner épaule', en: 'Arthroscan shoulder' },
      { fr: 'Arthroscanner genou', en: 'Arthroscan knee' },
      { fr: 'Arthroscanner hanche', en: 'Arthroscan hip' },
      { fr: 'Arthroscanner main', en: 'Arthroscan hand' },
      { fr: 'Arthroscanner pied', en: 'Arthroscan foot' },
      { fr: 'Arthroscanner poignet', en: 'Arthroscan wrist' },
      { fr: 'Cérébral', en: 'Cerebral scan' },
      { fr: 'Coloscanner', en: 'Coloscan' },
      { fr: 'Massif facial', en: 'Massive facial scan' },
      { fr: 'Pelvis', en: 'Pelvis scan' },
      { fr: 'Rachidien', en: 'Spinal scan' },
      { fr: 'Rachis cervical', en: 'Cervical spine scan' },
      { fr: 'Rachis dorsal', en: 'Dorsal spine scan' },
      { fr: 'Rachis lombaire', en: 'Lumbar spine scan' },
      { fr: 'Rachis lombosacré', en: 'Spine lumbosacral sc...' },
      { fr: 'Sinus', en: 'Sinus' },
      { fr: 'Thoracique', en: 'Thoracic scan' },
      { fr: 'Thoraco-abdominal', en: 'Thoracoabdominal scan' },
      {
        fr: 'Thoraco-abdomino-pelvien',
        en: 'Thoraco-abdominopelvic scan',
      },
      { fr: 'Uroscanner', en: 'Ct urography' },
    ],
    en: 'SCANNER',
    fr: 'SCANNER',
  },
  [ImageryExamCategories.SCINTIGRAPHY]: {
    denominations: [
      { fr: 'Osseuse', en: 'Bone scan or scintihgraphy' },
      { fr: 'Pulmonaire', en: 'Pulmonary scintigraphy' },
    ],
    en: 'SCINTIGRAPHY',
    fr: 'SCINTIGRAPHIE',
  },
};

export const biologyExams = {
  [BiologyExamCategories.ADRENAL]: {
    denominations: [
      {
        fr: '17 hydroxy-corticostéroïdes ou tétrahydro-11 désoxycortisol (ths) urinaires',
        en: '17oh steroide',
      },
      {
        fr: '17-cétostéroïdes urinaires',
        en: '17 cetosteroide urine',
      },
      { fr: '17-oh-progestérone', en: '17 hydroxyprogesterone' },
      {
        fr: 'Acide vanilmandélique (métabolite des catécholamines) urinaire',
        en: 'Vanillylmandelic acid',
      },
      {
        fr: 'Aldostérone ou tétrahydro-aldostérone urinaires',
        en: 'Aldosterone urine',
      },
      { fr: 'Aldostérone plasmatique', en: 'Aldosterone serum' },
      {
        fr: 'Catécholamines totales (ou métanéphrines ou acide homovanillique) urinaires',
        en: 'Catecholamine urine test',
      },
      { fr: 'Corticotropine (acth)', en: 'Acth' },
      {
        fr: 'Cortisol libre urinaire sur 24h',
        en: 'Free cortisol h24 urine',
      },
      { fr: 'Cortisol sanguin', en: 'Cortisol serum' },
      {
        fr: 'Déhydroépiandrostérone (dha) plasmatique',
        en: 'Dhea serum',
      },
      { fr: 'Delta 4 androsténedione', en: 'Delta 4 androstenedione' },
      { fr: 'Dihydrotestostérone (dht)', en: 'Dihydrotestosterone' },
      { fr: 'Rénine', en: 'Renin' },
      { fr: 'Sulfate de dha', en: 'Dhea sulfate' },
      { fr: 'Test au synacthène', en: 'Synacthen test' },
      {
        fr: 'Titrage des autoanticorps antisurrénale',
        en: 'Antiadrenal antibody',
      },
    ],
    en: 'ADRENAL',
    fr: 'SURRENALES',
  },
  [BiologyExamCategories.ALLERGY]: {
    denominations: [
      {
        fr: 'Dosage pondéral des immunoglobulines (iga, igg, igm)',
        en: 'Quantitative immunoglobulin test (iga, igg, igm)',
      },
      {
        fr: 'Ecp eosino cationique protéine',
        en: 'Eosinophil cationic protein',
      },
      {
        fr: 'Ige spécifiques : identification non quantitative',
        en: 'Specific ig e',
      },
      { fr: 'Ige totales sériques', en: 'Total ig e' },
      {
        fr: "Recherche d'ige spécifiques de .... (cf liste thermoficher)",
        en: 'Allergen specific ig e',
      },
      { fr: 'Tryptase basale', en: 'Tryptase' },
    ],
    en: 'ALLERGY',
    fr: 'ALLERGOLOGIE',
  },
  [BiologyExamCategories.BACTERIOLOGY]: {
    denominations: [
      { fr: 'Coqueluche pcr', en: 'Bordetella pertussis pcr' },
      {
        fr: 'Hélicobacter pylori: test respiratoire à l’urée marquée au carbone 13',
        en: 'Helicobacter pylori respiratory test',
      },
      {
        fr: 'Identification biochimique de mycobacterium tuberculosis',
        en: 'Mycobacterium tuberculosis screening test',
      },
      {
        fr: "Identification biochimique et/ou antigénique d'une bactérie anaérobie",
        en: 'Biochemical and / or antigenic identification of anaerobic bacterium',
      },
      {
        fr: 'Isolement de borrelia par culture',
        en: 'Lyme disease western blot',
      },
      { fr: 'Quantiféron', en: 'Quantiferon' },
      {
        fr: 'Recherche de clostridium difficile',
        en: 'Clostridium difficile culture',
      },
      {
        fr: 'Recherche de mycobactéries',
        en: 'Mycobacterium screening test',
      },
      {
        fr: 'Recherche des rickettsiales par examen direct et isolement',
        en: 'Rickettsiales  test- direct and isolation',
      },
      { fr: 'Sérologie à campylobacter', en: 'Campylobacter antibody' },
      {
        fr: 'Sérologie à chlamydia pneumoniae',
        en: 'Chlamydia pneumoniae antibody',
      },
      {
        fr: 'Sérologie à chlamydia psittaci',
        en: 'Chlamydia psittaci antibody',
      },
      { fr: 'Sérologie à légionelloses', en: 'Legionella antibody' },
      {
        fr: 'Sérologie à mycoplasmes respiratoires',
        en: 'Mycoplasma pneumoniae antibody',
      },
      { fr: 'Sérologie à rickettsioses', en: 'Rickettsia antibody' },
      {
        fr: 'Sérologie bartonellose (maladie des griffes du chat)',
        en: 'Bartonella antibody',
      },
      {
        fr: 'Sérologie borréliose (maladie de lyme)',
        en: 'Lyme disease antibody',
      },
      { fr: 'Sérologie brucellose', en: 'Brucella antibody' },
      { fr: 'Sérologie chlamydia', en: 'Chlamydia antibody' },
      {
        fr: 'Sérologie fièvre q (coxiella burnetti)',
        en: 'Q fever antibody',
      },
      {
        fr: 'Sérologie hélicobacter pylori',
        en: 'Helicobacter pylori antigen',
      },
      { fr: 'Sérologie salmonelloses', en: 'Salmonella antibody' },
      { fr: 'Sérologie streptococcies', en: 'Streptococcus antibody' },
      { fr: 'Sérologie syphilis tpha vdrl', en: 'Syphilis antibody' },
      { fr: 'Sérologie tétanos', en: 'Tetanus antibody' },
      { fr: 'Sérologie tularémie', en: 'Tularemia antibody' },
      { fr: 'Sérologie yersinia', en: 'Yersinia antibody' },
      { fr: 'Tuberculose pcr', en: 'Bk virus pcr' },
    ],
    en: 'BACTERIOLOGY',
    fr: 'BACTERIOLOGIE',
  },
  [BiologyExamCategories.BLOOD_BIOCHEMISTRY]: {
    denominations: [
      { fr: 'Acide lactique', en: 'Lactate' },
      { fr: 'Acide urique', en: 'Uric acid' },
      { fr: 'Ammoniaque', en: 'Ammonia plasma' },
      { fr: 'Bicarbonates', en: 'Bicarbonate' },
      { fr: 'Bicarbonates ou co2 total', en: 'Bicarbonate test' },
      { fr: 'Calcémie', en: 'Calcium' },
      { fr: 'Calcémie corrigée', en: 'Corrected calcium' },
      { fr: 'Calcémie ionisée', en: 'Ionized calcium' },
      { fr: 'Chlorémie', en: 'Chloride' },
      { fr: 'Clearance de la créatinine', en: 'Creatinine clearance' },
      { fr: 'Créatininémie', en: 'Creatinine blood' },
      { fr: 'Cuivre sérique', en: 'Copper plasma' },
      { fr: 'Fer sérique', en: 'Iron plasma' },
      { fr: 'Gazométrie artérielle', en: 'Blood gases' },
      { fr: 'Glycémie', en: 'Glucose blood' },
      { fr: 'Ionogramme (k+, na+, cl-)', en: 'Electrolytes' },
      {
        fr: 'Ionogramme complet (k+, na+, cl-, hco3-, protides totaux)',
        en: 'Electrolytes (k+, na+, cl-, hco3-, protein)',
      },
      { fr: 'Kaliémie', en: 'Potassium levels' },
      { fr: 'Magnésium plasmatique', en: 'Magnesium plasma' },
      { fr: 'Méthémoglobine', en: 'Methemoglobin' },
      { fr: 'Natrémie', en: 'Sodium plasma' },
      { fr: 'Osmolarité mesurée', en: 'Osmolality' },
      { fr: 'Phosphore minéral', en: 'Phosphorus' },
      { fr: 'Saturation en oxygène (sa o2)', en: 'Oxygen saturation' },
      { fr: 'Urée sanguine', en: 'Urea blood' },
      { fr: 'Zinc plasmatique', en: 'Zinc plasma' },
    ],
    en: 'BLOOD BIOCHEMISTRY',
    fr: 'BIOCHIMIE SANGUINE',
  },
  [BiologyExamCategories.DIABETOLOGY]: {
    denominations: [
      { fr: 'Anticorps anti gad', en: 'Gad antibody' },
      { fr: 'Anticorps anti-ia2', en: 'Anti-ia-2 antibody' },
      { fr: 'C peptide sanguin', en: 'C peptide blood' },
      { fr: 'C peptide urinaire', en: 'C peptide urine' },
      { fr: 'Fructosamines', en: 'Fructosamine' },
      { fr: 'Glycémie à jeun', en: 'Fasting blood glucose' },
      { fr: 'Glycémie non à jeun', en: 'Glucose blood' },
      { fr: 'Hba1c (hémoglobine glyquée)', en: 'Hemoglobin a1c' },
      { fr: 'Hgpo', en: 'Hour oral glucose tolerance test (ogtt)' },
      { fr: 'Insuline', en: 'Insulin blood' },
      { fr: 'Insuline libre', en: 'Insulin free blood' },
      {
        fr: 'Test de diagnostic de diabète gestationnel',
        en: 'Glucose tolerance test pregnancy',
      },
      {
        fr: "Titrage des anticorps anticellules d'îlots de langerhans du pancréas",
        en: 'Anti human lagerhan cells antibody',
      },
      {
        fr: 'Titrage des autoanticorps anti-insuline',
        en: 'Anti-insulin antibody',
      },
    ],
    en: 'DIABETOLOGY',
    fr: 'DIABETOLOGIE',
  },
  [BiologyExamCategories.DIGESTIVE_TRACK]: {
    denominations: [
      {
        fr: "Autoanticorps anticellules pariétales de l'estomac",
        en: 'Antiparietal cell antibody',
      },
      {
        fr: 'Autoanticorps antifacteur intrinsèque',
        en: 'Intrinsic factor antibody',
      },
      { fr: 'Gastrine', en: 'Gastrin serum' },
      {
        fr: 'Peptide vasoactif intestinal (vip)',
        en: 'Vasoactive intestinal peptide',
      },
    ],
    en: 'DIGESTIVE TRACK',
    fr: 'TUBE DIGESTIF ET ANNEXES',
  },
  [BiologyExamCategories.DRUGS_AND_TOXIC_SUBSTANCES]: {
    denominations: [
      { fr: 'Acide valproïque', en: 'Valproic acid dosage' },
      { fr: 'Dépakinémie', en: 'Depakinemia' },
      { fr: 'Digoxinémie', en: 'Digoxinemia' },
      { fr: 'Gardenalémie', en: 'Gardenal dosage' },
      { fr: 'Lamictalémie', en: 'Lamictal dosage' },
      { fr: 'Lithiémie', en: 'Lithemia' },
      { fr: 'Plombémie', en: 'Lead blood' },
      { fr: 'Quininémie', en: 'Quininemia' },
      { fr: 'Tégrétolémie', en: 'Tegretolemia' },
    ],
    en: 'DRUGS AND TOXIC SUBSTANCES',
    fr: 'MEDICAMENTS ET TOXIQUES',
  },
  [BiologyExamCategories.ENDOCRINOLOGY]: {
    denominations: [
      {
        fr: 'Ac anticellules productrices de stéroïdes',
        en: 'Steroid cell antibody',
      },
      {
        fr: "Ac antimembrane basale d'épithélium malpighien",
        en: 'Skin antibody',
      },
      {
        fr: 'Ac autoanticorps antimembrane basale glomérulaire',
        en: 'Anti-glomerular basement membrane antibody',
      },
      {
        fr: 'Acide hydroxy-indole-acétique (métabolite de la sérotonine) urinaire',
        en: 'Hydroxy-indole-acetic acid (metabolite of serotonin) urinary',
      },
      { fr: 'Bêta hcg sanguin', en: 'Bhcg blood' },
      { fr: 'Bêta hcg urinaire', en: 'Bhcg urine' },
      { fr: 'Erythropoïétine', en: 'Erythopoetin' },
      { fr: 'Estradiol', en: 'Estradiol' },
      { fr: 'Fsh', en: 'Fsh' },
      { fr: 'Hormone antimullérienne', en: 'Anti mullerian hormone' },
      {
        fr: 'Hormone de croissance (hgh, somatotropine)',
        en: 'Growth hormon',
      },
      { fr: 'Igfbp3', en: 'Igfbp3' },
      { fr: 'Inhibines', en: 'Inhibin' },
      { fr: 'Lh', en: 'Lh' },
      { fr: 'Ostéocalcine', en: 'Osteocalcin' },
      { fr: 'Progestérone', en: 'Progesteron' },
      { fr: 'Prolactine', en: 'Prolactin' },
      {
        fr: 'Protéine de transport des hormones sexuelles (te bg, sbg)',
        en: 'Sex hormon transport protein',
      },
      {
        fr: 'Séparation chromatographique des formes moléculaires de la prolactine',
        en: 'Séparation chromatographique des formes moléculaires de la prolactine',
      },
      {
        fr: 'Sérotonine par chromatographie liquide à haute performance',
        en: 'Serotonin',
      },
      { fr: 'Sex binding globulin (shbg)', en: 'Sex binding globulin' },
      { fr: 'Somatomédine (igf1 - smc)', en: 'Somatomedin' },
      { fr: 'Test au lh-rh court', en: 'Lhrh stimulation test' },
      { fr: 'Testostérone', en: 'Testosteron' },
      {
        fr: 'Testostérone libre ou biodisponible dans le sang',
        en: 'Free testosteron',
      },
    ],
    en: 'ENDOCRINOLOGY',
    fr: 'ENDOCRINOLOGIE',
  },
  [BiologyExamCategories.ENZYMES]: {
    denominations: [
      { fr: "5'nucléotidase", en: "5'nuclotidase" },
      { fr: 'Alat (tgp)', en: 'Alanine aminotransferase (alt)' },
      { fr: 'Aldolase', en: 'Aldolase' },
      { fr: 'Amylasémie', en: 'Amylase' },
      { fr: 'Asat (tgo)', en: 'Aspartate aminotransferase (ast)' },
      {
        fr: 'Bilirubines libre et conjuguée',
        en: 'Free and conjugated bilirubin',
      },
      {
        fr: 'Créatine phosphokinase (cpk)',
        en: 'Creatin phosphokinase',
      },
      { fr: 'Créatine phosphokinase mb', en: 'Ck mb' },
      { fr: 'Enzyme de conversion', en: 'Converting enzym' },
      {
        fr: 'Gamma glutamyl transférase ggt',
        en: 'Gamma-glutamyl transferase (ggt)',
      },
      {
        fr: 'Glucose - 6 - phosphate déshydrogénase (g6pd)',
        en: 'G6pd',
      },
      {
        fr: 'Lactate déshydrogénase (l.d.h.)',
        en: 'Dehydrogenase lactate',
      },
      { fr: 'Lipasémie', en: 'Lipasemia' },
      {
        fr: 'Lysozyme ou muramidase dans le sang',
        en: 'Lysozyme blood',
      },
      {
        fr: 'Lysozyme ou muramidase dans les urines',
        en: 'Lysozyme urinary',
      },
      { fr: 'Phosphatases alcalines', en: 'Alkaline phosphataseas' },
      { fr: 'Pyruvate kinase', en: 'Pyruvat kinase' },
    ],
    en: 'ENZYMES',
    fr: 'ENZYMOLOGIE',
  },
  [BiologyExamCategories.HEMATOLOGY]: {
    denominations: [
      { fr: "Dosage de l'hémoglobine", en: 'Hemoglobin' },
      { fr: "Dosage de l'hémoglobine f", en: 'Haemoglobin f' },
      { fr: 'Dosage de la methémoglobine', en: 'Methemoglobin' },
      {
        fr: 'Dosage sanguin des protoporphyrines',
        en: 'Protoporphyrin',
      },
      {
        fr: 'Électrophorèse de hémoglobine',
        en: 'Hemoglobin electrophoresis',
      },
      { fr: 'Formule leucocytaire', en: 'Hematologic profile' },
      { fr: 'Hématocrite', en: 'Hematocrit' },
      {
        fr: 'Mesure de la résistance globulaire osmotique',
        en: 'Measurement of osmotic globular resistance',
      },
      { fr: 'Myélogramme', en: 'Bone marrow biopsy' },
      { fr: 'Nfs-plaquettes (numération + formule)', en: 'Hemogram' },
      { fr: 'Numération des hématies', en: 'Red blood cell count' },
      { fr: 'Numération des leucocytes', en: 'White blood cell count' },
      { fr: 'Numération des lymphocytes', en: 'Lymphocyt count' },
      {
        fr: 'Numération des lymphocytes t4 t8',
        en: 'Lymphocyt t4-t8 count',
      },
      { fr: 'Numération des plaquettes', en: 'Platelet count' },
      { fr: 'Numération des réticulocytes', en: 'Reticulocyt count' },
      { fr: 'Numération sanguine', en: 'Complete blood count' },
      {
        fr: "Recherche d'une hémoglobinurie nocturne paroxystique",
        en: 'Paroxysmal nocturnal hemoglobinuria',
      },
      { fr: 'Recherche des corps de heinz', en: 'Heinz bodies' },
      { fr: 'Recherche des drépanocytes', en: 'Sickle cell test' },
      {
        fr: "Recherche-quantification d'hématies foetles par test de kleihauer",
        en: 'Kleihauer test',
      },
      { fr: "Test à l'isopropanol", en: 'Isopropanol test' },
      { fr: "Test d'auto-hémolyse", en: 'Autohemolysis test' },
      {
        fr: "Test de solubilité de l'hémoglobine",
        en: 'Solubility test of hemoglobin',
      },
      { fr: 'Vitesse de sédimentation', en: 'Sedimentation rate' },
      { fr: 'Volume globulaire moyen', en: 'Red cell indices' },
    ],
    en: 'HEMATOLOGY',
    fr: 'HEMATOLOGIE',
  },
  [BiologyExamCategories.HEMOSTASIS_AND_COAGULATION]: {
    denominations: [
      { fr: 'Cryofibrinogène', en: 'Cryofibrinogen' },
      {
        fr: "Dépistage d'un anticoagulant circulant",
        en: 'Circulating anticoagulant screen',
      },
      {
        fr: 'Détermination semi-quantitative des d-dimères ou des pdf',
        en: 'D dimer and fdp quantitativ',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur ii",
        en: 'Factor ii',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur ix",
        en: 'Factor ix',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur v",
        en: 'Factor v',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur vii",
        en: 'Factor vii',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur viii",
        en: 'Factor viii',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur x",
        en: 'Factor x',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur xi",
        en: 'Factor xi',
      },
      {
        fr: "Dosage de l'activité coagulante du facteur xii",
        en: 'Factor xii',
      },
      { fr: "Dosage de l'antithrombine", en: 'Antithrombin iii' },
      { fr: 'Dosage de la protéine c anticoagulante', en: 'Protein c' },
      { fr: 'Dosage de la protéine s anticoagulante', en: 'Protein s' },
      {
        fr: 'Dosage du facteur willebrand',
        en: 'Von willebrand factor',
      },
      { fr: 'Dosage du facteur xiii', en: 'Factor xiii' },
      { fr: 'Dosage du fibrinogène (facteur i)', en: 'Fibrinogen' },
      { fr: 'Dosage quantitatif des d-dimères', en: 'D-dimer' },
      { fr: 'Héparine standard', en: 'Heparin' },
      { fr: 'Inr', en: 'Inr' },
      {
        fr: "Mesure de l'activité anti xa",
        en: 'Anti factor xa heparin',
      },
      {
        fr: 'Recherche d’anticorps anti-facteur 4 plaquettaire (pf4)',
        en: 'Heparin-induced thrombocytopenia pf4 antibody',
      },
      {
        fr: 'Recherche d"un inhibiteur anti-facteur viii ou anti-facteur ix',
        en: 'Anti-factor viii or anti-factor ix inhibitor detection',
      },
      {
        fr: 'Recherche et identification d’un anticoagulant de type lupique',
        en: 'Lupus anticoagulant',
      },
      {
        fr: 'Résistance protéine c (facteur v leiden)',
        en: 'Apc resistance profile with reflex to factor v leiden',
      },
      { fr: 'Tca', en: 'Ptt ou aptt' },
      { fr: 'Temps de quick', en: 'Prothrombin time' },
      { fr: 'Tp', en: 'Prothrombin time' },
    ],
    en: 'HEMOSTASIS AND COAGULATION',
    fr: 'HEMOSTASE ET COAGULATION',
  },
  [BiologyExamCategories.IMMUNOHEMATOLOGY]: {
    denominations: [
      {
        fr: 'Dépistage et titrage des anticorps immuns du système abo',
        en: 'Abo system immune antibodies',
      },
      {
        fr: 'Détermination des phénotypes rh (hors antigène d) antigène c, c, e, e et kell (k)',
        en: 'Rh type + ag c, c, e, e, kell',
      },
      {
        fr: "Epreuve d'absorption d'anticorps sur des globules rouges",
        en: 'Antibody absorption test on red blood cells',
      },
      {
        fr: "Epreuve d'élution d'anticorps à partir de globules rouges",
        en: 'Antibody elution test from red blood cells',
      },
      {
        fr: 'Epreuve directe de compatibilité',
        en: 'Direct compatibility test',
      },
      { fr: 'Groupage granulocytaire', en: 'Granulocyte aggregation' },
      { fr: 'Groupage plaquettaire (pla)', en: 'Platelet grouping' },
      { fr: 'Groupage sanguin abo-rh', en: 'Abo group + rh type' },
      {
        fr: "Recherche d'anticorps antigranulocytaires",
        en: 'Research of anti-granulocyte antibodies',
      },
      {
        fr: "Recherche d'anticorps antiplaquettaires",
        en: 'Anti-platelet antibodies bound or free',
      },
      {
        fr: "Recherche d'anticorps irréguliers (rai)",
        en: 'Irregular antibody screen',
      },
      { fr: 'Test de coombs', en: 'Coombs test' },
    ],
    en: 'IMMUNOHEMATOLOGY',
    fr: 'IMMUNO-HEMATOLOGIE',
  },
  [BiologyExamCategories.INFECTIOLOGY]: {
    denominations: [
      { fr: 'Coproculture', en: 'Stool culture' },
      { fr: 'Crp', en: 'C-reactive protein' },
      {
        fr: 'Détermination de la concentration minimale inhibitrice (cmi) d’un micro-organisme',
        en: 'Determination of the minimum inhibitory concentration (mic) of a microorganism',
      },
      {
        fr: "Etude de la sensibilité d'une mycobactérie vis-à-vis des antibiotiques",
        en: 'Study of the susceptibility of a mycobacterium to antibiotics',
      },
      {
        fr: 'Examen parasitologique des selles',
        en: 'Stool parasitology',
      },
      { fr: 'Hémocultures aérobies', en: 'Blood culture' },
      {
        fr: 'Hémocultures anaérobies',
        en: 'Anaerobe culture and gram stain',
      },
      { fr: 'Procalcitonine pct', en: 'Procalcitonin' },
      { fr: 'Vs vitesse de sédimentation', en: 'Sedimentation rate' },
    ],
    en: 'INFECTIOLOGY',
    fr: 'INFECTIEUX - GENERAL',
  },
  [BiologyExamCategories.KIDNEY_STONES]: {
    denominations: [
      {
        fr: 'Analyse morpho-constitutionnelle des calculs par analyse séquentielle optique et physique',
        en: 'Morpho-constitutional analysis of stones by optical and physical sequential analysis',
      },
    ],
    en: 'KIDNEY STONES',
    fr: 'CALCULS',
  },
  [BiologyExamCategories.LIPID_PANEL]: {
    denominations: [
      { fr: 'Apo e', en: 'Apolipoprotein e' },
      { fr: 'Apolipoprotéines a1', en: 'Apolipoprotein a1' },
      { fr: 'Apolipoprotéines b', en: 'Apolipoprotein b' },
      { fr: 'Cholestérol hdl et ldl', en: 'Cholesterol hdl-ldl' },
      { fr: 'Cholestérol total', en: 'Cholesterol' },
      { fr: 'Chylomicron', en: 'Chylomicron body fluid' },
      { fr: 'Ratio b/a', en: 'Apolipoprotein b/a ratio' },
      { fr: 'Triglycérides', en: 'Triglyceride' },
    ],
    en: 'LIPID PANEL',
    fr: 'BILAN LIPIDIQUE',
  },
  [BiologyExamCategories.NEUROMUSCULAR_SYSTEM]: {
    denominations: [
      {
        fr: "Autoanticorps antirécepteur de l'acétylcholine",
        en: 'Acetylcholine recpetor antibody',
      },
    ],
    en: 'NEUROMUSCULAR SYSTEM',
    fr: 'SYSTEME NEURO-MUSCULAIRE',
  },
  [BiologyExamCategories.OTHER_SAMPLINGS]: {
    denominations: [
      {
        fr: 'Anatomopathologie sur frottis vaginal',
        en: 'Anatomopathology on vaginal smear',
      },
      { fr: 'Bk crachats', en: 'Bacillus of koch sputum' },
      { fr: 'Bk tubage', en: 'Bacillus of koch casing' },
      {
        fr: 'Examen cytobactériologique des crachats',
        en: 'Cytobacteriological examination of sputum',
      },
      {
        fr: 'Prélèvement sur écouvillon: bactériologie',
        en: 'Swab sampling: bacteriology',
      },
      {
        fr: 'Prélèvement sur écouvillon: bordetella pertussis par pcr (coqueluche)',
        en: 'Swab sampling: bordetella pertussis by pcr (whooping cough)',
      },
      {
        fr: 'Prélèvement sur écouvillon: mycologie',
        en: 'Swab sampling: mycology',
      },
      {
        fr: 'Prélèvement sur écouvillon: pcr hsv',
        en: 'Swab sampling: hsv pcr',
      },
      {
        fr: 'Prélèvement sur sécrétions naso-pharyngées: virus grippe type a et b sur pcr',
        en: 'Nasopharyngeal secretions: influenza virus type a and b on pcr',
      },
      {
        fr: 'Prélèvement urétral: bactériologie',
        en: 'Urethral sampling: bacteriology',
      },
      {
        fr: 'Prélèvement urétral: mycoplasme',
        en: 'Urethral swab: mycoplasma',
      },
      {
        fr: 'Prélèvement urétral: pcr chlamydia',
        en: 'Urethral sampling: chlamydia pcr',
      },
      {
        fr: 'Prélèvement urétral: pcr gonocoque',
        en: 'Urethral sampling: gonococcal pcr',
      },
      {
        fr: 'Prélèvement vaginal: bactériologie',
        en: 'Vaginal sampling: bacteriology',
      },
      { fr: 'Prélèvement vaginal: herpès', en: 'Vaginal swab: herpes' },
      {
        fr: 'Prélèvement vaginal: mycoplasme',
        en: 'Vaginal swab: mycoplasma',
      },
      {
        fr: 'Prélèvement vaginal: pcr chlamydia',
        en: 'Vaginal sampling: chlamydia pcr',
      },
      {
        fr: 'Prélèvement vaginal: pcr gonocoque',
        en: 'Vaginal sampling: gonococcal pcr',
      },
      {
        fr: 'Recherche de dermatophytes sur peau/phanère',
        en: 'Search for dermatophytes on skin / dander',
      },
      {
        fr: 'Recherche de levures sur peau/phanère',
        en: 'Search for yeasts on skin / dander',
      },
      {
        fr: 'Test respiratoire hélicobacter pylori',
        en: 'Helicobacter pylori breath test',
      },
    ],
    en: 'OTHER SAMPLINGS',
    fr: 'AUTRES PRELEVEMENTS',
  },
  [BiologyExamCategories.PARASITOLOGY_AND_MYCOLOGY]: {
    denominations: [
      { fr: "Culture d'amibes", en: 'Amoeba culture' },
      {
        fr: 'Examen parasitologique du sang',
        en: 'Blood parasitology',
      },
      { fr: 'Frottis sanguin', en: 'Thick and thin blood smears' },
      { fr: 'Goutte épaisse', en: 'Thick and thin blood smears' },
      {
        fr: "Recherche d'amibes dans un prélèvement de mucus",
        en: 'Amoeba on removal of mucus',
      },
      {
        fr: "Recherche d'oeufs d'helminthes sur la marge de l'anus",
        en: 'Helminthe eggs',
      },
      { fr: 'Recherche de cryptosporidium', en: 'Cryptosporidiosis' },
      {
        fr: 'Recherche des hématozoaires sur frottis et en goutte épaisse',
        en: 'Haematozoon (thick or thin smear)',
      },
      { fr: 'Recherche des oeufs de bilharzies', en: 'Bilharzia eggs' },
      {
        fr: "Recherche et/ou identification éventuelle d'un parasite",
        en: 'Check for / identification of parasites',
      },
      {
        fr: "Recherche sur selles des larves d'anguillules",
        en: 'Anguillula larvae',
      },
      { fr: 'Scotch test', en: 'Scotch test' },
      { fr: 'Sérologe cysticercose', en: 'Cysticercosis antibody' },
      {
        fr: 'Sérologie alvéolites allergiques exogènes',
        en: 'Exogenous allergic alveolitis serology',
      },
      { fr: 'Sérologie amibiase', en: 'Amebiasis antibody' },
      { fr: 'Sérologie anisakiase', en: 'Anisakis antibody' },
      { fr: 'Sérologie ascaridiose', en: 'Ascaris antibody' },
      { fr: 'Sérologie aspergillose', en: 'Aspergillus antibody' },
      { fr: 'Sérologie candidose', en: 'Candida antibody' },
      { fr: 'Sérologie cryptoccocose', en: 'Cryptococcus antibody' },
      { fr: 'Sérologie distomatose', en: 'Distomiasis antibody' },
      { fr: 'Sérologie echinococcoses', en: 'Echinococcus antibody' },
      { fr: 'Sérologie filariose', en: 'Filariasis antibody' },
      { fr: 'Sérologie histoplasmose', en: 'Histoplasma antibody' },
      { fr: 'Sérologie hypodermose', en: 'Hypoderma antibody' },
      { fr: 'Sérologie leishmaniose', en: 'Leishmania antibody' },
      { fr: 'Sérologie lyme', en: 'Lyme antibody' },
      {
        fr: "Sérologie maladie du poumon d'éleveur d'oiseaux",
        en: "Pigeon breeder's lung disease serology",
      },
      {
        fr: 'Sérologie maladie du poumon de fermier (actinomycètes thermophiles)',
        en: "Farmer's lung disease serology",
      },
      { fr: 'Sérologie paludisme', en: 'Malaria antibody' },
      { fr: 'Sérologie schistosomiase', en: 'Schistosoma antibody' },
      {
        fr: 'Sérologie toxocarose (larva migrans viscérale)',
        en: 'Toxocara antibody',
      },
      { fr: 'Sérologie toxoplasmose', en: 'Toxoplasma antibody' },
      { fr: 'Sérologie trichinose', en: 'Trichinella antibody' },
      { fr: 'Sérologie trypanosomiase', en: 'Trypanosoma antibody' },
    ],
    en: 'PARASITOLOGY AND MYCOLOGY',
    fr: 'PARASITOLOGIE ET MYCOLOGIE',
  },
  [BiologyExamCategories.PROTEINS_AND_AUTOIMMUNITY]: {
    denominations: [
      { fr: "5'nucléotidase", en: "5'nucleotidase" },
      { fr: 'Aan anticorps antinucléaires', en: 'Ana' },
      { fr: 'Ac anti ag solubles', en: 'Soluble antigen antibody' },
      { fr: 'Ac anti ccp', en: 'Ccp antibody' },
      { fr: 'Ac anti dna natif', en: 'Anti-dna antibody' },
      { fr: 'Ac anti ena natif', en: 'Anti-ena antibody' },
      {
        fr: 'Ac anti endomysium iga et igg',
        en: 'Endomysial antibody',
      },
      { fr: 'Ac anti histones', en: 'Anti-histone antibody' },
      { fr: 'Ac anti lkm', en: 'Anti lkm antibody' },
      { fr: 'Ac anti muscle lisse', en: 'Anti-smooth muscle antibody' },
      { fr: 'Ac anti musk', en: 'Anti-musk antibody' },
      {
        fr: 'Ac anti peptides cycliques citrullinés',
        en: 'Cycle citrullinated peptide antibody',
      },
      {
        fr: 'Ac anti peroxydase atpo',
        en: 'Thyroid peroxydase antibody',
      },
      {
        fr: 'Ac anti phospholipides',
        en: 'Antiphospholipid antibodies',
      },
      {
        fr: "Ac anti récepteurs de l'acétylcholine(anti-rac)",
        en: 'Acetylcholine receptor binding antibody',
      },
      {
        fr: 'Ac anti récepteurs de la tsh (anti trak)',
        en: 'Tsh receptor stimulating antibody',
      },
      { fr: 'Ac anti réticuline', en: 'Anti-reticulin antibody' },
      { fr: 'Ac anti ribosome', en: 'Ribosomal antibody' },
      { fr: 'Ac anti sla', en: 'Sla antibody' },
      { fr: 'Ac anti sm', en: 'Anti sm antibody' },
      { fr: 'Ac anti ssa / anti ro', en: 'Anti ssa/ro antibody' },
      { fr: 'Ac anti ssb / anti la', en: 'Anti ssb/la antibody' },
      { fr: 'Ac anti thyroglobuline', en: 'Thyroglobulin antibody' },
      {
        fr: 'Ac anti transglutaminase iga - igg',
        en: 'Anti-transglutaminase iga igg antibody',
      },
      { fr: 'Ac anti-actine', en: 'Actin antibody' },
      { fr: 'Ac anti-centromères', en: 'Centromere antibody' },
      { fr: 'Ac anti-cytosol', en: 'Liver cytosolic antibody' },
      { fr: 'Ac anti-mitochondrie', en: 'Mitochondrial antibody' },
      { fr: 'Ac antic1q', en: 'Anti c1q antibody' },
      { fr: 'Ac anticardiolipines', en: 'Cardiolipin antibody' },
      {
        fr: 'Ac anticytoplasme des polynucléaires neutrophiles anti-pr3 et anti-mpo',
        en: 'Anti-neutrophil cytoplasmic mpo/pr3 antibody',
      },
      {
        fr: 'Ac antiréticulum endoplasmique (lkm et/ou anti-cytosol)',
        en: 'Endoplasmic reticulum marker antibody',
      },
      {
        fr: 'Ac beta2 glycoproteine1',
        en: 'Beta2 glycoprotein1 antibody',
      },
      { fr: 'Ac pm-scl', en: 'Anti-pm scl antibody' },
      {
        fr: 'Acc anticoagulant circulant',
        en: 'Circulating anticoagulant screen',
      },
      { fr: 'Acide hyaluronique', en: 'Hyaluronic acid' },
      { fr: 'Ag p24', en: 'P24 antigen' },
      { fr: 'Albumine', en: 'Albumin' },
      { fr: 'Alpha 1 antitrypsine', en: 'Alpha1-antitrypsin' },
      {
        fr: 'Alpha 1 glycoprotéine (acide orosomucoïde)',
        en: 'Alpha1 glycoprotein',
      },
      { fr: 'Alpha 2 macroglobuline', en: 'Alpha2 macroglobulin' },
      { fr: 'An anti golgi', en: 'Anti-golgi antibody' },
      {
        fr: 'Anca anticorps anticytoplasme des polynucléaires neutrophiles',
        en: 'Anca',
      },
      {
        fr: 'Aslo anticorps antistreptolysine du groupe o',
        en: 'Antistreptolysin aslo',
      },
      { fr: 'Bêta 2 microglobuline', en: 'Beta2 microglobulin' },
      { fr: 'C1 inhibiteur', en: 'C1 inhibitor' },
      { fr: 'C3', en: 'C3' },
      { fr: 'C4', en: 'C4' },
      { fr: 'Céruléoplasmine', en: 'Ceruloplasmin' },
      { fr: 'Ch50', en: 'Ch50' },
      {
        fr: 'Complément c1 estérase inhibiteur',
        en: 'C1 esterase inhibitor panel',
      },
      { fr: 'Cryoglobulinémie', en: 'Cryoglobulin' },
      {
        fr: 'Électrophorèse des protéines plasmatiques',
        en: 'Protein electrophoresis',
      },
      {
        fr: "Enzyme de conversion de l'angiotensine (eca)",
        en: 'Angiotensin converting enzyme',
      },
      { fr: 'Facteurs rhumatoides', en: 'Rheumatoid factor' },
      { fr: 'Fragment c1q', en: 'C1q fragment' },
      { fr: 'Haptoglobine', en: 'Haptoglobin' },
      {
        fr: 'Hla b27',
        en: 'Ankylosing spondylitis genotyping (hla b27)',
      },
      {
        fr: 'Identification de la cible des autoanticorps antiplaquettaires',
        en: 'Autoimmune platelet antibody screen',
      },
      {
        fr: 'Identification des cibles des autoanticorps antimembrane nucléaire.',
        en: 'Anti-nuclear envelope antibody',
      },
      { fr: 'Iga', en: 'Iga' },
      { fr: 'Igg', en: 'Igg' },
      { fr: 'Igm', en: 'Igm' },
      {
        fr: 'Immuncomplexes circulants',
        en: 'Circulating immune complex detection',
      },
      {
        fr: 'Immunofixation des proteines seriques',
        en: 'Protein immunofixation serum',
      },
      {
        fr: 'Immunoglobulines igg1, igg2, igg3, igg4',
        en: 'Igg1, igg2, igg3, igg4',
      },
      {
        fr: "Marqueurs de l'ostéoporose dans l'urine",
        en: 'Osteoporosis urine test',
      },
      { fr: 'Myoglobine', en: 'Myoglobin' },
      { fr: 'Ostéocalcine', en: 'Osteocalcin' },
      {
        fr: 'Peptides natriurétiques (anp, bnp, nt-probnp)',
        en: 'Bnp',
      },
      { fr: 'Phénotype hla classe i', en: 'Hla class i phenotype' },
      { fr: 'Phénotype hla classe ii', en: 'Hla class ii phenotype' },
      {
        fr: 'Phosphatase alcaline osseuse',
        en: 'Alkaline phosphatases bone',
      },
      { fr: 'Préalbumine', en: 'Prealbumin' },
      { fr: 'Protéine c réactive (crp)', en: 'C reactiv protein' },
      {
        fr: 'Protéines sériques ou plasmatiques totales',
        en: 'Total protein',
      },
      {
        fr: 'Rbp (retinol binding protein)',
        en: 'Retinol bonding protein',
      },
      {
        fr: 'Recherche de cryoglobulines',
        en: 'Cryoglobulin screening',
      },
      {
        fr: "Recherche ou typage d'une dysglobulinémie monoclonale",
        en: 'Monoclonal gammapathy screening',
      },
      { fr: 'Schizocytes', en: 'Schizocyte' },
      {
        fr: 'Telopeptides du collagène osseux de type 1 (ctx-crosslaps)',
        en: 'Ctx crosslaps',
      },
      {
        fr: "Test indirect pour la recherche d'autoanticorps antiplaquettaires circulants",
        en: 'Circulating autoimmune platelet antibody screen',
      },
      { fr: 'Troponine', en: 'Troponin' },
      { fr: 'Vitesse de sédimentation', en: 'Sedimentation rate' },
    ],
    en: 'PROTEINS AND AUTOIMMUNITY',
    fr: 'PROTEINES et AUTO-IMMUNITE',
  },
  [BiologyExamCategories.SEMEN_ANALYSIS]: {
    denominations: [
      {
        fr: 'Exploration biochimique de la fonction prostato-vésiculaire',
        en: 'Biochemical exploration of prostato-vesicular function',
      },
      { fr: 'Spermocytogramme', en: 'Spermocytogram' },
      { fr: 'Spermogramme', en: 'Seminogram' },
      {
        fr: 'Test post-coïtal ou test de huhner',
        en: "Post-coital test or huhner's test",
      },
    ],
    en: 'SEMEN ANALYSIS',
    fr: 'SPERMIOLOGIE',
  },
  [BiologyExamCategories.STDS]: {
    denominations: [
      { fr: 'Antigénémie p24', en: 'Hiv p24 antigen' },
      { fr: 'Arn viral vih plasmatique par pcr', en: 'Hiv viral rna' },
      {
        fr: "Pcr chlamydia trachomatis et gonocoque sur 1er jet d'urines (chez l'homme)",
        en: 'Chlamydia and gonorrhea pcr urine specimen',
      },
      {
        fr: 'Prélèvement urétral: examen direct + culture bactériologique + pcr gonocoque/chlamydia trachomatis/mycoplasma genitalium + antibiogramme',
        en: 'Male urethral swab',
      },
      {
        fr: 'Prélèvement vaginal: examen direct + culture bactériologique (recherche de gonocoque) + antibiogramme + pcr chlamydia trachomatis',
        en: 'Vulvar swab',
      },
      { fr: 'Sérologie syphilis tpha vdrl', en: 'Syphilis antibody' },
      {
        fr: 'Sérologie vhb : ag hbs, ac anti hbs et ac anti hbc',
        en: 'Hepatitis b antibody',
      },
      { fr: 'Sérologie vhc', en: 'Hepatitis c antibody' },
      { fr: 'Sérologie vih 1 et 2', en: 'Hiv 1 and 2 antibody' },
      { fr: 'Taux cd4', en: 'C4d+' },
    ],
    en: 'STDS',
    fr: 'INFECTIONS SEXUELLEMENT TRANSMISSIBLES',
  },
  [BiologyExamCategories.STOOL_TEST]: {
    denominations: [
      { fr: 'Calorimétrie fécale', en: 'Fecal calorimetry' },
      {
        fr: "Détermination de l'activité chymotrypsique fécale",
        en: 'Determination of fecal chymotrypsic activity',
      },
      {
        fr: "Dosage d'acides organiques fécaux",
        en: 'Determination of fecal organic acids',
      },
      {
        fr: "Dosage d'ammoniaque/dérivés aminés fécaux",
        en: 'Ammonia dosage / fecal amino derivatives',
      },
      {
        fr: "Dosage de l'alpha 1 antitrypsine fécale",
        en: 'Determination of alpha 1 fecal antitrypsin',
      },
      {
        fr: "Dosage de l'azote fécal soluble",
        en: 'Determination of soluble fecal nitrogen',
      },
      {
        fr: "Dosage de l'azote fécal total",
        en: 'Determination of total faecal nitrogen',
      },
      {
        fr: "Dosage de l'élastase fécale",
        en: 'Determination of fecal elastase',
      },
      {
        fr: 'Dosage des lipides totaux des selles',
        en: 'Determination of total feces lipids',
      },
      {
        fr: 'Examen microscopique direct et après colorations des selles',
        en: 'Direct microscopic examination and after stool colorations',
      },
      {
        fr: "Examen physique complet d'une selle",
        en: 'Full physical examination of feces',
      },
      {
        fr: 'Fécalogramme (ou coprologie fonctionnelle)',
        en: 'Fecalogram (or functional coprology)',
      },
      { fr: 'Ionogramme fécal', en: 'Fecal ionogram' },
      {
        fr: 'Osmolarité fécale mesurée et calcul du trou osmolaire',
        en: 'Fecal osmolarity measured and calculation of the osmolar hole',
      },
      {
        fr: 'Recherche de sang fécal (hématies et/ou hémoglobine humaine)',
        en: 'Fecal occult blood test',
      },
      { fr: 'Recherche de stéatorrhée sur 24h', en: 'Fecal fat' },
      {
        fr: 'Recherche des pigments biliaires fécaux',
        en: 'Research of fecal bile pigments',
      },
      {
        fr: "Test à l'hydrogène expiré",
        en: 'Exhaled hydrogen test',
      },
      { fr: 'Test au xylose', en: 'Xylose test' },
    ],
    en: 'STOOL TEST',
    fr: 'COPROLOGIE',
  },
  [BiologyExamCategories.THYROID_AND_PARATHYROID]: {
    denominations: [
      {
        fr: 'Autoanticorps antimicrosomes thyroïdiens',
        en: 'Antithyroid microsomal antibody',
      },
      {
        fr: 'Autoanticorps antirécepteurs de tsh',
        en: 'Tsh receptor stimulating antibody',
      },
      {
        fr: 'Autoanticorps antithyroglobuline',
        en: 'Antithyroglobulin antibody',
      },
      {
        fr: 'Autoanticorps antithyroperoxydase',
        en: 'Thyroid peroxydase antibody',
      },
      { fr: 'Calcitonine', en: 'Calcitonin' },
      { fr: 'Parathormone', en: 'Parathormon' },
      { fr: 'T3 libre (triiodothyronine libre)', en: 'Free t3' },
      { fr: 'T4 libre (thyroxine libre)', en: 'Free t4' },
      { fr: 'Test au trh', en: 'Trh test' },
      { fr: 'Thyroglobuline', en: 'Thyroglobin' },
      { fr: 'Tsh', en: 'Tsh' },
    ],
    en: 'THYROID AND PARATHYROID',
    fr: 'THYROIDE ET PARATHYROIDES',
  },
  [BiologyExamCategories.TUMORAL_BIOMARKERS]: {
    denominations: [
      { fr: 'Alpha-foetoprotéine (a.f.p.)', en: 'Alpha-fetoprotein' },
      { fr: 'Antigène ca 125', en: 'Cancer antigen 125' },
      {
        fr: 'Antigène ca 15-3',
        en: 'Cancer antigen-breast (ca 15-3)',
      },
      { fr: 'Antigène ca 19-9', en: 'Cancer antigen-gi (ca 19-9)' },
      {
        fr: 'Antigène carcino-embryonnaire (ace)',
        en: 'Carcinoembryonic antigen (ace)',
      },
      {
        fr: 'Antigène du carcinome à cellules squameuses (scc)',
        en: 'Squamous cell carcinoma antigen',
      },
      {
        fr: 'Antigène prostatique spécifique (psa)',
        en: 'Prostatic specific antigen (psa)',
      },
      {
        fr: 'Antigène prostatique spécifique libre (psa libre)',
        en: 'Free prostatic specific antigen',
      },
      {
        fr: 'Antigène tissulaire polypeptidique (tpa)',
        en: 'Tissue polypeptide antigen',
      },
      { fr: 'Ca 27.29', en: 'Cancer antigen 27.29' },
      { fr: 'Chromogranine a', en: 'Chromogranin a' },
      { fr: 'Cyfra 21-1', en: 'Cyfra 21-1' },
      { fr: 'Enolase (nse)', en: 'Neuron specific enolase (nse)' },
      {
        fr: 'Sous-unité bêta hcg libre',
        en: 'Free beta-subunit hcg',
      },
    ],
    en: 'TUMORAL BIOMARKERS',
    fr: 'MARQUEURS TUMORAUX',
  },
  [BiologyExamCategories.URINE_TEST]: {
    denominations: [
      { fr: 'Acétone urinaire', en: 'Urinary acetone' },
      { fr: 'Acide urique urinaire', en: 'Urinary uric acid' },
      { fr: 'Acides aminés libres', en: 'Free amino acids' },
      { fr: 'Acides aminés totaux', en: 'Total amino acids' },
      {
        fr: 'Ag soluble urinaire légionnelle',
        en: 'Legible urinary soluble antigen',
      },
      {
        fr: 'Ag soluble urinaire pneumocoque',
        en: 'Pneumococcal urinary soluble antigen',
      },
      {
        fr: 'Anatomopathologie sur les urines',
        en: 'Anatomopathology on the urine',
      },
      { fr: 'Bu bandelette urinaire', en: 'Urine strips' },
      { fr: 'Calcium urinaire', en: 'Urinary calcium' },
      {
        fr: 'Chlamydia par pcr urinaire',
        en: 'Chlamydia by urinary pcr',
      },
      { fr: 'Citraturie', en: 'Urinary citrate' },
      {
        fr: 'Créatinine et urée urinaires sur 24 heures',
        en: 'Creatine and urinary urea over 24 hours',
      },
      {
        fr: 'Créatinine et urée urinaires sur échantillon',
        en: 'Urine creatinine and urea sample',
      },
      { fr: 'Cristallurie', en: 'Crystalluria' },
      { fr: 'Cuivre urinaire', en: 'Urinary copper' },
      {
        fr: 'Ecbu examen cytobactériologique des urines',
        en: 'Cytobacteriological testing of urine',
      },
      {
        fr: 'Eléctrophorèse des protéines urinaires',
        en: 'Electrophoresis of urinary proteins',
      },
      { fr: 'Glycosurie', en: 'Glycosuria' },
      {
        fr: 'Hydroxyproline totale et libre urinaires',
        en: 'Total hydroxyproline and free urinary',
      },
      { fr: 'Ionogramme urinaire (k+, na+)', en: 'Uriniary ionogram' },
      {
        fr: 'Ionogramme urinaire (k+, na+)',
        en: 'Urinary ionogram (k +, na +)',
      },
      { fr: 'Magnésurie', en: 'Magnésurie' },
      { fr: 'Microalbuminurie', en: 'Microalbuminuria' },
      { fr: 'Oxalurie', en: 'Oxaluria' },
      {
        fr: 'Oxalurie + citraturie + magnésurie',
        en: 'Oxaluria + citraturie + magnesurie',
      },
      { fr: 'Ph urinaire', en: 'Urinary ph' },
      {
        fr: 'Phosphore minéral urinaire',
        en: 'Urine mineral phosphorus',
      },
      {
        fr: 'Pigments et sels biliaires urinaire',
        en: 'Pigments and urinary bile salts',
      },
      { fr: 'Porphyrines urinaires', en: 'Urinary porphyrins' },
      { fr: 'Potassium urinaire', en: 'Urinary potassium' },
      { fr: 'Protéinurie', en: 'Proteinuria' },
      { fr: 'Protéinurie de bence jones', en: 'Bence jones protein' },
      { fr: 'Recherche de sang urinaire', en: 'Urinary blood test' },
      { fr: 'Sodium urinaire', en: 'Urinary sodium' },
      {
        fr: "Typage de la nature (sélectivité) d'une protéinurie",
        en: 'Typing of the nature (selectivity) of a proteinuria',
      },
      { fr: 'Urobiline', en: 'Urobilin' },
    ],
    en: 'URINE TEST',
    fr: 'BILAN URINAIRE',
  },
  [BiologyExamCategories.VIROLOGY]: {
    denominations: [
      {
        fr: 'Détection du génome viral de papillomavirus humains',
        en: 'Human papillomavirus viral load',
      },
      {
        fr: 'Détection du génome viral vhb',
        en: 'Hepatitis b viral load',
      },
      {
        fr: 'Détection qualitative et quantitatie de l’arn viral de vhc',
        en: 'Hepatitis c viral rna',
      },
      {
        fr: 'Détection qualitative et quantitatie de l’arn viral de vhd',
        en: 'Hepatitis d viral rna',
      },
      {
        fr: "Détection qualitative et quantitative de l'arn vih-1 dans le plasma séminal",
        en: 'Hiv viral rna seminal fluid',
      },
      { fr: 'Hépatite b adn viral - charge virale', en: 'Hbv dna' },
      {
        fr: 'Hépatite c arn viral (détection et quantification)',
        en: 'Hbc rna',
      },
      { fr: 'Mesure de la charge virale vih-1', en: 'Hiv viral load' },
      { fr: 'Mni test', en: 'Mononucleosis spot test' },
      { fr: 'Pcr grippe h1n1', en: 'H1n1 influenzae pcr' },
      { fr: 'Recherche astrovirus', en: 'Astrovirus antibody' },
      { fr: 'Recherche calicivirus', en: 'Calicivirus antibody' },
      {
        fr: 'Sérologie à vrs',
        en: 'Respiratory syncytial virus antibody',
      },
      { fr: 'Sérologie adénovirus', en: 'Adenovirus antibody' },
      { fr: 'Sérologie arboviroses', en: 'Arbovirus antibodies' },
      { fr: 'Sérologie arénavirus', en: 'Arenavirus antibody' },
      { fr: 'Sérologie cmv', en: 'Cmv antibody' },
      { fr: 'Sérologie dengue', en: 'Dengue antibody' },
      { fr: 'Sérologie ebv', en: 'Ebv antibody' },
      { fr: 'Sérologie entérovirus', en: 'Enterovirus antibody' },
      { fr: 'Sérologie grippe a', en: 'Influenza a antibody' },
      { fr: 'Sérologie grippe b', en: 'Influenza b antibody' },
      { fr: 'Sérologie hépatite a', en: 'Hepatitis a antibody' },
      {
        fr: 'Sérologie hépatite b aghbe, ac hbe',
        en: 'Hepatitis b antibody: hbe ag, hbe antibody',
      },
      {
        fr: 'Sérologie hépatite b: ag hbs, ac anti hbs et ac anti hbc',
        en: 'Hepatitis b antibody: hbs ag, anti hbs antibody, anti hbc antibody',
      },
      { fr: 'Sérologie hépatite c', en: 'Hepatitis c antibody' },
      { fr: 'Sérologie hépatite delta', en: 'Hepatitis d antibody' },
      { fr: 'Sérologie hépatite e', en: 'Hepatitis e antibody' },
      {
        fr: 'Sérologie herpès 6 (vhh 6)',
        en: 'Herpes virus 6 antibody',
      },
      {
        fr: 'Sérologie herpès simplex (vhs 1 et 2)',
        en: 'Herpes simplex virus 1 and 2 antibody',
      },
      { fr: 'Sérologie htlv i/ii', en: 'Htlv 1 and 2 antibody' },
      {
        fr: 'Sérologie oreillon (virus ourlien)',
        en: 'Mumps antibody',
      },
      { fr: 'Sérologie parainfluenza', en: 'Parainfluenza antibody' },
      { fr: 'Sérologie parvovirus b19', en: 'Parvovirus b19 antibody' },
      { fr: 'Sérologie rage', en: 'Rage antibody' },
      { fr: 'Sérologie rougeole', en: 'Measles antibody' },
      { fr: 'Sérologie rubéole', en: 'Rubella antibody' },
      {
        fr: 'Sérologie varicelle zona vzv',
        en: 'Varicella zoster virus',
      },
      { fr: 'Sérologie vih 1 et 2', en: 'Hiv 1 and 2 antibody' },
    ],
    en: 'VIROLOGY',
    fr: 'VIROLOGIE',
  },
  [BiologyExamCategories.VITAMINS_AND_MINERALS]: {
    denominations: [
      {
        fr: 'Capacité totale de saturation en fer de la transferrine (ctst)',
        en: 'Total iron saturation capacity of transferrin (tsct)',
      },
      {
        fr: 'Coefficient de saturation de la transferrine',
        en: 'Saturation factor of transferrin',
      },
      {
        fr: 'Dérivés dihydroxylés de la vitamine d',
        en: 'Dihydroxy derivatives of vitamin d',
      },
      {
        fr: 'Dosage de la 25-(oh)-vitamine d (d2 + d3)',
        en: 'Determination of 25- (oh) -vitamin d (d2 + d3)',
      },
      { fr: 'Fer sérique', en: 'Iron plasma' },
      { fr: 'Ferritine', en: 'Ferritin' },
      { fr: 'Ferritine érythrocytaire', en: 'Erythrocyte ferritin' },
      { fr: 'Folates sériques ou érythrocytaires b9', en: 'Folate' },
      {
        fr: 'Récepteur soluble de la transferrine (rstf)',
        en: 'Soluble transferrin receptor',
      },
      {
        fr: 'Transferrine désialylée ou déglycosylée ou transferrine carboxy déficiente (cdt)',
        en: 'Desialylated or deglycosylated transferrin or deficient carboxy transferrin',
      },
      { fr: 'Transferrine ou sidérophylline', en: 'Transferrin' },
      { fr: 'Vitamine a', en: 'Vitamin a' },
      { fr: 'Vitamine b1', en: 'Vitamin b1' },
      { fr: 'Vitamine b6', en: 'Vitamin b6' },
      { fr: 'Vitamine c', en: 'Vitamin c' },
      { fr: 'Vitamine e', en: 'Vitamin e' },
      { fr: 'Vitamines b 12', en: 'Vitamin b12' },
    ],
    en: 'VITAMINS AND MINERALS',
    fr: 'VITAMINES et BILAN MARTIAL',
  },
};

export const translateBiologogyCategory = (category: BiologyExamCategories) => {
  const exams = biologyExams[category];

  if (exams) {
    return exams.fr;
  }

  return category;
};

export const translateBiologogyDecomination = (
  category: BiologyExamCategories,
  denomination: string,
) => {
  const exams = biologyExams[category];

  if (exams) {
    const exam = exams.denominations.find((d) => d.en === denomination);

    if (exam) {
      return exam.fr;
    }
  }

  return denomination;
};

export const translateImageryCategory = (category: ImageryExamCategories) => {
  const exams = imageryExams[category];

  if (exams) {
    return exams.fr;
  }

  return category;
};

export const translateImageryDecomination = (
  category: ImageryExamCategories,
  denomination: string,
) => {
  const exams = imageryExams[category];

  if (exams) {
    const exam = exams.denominations.find((d) => d.en === denomination);

    if (exam) {
      return exam.fr;
    }
  }

  return denomination;
};
