import { Svg, Line, Path } from '@react-pdf/renderer';

const PinIcon = ({ size, color }: { size: number; color: string }) => (
  <Svg height={size} width={size} viewBox="0 0 24 24">
    <Line
      x1="12"
      y1="17"
      x2="12"
      y2="22"
      stroke={color}
      strokeWidth="2"
      strokeLineCap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5 17h14v-1.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V6h1a2 2 0 0 0 0-4H8a2 2 0 0 0 0 4h1v4.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24Z"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLineCap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PinIcon;
