import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export const practitionerContactApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPractitionerContacts: builder.query<
      Unpatient.PractitionerContact[],
      void
    >({
      query: () => `practitioner-contacts`,
      providesTags: ['PractitionerContacts'],
    }),
    getPractitionerContact: builder.query<
      Unpatient.PractitionerContact,
      string
    >({
      query: (practitionerContactId) =>
        `practitioner-contacts/${practitionerContactId}`,
      providesTags: ['PractitionerContacts'],
    }),
    searchPractitionerContacts: builder.query<
      Unpatient.PractitionerContact[],
      string
    >({
      query: (s) => `/practitioner-contacts/search?s=${s}`,
      providesTags: ['PractitionerContacts'],
    }),
    createPractitionerContact: builder.mutation<
      Unpatient.PractitionerContact,
      Partial<Unpatient.PractitionerContact>
    >({
      query: (body) => {
        const formData = new FormData();
        return {
          url: `practitioner-contacts`,
          method: 'POST',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['PractitionerContacts'],
    }),
    patchPractitionerContact: builder.mutation<
      Unpatient.PractitionerContact,
      Partial<Unpatient.PractitionerContact>
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();

        return {
          url: `/practitioner-contacts/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['PractitionerContacts'],
    }),
    deletePractitionerContact: builder.mutation<{}, string>({
      query: (templateMessageId) => ({
        url: `practitioner-contacts/${templateMessageId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['PractitionerContacts'],
    }),
  }),
});

export const {
  useGetPractitionerContactsQuery,
  useGetPractitionerContactQuery,
  useCreatePractitionerContactMutation,
  usePatchPractitionerContactMutation,
  useDeletePractitionerContactMutation,
} = practitionerContactApi;
