import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import cx from 'classnames';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useCreateTemplateMessageMutation } from '@@/services/templateMessage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TemplateMessageForm, {
  schema,
} from '@@/components/Forms/TemplateMessageForm';

type FormType = {
  title: string;
  body: string;
};

const NewTemplateMessagePage: React.FC = () => {
  const navigate = useNavigate();
  const [createTemplateMessage, { isSuccess }] =
    useCreateTemplateMessageMutation();

  const form = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    async (data: FormType) => {
      createTemplateMessage(data);
    },
    [createTemplateMessage],
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Votre message a bien été programmé`, {
        position: 'top-right',
      });

      navigate('/template-messages');
    }
  }, [isSuccess]);

  return (
    <div className="mx-4 w-full">
      <Link
        className="p-2 my-2 flex flex-row justify-start"
        to="/template-messages"
      >
        <IoIosArrowRoundBack size={20} />
        <span className="ml-1">Retour</span>
      </Link>

      <TemplateMessageForm form={form} />

      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className={cx(
          'my-2 rounded-lg p-2 bg-cyan-400 text-white',
          form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className="ml-1">Valider</span>
      </button>
    </div>
  );
};

export default NewTemplateMessagePage;
