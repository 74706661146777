import React, { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { mainColor, smallFontSize, bigFontSize } from '@@/constants/pdf';
import CalendarIcon from '@@/components/PDF/CalendarIcon';
import PinIcon from '@@/components/PDF/PinIcon';
import TargetIcon from '@@/components/PDF/TargetIcon';
import Header from '@@/components/PDF/Header';

const styles = StyleSheet.create({
  page: {},
  title: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    margin: '8px 16px',
    textAlign: 'center',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: bigFontSize,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    margin: '16px',
    color: mainColor,
  },
  month: {
    width: '130px',
    paddingRight: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRight: `2px solid ${mainColor}`,
    marginTop: '2px',
  },
  monthText: {
    marginLeft: '2px',
    fontFamily: 'Helvetica-Bold',
    fontSize: smallFontSize,
  },
  recommendations: {
    width: '500px',
    paddingLeft: '25px',
  },
  recommendation: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '14px',
    backgroundColor: '#e0f2fe',
    padding: '15px 30px 15px 10px',
    borderRadius: '15px',
  },
  description: {
    overflowWrap: 'break-word',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  descriptionText: {
    marginLeft: '4px',
    fontFamily: 'Helvetica-Bold',
    fontSize: smallFontSize,
  },
  objective: {
    marginTop: '10px',
    overflowWrap: 'break-word',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  objectiveText: {
    marginLeft: '4px',
    fontSize: smallFontSize,
    fontFamily: 'Helvetica',
  },
});

const Plan: React.FC<{
  plan?: Unpatient.Plan;
  patient?: Unpatient.User;
  practitioner?: Unpatient.User;
}> = ({ plan, patient, practitioner }) => {
  const maxRecos = useMemo(() => {
    if (!plan) {
      return 0;
    }

    return plan.program.reduce((prev: number, cur) => {
      return Math.max(prev, cur.recommendations.length);
    }, 0);
  }, [plan]);

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={maxRecos < 5}>
        <Header practitioner={practitioner} />

        <Text style={styles.title}>
          Plan de soins destiné à&nbsp;
          <Text style={styles.bold}>
            {patient?.firstName} {patient?.lastName}
          </Text>
          , émis le {moment(plan?.createdAt).format('DD/MM/YYYY')}
        </Text>

        {plan?.program?.map((program: Unpatient.MonthProgram, i: number) => {
          const month = moment(program.month, 'MM/YYYY').format('MMM YYYY');

          return (
            <View key={i} style={styles.section}>
              <View style={styles.month}>
                <CalendarIcon color={mainColor} size={24} />
                <Text style={styles.monthText}>{month}</Text>
              </View>

              <View style={styles.recommendations}>
                {program.recommendations.map((reco, k) => (
                  <View key={k} style={styles.recommendation} wrap={false}>
                    <View style={styles.description}>
                      <PinIcon color={mainColor} size={20} />
                      <Text style={styles.descriptionText}>
                        {reco.description}
                      </Text>
                    </View>
                    <View style={styles.objective}>
                      <TargetIcon color={mainColor} size={20} />
                      <Text style={styles.objectiveText}>{reco.objective}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default Plan;
