import React from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { CiLink } from 'react-icons/ci';
import { selectInputSpecialities } from '@@/constants/practitioner';
import { selectInputGenders } from '@@/constants/medicalRecord';
import { selectInputTitles } from '@@/constants/user';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectInput from '@@/components/Inputs/SelectInput';
import PhonePickerInput from '@@/components/Inputs/PhonePickerInput';
import CheckboxInput from '@@/components/Inputs/CheckboxInput';
import { isValidUrl } from '@@/utils/url';

export const schema = yup
  .object({
    name: yup.string().required('Veuillez renseigner le nom du praticien'),
    speciality: yup
      .string()
      .required('Veuillez renseigner la spécialité du praticien'),
    email: yup.string().email(),
    phone: yup.string(),
    gender: yup.string(),
    title: yup.string(),
    address: yup.string(),
    city: yup.string(),
    postcode: yup.string(),
    country: yup.string(),
    fax: yup.string(),
    url: yup.string(),
    rpps: yup.string(),
    appointmentUrl: yup.string(),
    newPatients: yup.bool(),
    notes: yup.string(),
  })
  .required();

const PractitionerContactForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const appointmentURL = watch('appointmentURL');

  return (
    <div className="mt-12">
      <div className="grid grid-cols-3 gap-10 my-8">
        <StandardInput
          register={register}
          id="name"
          label="Nom du praticien (*)"
          type="text"
          placeholder="Nom du praticien (*)"
          error={errors.name}
        />
        <div className="-mt-6">
          <SelectInput
            register={register}
            id="speciality"
            label="Spécialité (*)"
            options={selectInputSpecialities}
            placeholder="Choisissez une spécialité (*)"
            error={errors.speciality}
          />
        </div>
        <div className="-mt-6">
          <SelectInput
            register={register}
            id="gender"
            label="Genre du praticien référé"
            options={selectInputGenders}
            placeholder="Choisissez un genre"
            error={errors.gender}
          />
        </div>
        <div className="-mt-6">
          <SelectInput
            register={register}
            id="title"
            label="Titre du praticien"
            options={selectInputTitles}
            placeholder="Choisissez un titre"
            error={errors.title}
          />
        </div>
        <StandardInput
          register={register}
          id="rpps"
          label="RPPS"
          type="text"
          placeholder="RPPS"
          error={errors.rpps}
        />
        <StandardInput
          register={register}
          id="email"
          label="Email"
          type="text"
          placeholder="Email"
          error={errors.email}
        />
        <PhonePickerInput
          control={control}
          id="phone"
          error={errors.phone}
          label="Téléphone"
        />
        <StandardInput
          register={register}
          id="address"
          label="Adresse"
          type="text"
          placeholder="Adresse"
          error={errors.address}
        />
        <StandardInput
          register={register}
          id="city"
          label="Ville"
          type="text"
          placeholder="Ville"
          error={errors.city}
        />
        <StandardInput
          register={register}
          id="postcode"
          label="Code postal"
          type="text"
          placeholder="Code postal"
          error={errors.postcode}
        />
        <StandardInput
          register={register}
          id="country"
          label="Pays"
          type="text"
          placeholder="Pays"
          error={errors.country}
        />
        <StandardInput
          register={register}
          id="fax"
          label="Fax"
          type="text"
          placeholder="Fax"
          error={errors.fax}
        />
        <StandardInput
          register={register}
          id="url"
          label="URL"
          type="text"
          placeholder="URL"
          error={errors.url}
        />
        <StandardInput
          register={register}
          id="appointmentURL"
          label="URL (doctolib)"
          type="text"
          placeholder="URL (doctolib)"
          error={errors.appointmentURL}
        />

        {!!appointmentURL && isValidUrl(appointmentURL) && (
          <Link
            className="flex flex-row items-center font-main text-main"
            to={appointmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiLink size={20} />
            <span className="ml-1 text-sm underline">Lien vers Doctolib</span>
          </Link>
        )}
      </div>
      <StandardInput
        register={register}
        id="notes"
        label="Notes"
        type="textarea"
        placeholder="Notes"
        error={errors.notes}
      />
      <div className="my-8">
        <CheckboxInput
          register={register}
          id="newPatients"
          label="Accepte de nouveaux patients"
        />
      </div>
    </div>
  );
};

export default PractitionerContactForm;
