import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGetUserHistoriesQuery } from '@@/services/history';
import SelectInput from '@@/components/Inputs/SelectInput';

export const schema = yup
  .object({
    revision: yup.string().required('Veuillez entrer une révision'),
  })
  .required();

const HistoryForm: React.FC<{ patientId: string; callback: Function }> = ({
  patientId,
  callback,
}) => {
  const { data: histories } = useGetUserHistoriesQuery(patientId || '', {
    skip: !patientId,
  });

  const { register } = useForm<{ revision: string }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const selectValues = useMemo(() => {
    if (!histories?.length) {
      return [];
    }

    return [
      ...histories.map((history) => {
        const requester = history.requester
          ? `${history.requester?.firstName} ${history.requester?.lastName}`
          : 'GPT-4';
        return {
          id: history.id,
          label: `${moment(history.createdAt).format('lll')} - (${requester})`,
        };
      }),
      { id: 'init', label: 'Révision initiale' },
    ];
  }, [histories]);

  const cb = useCallback(
    (revision: string) => {
      if (revision && callback && histories?.length) {
        if (revision === 'init') {
          callback(histories[histories.length - 1].originalData, revision);
        } else {
          callback(
            histories?.find((h) => h.id === revision)?.updatedData,
            revision,
          );
        }
      }
    },
    [callback, histories],
  );

  if (!histories?.length) {
    return null;
  }

  return (
    <div className="mb-8 w-96">
      <SelectInput
        register={register}
        id="revision"
        label="Révision"
        options={selectValues}
        callback={cb}
      />

      <Link
        to={`/patients/${patientId}/audit`}
        className="block font-main text-main underline italic text-sm mt-4"
      >
        Audit
      </Link>
    </div>
  );
};

export default HistoryForm;
