import { api } from '@@/services/api';
import { Statuses } from '@@/constants/whatsapp';

export const whatsappApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChatsStatus: builder.query<{ status: Statuses; qr: string }, void>({
      query: () => `whatsapp/status`,
      providesTags: ['Chats'],
    }),
    getChats: builder.query<Unpatient.Chat[], null>({
      query: () => `whatsapp/chats`,
      providesTags: ['Chats'],
    }),
    getChatMessages: builder.query<Unpatient.ChatMessage[], string>({
      query: (id) => `whatsapp/chats/${id}`,
      providesTags: ['ChatMessages'],
    }),
    getChatMessage: builder.query<
      Unpatient.ChatMessage,
      { chatId: string; messageId: string }
    >({
      query: ({ chatId, messageId }) => `whatsapp/chats/${chatId}/${messageId}`,
    }),
    getChatContact: builder.query<Unpatient.ChatContact, string>({
      query: (id) => `whatsapp/contacts/${id}`,
      providesTags: ['ChatContact'],
    }),
    getMessageMedia: builder.query<
      { data: string; mimetype: string; filename: string },
      { chatId: string; messageId: string }
    >({
      query: ({ chatId, messageId }) => ({
        url: `whatsapp/chats/${chatId}/${messageId}`,
        responseHandler: async (response) => {
          const { _media: media } = await response.json();
          return media;
        },
      }),
    }),
    sendMessage: builder.mutation<
      void,
      { chatId: string; message: string; files?: FileList }
    >({
      query: ({ chatId, files, message }) => {
        const formData = new FormData();
        formData.append('message', message);
        if (files) {
          for (const file of Array.from(files)) {
            formData.append('file', file);
          }
        }
        return {
          url: `whatsapp/chats/${chatId}`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Chats'],
    }),
    deleteMessage: builder.mutation<
      void,
      { chatId: string; messageId: string }
    >({
      query: ({ chatId, messageId }) => {
        return {
          url: `whatsapp/chats/${chatId}/${messageId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(api.util.invalidateTags(['ChatMessages', 'Chats']));
        }, 1000);
      },
    }),
    disconnect: builder.mutation<{}, void>({
      query: () => ({
        url: 'whatsapp/disconnect',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Chats', 'ChatMessages', 'ChatContact'],
    }),
    getActivityMetrics: builder.query<Unpatient.ActivityMetrics, void>({
      query: () => `whatsapp/metrics`,
    }),
  }),
});

export const {
  useGetChatsQuery,
  useGetChatsStatusQuery,
  useGetChatMessagesQuery,
  useGetChatMessageQuery,
  useSendMessageMutation,
  useDisconnectMutation,
  useGetChatContactQuery,
  useGetMessageMediaQuery,
  useDeleteMessageMutation,
  useGetActivityMetricsQuery,
} = whatsappApi;
