export const formatContactAddress = (
  contact: Unpatient.PractitionerContact,
) => {
  const parts = [
    contact.address,
    contact.postcode,
    contact.city,
    contact.country,
  ].filter(Boolean);

  return parts.join(', ');
};
