import { api } from '@@/services/api';

export const invoiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvoice: builder.query<Unpatient.Invoice, string>({
      query: (invoiceId) => `invoices/${invoiceId}`,
      providesTags: ['Invoices'],
    }),
    getUserInvoices: builder.query<Unpatient.Invoice[], string>({
      query: (userId) => `users/${userId}/invoices`,
      providesTags: ['Invoices'],
    }),
  }),
});

export const { useGetInvoiceQuery, useGetUserInvoicesQuery } = invoiceApi;
