import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetPractitionerContactsQuery } from '@@/services/practitionerContact';
import { translateSpeciality } from '@@/constants/practitioner';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPractitionerContactInput: React.FC<{
  id: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
  cb?: (arg: any) => void;
}> = ({ id, label, placeholder, error, register, cb }) => {
  const { data: practitionerContacts } = useGetPractitionerContactsQuery();

  const selectPractitionerContactsInput = useMemo(() => {
    if (practitionerContacts) {
      return [...practitionerContacts]
        .sort((c1, c2) => {
          if (c1.name < c2.name) {
            return -1;
          }

          if (c1.name > c2.name) {
            return 1;
          }

          return 0;
        })
        .map((contact) => ({
          id: contact.id,
          label: contact.speciality
            ? `${contact.name} - ${translateSpeciality(contact.speciality)}`
            : contact.name,
        }));
    }

    return [];
  }, [practitionerContacts]);

  if (!practitionerContacts) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectPractitionerContactsInput}
      placeholder={placeholder}
      error={error}
      callback={(value: string) => {
        if (typeof cb === 'function') {
          const practitioner = practitionerContacts?.find(
            ({ id }) => id === value,
          );

          if (practitioner) {
            cb(practitioner);
          }
        }
      }}
    />
  );
};

export default SelectPractitionerContactInput;
