import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export const schema = yup.object({
  temperature: yup.number().required(),
  hint: yup.string(),
});

const initialValue = 50;

const SuggestionForm: React.FC<{ form: any }> = ({ form }) => {
  const {
    control,
    register,
    formState: { errors },
  } = form;
  const [sliderValue, setSliderValue] = useState(initialValue);

  return (
    <form>
      <Controller
        name="temperature"
        control={control}
        defaultValue={initialValue}
        render={({ field }) => (
          <Slider
            {...field}
            value={typeof field.value === 'number' ? field.value : 0}
            onChange={(value) => {
              field.onChange(value);
              if (typeof value === 'number') {
                setSliderValue(value);
              }
            }}
            min={0}
            max={100}
          />
        )}
      />
      <div className="font-main text-cyan-500 text-end mb-4">
        Température: {sliderValue / 100}
      </div>

      <StandardInput
        register={register}
        id="hint"
        label="Message initial"
        type="textarea"
        placeholder="Message initial (facultatif)"
        error={errors?.hint}
      />
    </form>
  );
};

export default SuggestionForm;
