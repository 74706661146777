import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export const templateMessageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateMessages: builder.query<Unpatient.TemplateMessage[], void>({
      query: () => `template-messages`,
      providesTags: ['TemplateMessages'],
    }),
    getTemplateMessage: builder.query<Unpatient.TemplateMessage, string>({
      query: (templateMessageId) => `template-messages/${templateMessageId}`,
      providesTags: ['TemplateMessages'],
    }),
    createTemplateMessage: builder.mutation<
      Unpatient.TemplateMessage,
      { title: string; body: string }
    >({
      query: (body) => {
        return {
          url: `template-messages`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['TemplateMessages'],
    }),
    patchTemplateMessage: builder.mutation<
      Unpatient.TemplateMessage,
      Partial<Unpatient.TemplateMessage>
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();

        return {
          url: `/template-messages/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['TemplateMessages'],
    }),
    deleteTemplateMessage: builder.mutation<{}, string>({
      query: (templateMessageId) => ({
        url: `template-messages/${templateMessageId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['TemplateMessages'],
    }),
  }),
});

export const {
  useGetTemplateMessagesQuery,
  useGetTemplateMessageQuery,
  useCreateTemplateMessageMutation,
  usePatchTemplateMessageMutation,
  useDeleteTemplateMessageMutation,
} = templateMessageApi;
