import DOMPurify from 'dompurify';
export const formatWhatsAppText = (message: string): string => {
  return DOMPurify.sanitize(
    message
      .replace(/\*([^\*]+)\*/g, '<b>$1</b>')
      .replace(/_([^_]+)_/g, '<i>$1</i>')
      .replace(/~([^~]+)~/g, '<strike>$1</strike>')
      .replace(/```([^`]+)```/g, '<pre>$1</pre>')
      .replace(/\n/g, '<br />'),
  );
};
