import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import cx from 'classnames';
import omit from 'lodash.omit';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {
  useCreatePractitionerContactMutation,
  usePatchPractitionerContactMutation,
  useGetPractitionerContactQuery,
} from '@@/services/practitionerContact';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PractitionerContactForm, {
  schema,
} from '@@/components/Forms/PractitionerContactForm';

const NEW_PRACTITIONER_CONTACT = 'new';

const PractitionerContactPage: React.FC = () => {
  const { practitionerContactId } = useParams();
  const navigate = useNavigate();
  const [createPractitionerContact, { isSuccess }] =
    useCreatePractitionerContactMutation();

  const [patchPractitionerContact, { isSuccess: isPatchSuccess }] =
    usePatchPractitionerContactMutation();

  const isNewPractitionerContact = useMemo(
    () => practitionerContactId === NEW_PRACTITIONER_CONTACT,
    [practitionerContactId],
  );

  const { data: practitionerContact } = useGetPractitionerContactQuery(
    practitionerContactId || '',
    {
      skip: !practitionerContactId || isNewPractitionerContact,
    },
  );

  const form = useForm<Unpatient.PractitionerContact>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    async (data: Unpatient.PractitionerContact) => {
      if (practitionerContact?.id) {
        patchPractitionerContact({
          ...data,
          id: practitionerContact.id,
        });
      } else {
        createPractitionerContact(data);
      }
    },
    [
      createPractitionerContact,
      patchPractitionerContact,
      practitionerContact?.id,
    ],
  );

  useEffect(() => {
    if (practitionerContact?.id) {
      const data = omit(practitionerContact, [
        '_id',
        'id',
        'createdAt',
        'updatedAt',
      ]);

      form.reset(data);
    }
  }, [practitionerContact?.id, form.reset]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Votre contact a bien été ajouté`, {
        position: 'top-right',
      });

      navigate('/practitioner-contacts');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`Le contact a bien été modifié`, {
        position: 'top-right',
      });

      navigate('/practitioner-contacts');
    }
  }, [isPatchSuccess]);

  return (
    <div className="mx-4 w-full">
      <Link className="p-2 my-2 flex flex-row" to="/practitioner-contacts">
        <IoIosArrowRoundBack size={20} />
        <span className="ml-1">Retour</span>
      </Link>

      <PractitionerContactForm form={form} />

      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className={cx(
          'mb-8 rounded-lg p-2 bg-cyan-400 text-white',
          form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className="ml-1">Valider</span>
      </button>
    </div>
  );
};

export default PractitionerContactPage;
