import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import StandardInput from '@@/components/Inputs/StandardInput';

const FreeItem: React.FC<{
  form: any;
  index: number;
  removeItem: (arg: number) => void;
}> = ({ form, index, removeItem }) => {
  const { register } = form;

  return (
    <div className="w-[500px]">
      <div className="flex flex-row justify-between mb-10 text-white font-main bg-cyan-400 p-2 rounded-lg">
        <p className="text-lg font-semibold">Elément libre #{index + 1}</p>
        <button type="button" onClick={() => removeItem(index)}>
          <RiDeleteBinLine size={24} />
        </button>
      </div>
      <div className="flex flex-col space-y-10">
        <StandardInput
          register={register}
          id={`freeItems[${index}].text`}
          label="Texte"
          type="textarea"
          placeholder="Intitulé"
        />
        <StandardInput
          register={register}
          id={`freeItems[${index}].comment`}
          label="Commentaire"
          type="textarea"
          placeholder="Commentaire"
        />
      </div>
    </div>
  );
};

export const FreePrescriptionForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const { control } = form;

  const {
    fields: freeItems,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'freeItems',
  });

  return (
    <div className="flex flex-col mt-10 w-fit">
      <ul className="flex flex-col space-y-12">
        {freeItems.map((field, index) => (
          <FreeItem
            key={field.id}
            form={form}
            index={index}
            removeItem={removeItem}
          />
        ))}
      </ul>

      <button
        type="button"
        className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base flex flex-row items-center w-fit"
        onClick={() => appendItem({})}
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-2">Ajouter un élément</span>
      </button>
    </div>
  );
};
