import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreatePractitionerWaitingListEntryMutation } from '@@/services/practitionerWaitingList';
import PractitionerWishListForm, {
  schema,
} from '@@/components/Forms/PractitionerWishListForm';
import { SpecialitySlugs } from '@@/constants/practitioner';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  speciality: SpecialitySlugs;
};

const JoinUsPage: React.FC = () => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const [createPractitionerWaitingListEntry, { isSuccess }] =
    useCreatePractitionerWaitingListEntryMutation();

  const onSubmit = useCallback(
    (data: FormValues) => {
      createPractitionerWaitingListEntry(data);
    },
    [createPractitionerWaitingListEntry],
  );

  if (isSuccess) {
    return (
      <div className="bg-gradient-to-b from-black to-cyan-900 p-8 h-full">
        <p className="text-white text-3xl text-center mt-16">
          Merci pour votre soutien!
        </p>
        <p className="text-white text-lg text-center">
          Nous reviendrons vers vous incessamment sous peu
        </p>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-black to-cyan-900 h-fit p-8">
      <div className="w-full sm:w-[600px] mx-auto font-main text-white">
        <div className="flex justify-center mb-4">
          <img src="/favicon.png" className="w-16" />
        </div>

        <h1 className="text-3xl font-bold">
          Rejoins nous pour inventer&nbsp;
          <br />
          <span className="text-cyan-500">la médecine de demain</span>
        </h1>

        <div className="my-6 mx-auto w-fit bg-cyan-200 px-16 py-3 rounded-2xl">
          <Link className="text-black font-bold" to="/manifesto">
            Lire le manifesto
          </Link>
        </div>

        <div className="my-8">
          <p className="">
            Le système de santé est sur les rotules et nous les médecins aussi.
            L'avenir médical se joue aujourd'hui avec l'IA en renfort.&nbsp;
            <span className="font-bold text-cyan-500">
              Chers collègues à nous de jouer.
            </span>
          </p>

          <p className="mt-4 font-bold">
            Si vous souhaitez être recontacté pour participer à cette
            aventure,&nbsp;
            <span className="text-cyan-500">dites le nous ici :</span>
          </p>
        </div>

        <PractitionerWishListForm form={form} />

        <button
          type="button"
          className="block mt-6 mb-10 mx-auto py-2 px-16 w-fit bg-cyan-400 rounded-xl"
          onClick={form.handleSubmit(onSubmit)}
          disabled={form.formState.isSubmitting}
        >
          <span className="text-white">Envoyer</span>
        </button>
      </div>
    </div>
  );
};

export default JoinUsPage;
