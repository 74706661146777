import { api } from '@@/services/api';

export const suggestionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserSuggestions: builder.query<Unpatient.Suggestion[], string>({
      query: (userId) => `suggestions/${userId}`,
      providesTags: ['Suggestions'],
    }),
    createUserSuggestion: builder.mutation<
      Unpatient.Plan,
      { userId: string; temperature: number; hint?: string }
    >({
      query: ({ userId, temperature, hint }) => {
        return {
          url: `suggestions/${userId}`,
          method: 'POST',
          body: { temperature, hint },
        };
      },
      invalidatesTags: ['Suggestions'],
    }),
  }),
});

export const { useGetUserSuggestionsQuery, useCreateUserSuggestionMutation } =
  suggestionApi;
