import { Svg, Path } from '@react-pdf/renderer';

const TargetIcon = ({ size, color }: { size: number; color: string }) => (
  <Svg height={size} width={size} viewBox="0 0 24 24">
    <Path
      d="M12 13V2l8 4-8 4"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLineCap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M20.55 10.23A9 9 0 1 1 8 4.94"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLineCap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 10a5 5 0 1 0 8.9 2.02"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLineCap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default TargetIcon;
