import { api } from '@@/services/api';

export const historyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query<Unpatient.History, string>({
      query: (historyId) => `histories/${historyId}`,
      providesTags: ['Histories'],
    }),
    getUserHistories: builder.query<Unpatient.History[], string>({
      query: (userId) => `users/${userId}/histories`,
      providesTags: ['Histories'],
    }),
  }),
});

export const { useGetHistoryQuery, useGetUserHistoriesQuery } = historyApi;
