import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import cx from 'classnames';
import omit from 'lodash.omit';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {
  useCreateTaskMutation,
  usePatchTaskMutation,
  useGetTaskQuery,
} from '@@/services/task';
import { TaskStatuses } from '@@/constants/task';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TaskForm, { schema } from '@@/components/Forms/TaskForm';

const NEW_TASK = 'new';

type TaskFormType = Omit<Unpatient.Task, 'patientId'> & {
  patientId: { id: string; label: string };
};

const TaskPage: React.FC = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [createTask, { isSuccess }] = useCreateTaskMutation();

  const [patchTask, { isSuccess: isPatchSuccess }] = usePatchTaskMutation();

  const isNewTask = useMemo(() => taskId === NEW_TASK, [taskId]);

  const { data: task } = useGetTaskQuery(taskId || '', {
    skip: !taskId || isNewTask,
  });

  const form = useForm<TaskFormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      // @ts-ignore
      ownerId: '6675391eabebdc70901ad786', // Maelle Breton
      status: TaskStatuses.TO_DO,
    },
  });

  const onSubmit = useCallback(
    async (data: TaskFormType) => {
      if (task?.id) {
        patchTask({
          ...data,
          id: task.id,
          patientId: data.patientId?.id,
        });
      } else {
        createTask({
          ...data,
          patientId: data.patientId?.id,
        });
      }
    },
    [createTask, patchTask, task?.id],
  );

  useEffect(() => {
    if (task?.id) {
      const data = omit(task, [
        '_id',
        'id',
        'createdAt',
        'updatedAt',
        'creatorId',
        'ownerId',
        'patientId',
        'practitionerContactId',
        'eventId',
        'documentId',
      ]) as any;

      if (task?.practitionerContactId) {
        data.practitionerContactId = task.practitionerContactId.id;
      }

      if (task?.patientId) {
        data.patientId = {
          id: task.patientId.id,
          label: `${task.patientId.firstName} ${task.patientId.lastName}`,
        };
      }

      if (task?.ownerId) {
        data.ownerId = task.ownerId.id;
      }

      if (task?.eventId) {
        data.eventId = task.eventId.id;
      }

      if (task?.documentId) {
        data.documentId = task.documentId.id;
      }

      form.reset(data);
    }
  }, [task?.id, form.reset]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Votre tâche a bien été ajoutée`, {
        position: 'top-right',
      });

      navigate('/tasks');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`La tâche a bien été modifiée`, {
        position: 'top-right',
      });

      navigate('/tasks');
    }
  }, [isPatchSuccess]);

  return (
    <div className="mx-4 w-full">
      <Link className="p-2 my-2 flex flex-row justify-start" to="/tasks">
        <IoIosArrowRoundBack size={20} />
        <span className="ml-1">Retour</span>
      </Link>

      <TaskForm form={form} />

      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className={cx(
          'mb-8 rounded-lg p-2 bg-cyan-400 text-white',
          form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className="ml-1">Valider</span>
      </button>
    </div>
  );
};

export default TaskPage;
