import { api } from '@@/services/api';

export const timelineApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserTimeline: builder.query<Unpatient.TimelineEvent[], string>({
      query: (userId) => `timelines/${userId}`,
      providesTags: ['Timelines'],
    }),
  }),
});

export const { useGetUserTimelineQuery } = timelineApi;
