import { api } from '@@/services/api';

export const planApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlan: builder.query<Unpatient.Plan, string>({
      query: (planId) => `plans/${planId}`,
      providesTags: ['Plans'],
    }),
    getUserPlans: builder.query<Unpatient.Plan[], string>({
      query: (userId) => `users/${userId}/plans`,
      providesTags: ['Plans'],
    }),
    createUserPlan: builder.mutation<void, string>({
      query: (userId) => {
        return {
          url: `users/${userId}/plans`,
          method: 'POST',
          body: {},
        };
      },
      invalidatesTags: ['Plans'],
    }),
    deletePlan: builder.mutation<{}, string>({
      query: (planId) => ({
        url: `plans/${planId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Plans'],
    }),
    patchPlan: builder.mutation<Unpatient.Plan, Partial<Unpatient.Plan>>({
      query: ({ id, ...body }) => ({
        url: `/plans/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Plans'],
    }),
  }),
});

export const {
  useGetPlanQuery,
  useGetUserPlansQuery,
  useCreateUserPlanMutation,
  useDeletePlanMutation,
  usePatchPlanMutation,
} = planApi;
