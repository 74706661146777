import { api } from '@@/services/api';

export const faxApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFaxes: builder.query<Unpatient.Fax[], void>({
      query: () => `faxes`,
    }),
    viewFax: builder.query<string, string>({
      query: (faxId) => ({
        url: `faxes/${faxId}/view`,
        responseHandler: async (response) => {
          const contentType = response.headers.get('Content-Type');
          const blob = await response.blob();

          return URL.createObjectURL(
            contentType ? new Blob([blob], { type: contentType }) : blob,
          );
        },
      }),
    }),
  }),
});

export const { useGetFaxesQuery, useViewFaxQuery } = faxApi;
