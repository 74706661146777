import React, { useEffect } from 'react';
import { Column, FilterFn } from '@tanstack/react-table';

export function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();

  return (
    <DebouncedInput
      className="border shadow rounded text-center w-20 sm:w-24 mx-0.5 text-xs"
      onChange={(value) => column.setFilterValue(value)}
      placeholder="Recherche..."
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  );
}

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export const partialMatchFilter: FilterFn<any> = (
  row,
  columnId,
  filterValue,
) => {
  if (!filterValue) {
    return true;
  }

  const cellValue = String(row.original[columnId]);

  return !!cellValue?.toLowerCase()?.includes(filterValue?.toLowerCase());
};

export const nameMatchFilter: FilterFn<any> = (row, columnId, filterValue) => {
  if (!filterValue) {
    return true;
  }

  const cellValue = row.original[columnId]?.firstName
    ? String(row.original[columnId].firstName) +
      ' ' +
      String(row.original[columnId].lastName)
    : String(row.original[columnId]?.name);

  return !!cellValue?.toLowerCase()?.includes(filterValue?.toLowerCase());
};
