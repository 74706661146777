import React, { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getTitle, Genders } from '@@/constants/medicalRecord';
import { translateSpeciality } from '@@/constants/practitioner';
import {
  mainColor,
  smallFontSize,
  bigFontSize,
  biggerFontSize,
} from '@@/constants/pdf';
import { getAge } from '@@/utils/date';
import Header from '@@/components/PDF/Header';
import Footer from '@@/components/PDF/Footer';

const styles = StyleSheet.create({
  page: {},
  date: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    marginRight: '16px',
    textAlign: 'right',
  },
  body: {
    margin: '16px',
  },
  title: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    marginRight: '16px',
    textAlign: 'right',
    marginBottom: '8px',
  },
  text: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: smallFontSize,
    marginTop: '4px',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: bigFontSize,
    color: mainColor,
  },
  motive: {
    marginTop: '16px',
    marginBottom: '16px',
    fontFamily: 'Helvetica-Oblique',
    fontSize: biggerFontSize,
    color: mainColor,
    textAlign: 'center',
  },
  block: {
    marginTop: '16px',
  },
  end: {
    marginTop: '24px',
  },
});

const Referral: React.FC<{
  referral?: Omit<Unpatient.Referral, 'referredPractitioner'> & {
    referredPractitioner?: Unpatient.PractitionerContact;
  };
  patient?: Unpatient.User;
  practitioner?: Unpatient.User;
  signature?: string;
}> = ({ referral, patient, practitioner, signature }) => {
  const documents = useMemo(() => {
    if (!referral?.documents?.length) {
      return null;
    }

    return (
      <View style={styles.block}>
        <Text style={styles.text}>
          Les documents suivants sont joints à cette lettre :
        </Text>
        {referral?.documents.map((doc) => (
          <Text key={doc.id} style={styles.text}>
            - {doc.name || doc.label}
          </Text>
        ))}
      </View>
    );
  }, [referral?.documents]);
  const antecedents = useMemo(() => {
    if (referral?.ignoreAntecedents) {
      return null;
    }

    if (!referral?.antecedents) {
      return null;
    }

    const values = Object.values(referral?.antecedents).filter((v) => !!v);

    if (!values.length) {
      return null;
    }

    return (
      <View style={styles.block}>
        <Text style={styles.text}>
          Ce patient présente les antécédents suivants :
        </Text>
        <Text style={styles.text}>{values.join(' - ')}</Text>
      </View>
    );
  }, [referral?.antecedents, referral?.ignoreAntecedents]);

  if (!patient?.medicalRecord?.birthDate || !practitioner) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Header practitioner={practitioner} />
          <View style={{ margin: '16px' }}>
            {!patient?.medicalRecord?.birthDate && (
              <Text style={styles.text}>
                - Veuillez entrer la date de naissance du patient dans son
                dossier médical
              </Text>
            )}
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header practitioner={practitioner} />

        <Text style={styles.title}>
          A l'attention du&nbsp;
          <Text style={styles.bold}>
            {referral?.referredPractitioner?.title || 'Dr'}&nbsp;
            {referral?.referredPractitioner?.name || '<NAME>'}
          </Text>
          ,&nbsp;
          {!!referral?.referredPractitioner?.speciality &&
            translateSpeciality(referral?.referredPractitioner?.speciality)}
        </Text>
        <Text style={styles.date}>
          Le&nbsp;
          {moment(referral?.date).format('DD/MM/YYYY')},
        </Text>
        <View style={styles.body}>
          <Text style={styles.text}>
            {referral?.referredPractitioner?.gender === Genders.F
              ? 'Chère Consoeur'
              : 'Cher Confrère'}
            ,
          </Text>

          <Text style={styles.text}>
            Je vous adresse&nbsp;
            <Text style={styles.bold}>
              {getTitle(patient?.medicalRecord?.gender)}&nbsp;
              {patient?.firstName}
              &nbsp;
              {patient?.lastName}
            </Text>
            ,&nbsp;né
            {`${patient.medicalRecord.gender === Genders.F ? '(e)' : ''}`}
            &nbsp;le&nbsp;
            {moment(patient?.medicalRecord?.birthDate).format('DD/MM/YYYY')}
            ,&nbsp;
            {getAge(patient?.medicalRecord?.birthDate)} ans, pour la raison
            suivante:
          </Text>

          <Text style={styles.motive}>{referral?.reason || '<REASON>'}</Text>
          <Text style={styles.text}>
            {referral?.description || '<DESCRIPTION>'}
          </Text>

          {documents}

          {antecedents}

          <View style={styles.end}>
            <Text style={styles.text}>
              En vous remerciant pour&nbsp;
              {patient?.medicalRecord?.gender === Genders.F ? 'elle' : 'lui'},
            </Text>
            <Text style={styles.text}>Confraternellement,</Text>
          </View>
        </View>

        <Footer practitioner={practitioner} signature={signature} />
      </Page>
    </Document>
  );
};

export default Referral;
