import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export const scheduledMessageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getScheduledMessages: builder.query<Unpatient.ScheduledMessage[], void>({
      query: () => `scheduled-messages`,
      providesTags: ['ScheduledMessages'],
    }),
    getScheduledMessage: builder.query<Unpatient.ScheduledMessage, string>({
      query: (scheduledMessageId) => `scheduled-messages/${scheduledMessageId}`,
      providesTags: ['ScheduledMessages'],
    }),
    createScheduledMessage: builder.mutation<
      Unpatient.ScheduledMessage,
      { userId: string; body: string; scheduledAt: Date; eventId?: string }
    >({
      query: (body) => {
        return {
          url: `scheduled-messages`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ScheduledMessages'],
    }),
    createNextMorningScheduledMessage: builder.mutation<
      Unpatient.ScheduledMessage,
      { userId: string; body: string }
    >({
      query: (body) => {
        return {
          url: `scheduled-messages/next-morning`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ScheduledMessages'],
    }),
    patchScheduledMessage: builder.mutation<
      Unpatient.ScheduledMessage,
      {
        id: string;
        body?: string;
        userId?: string;
        scheduledAt?: Date;
        eventId?: string;
      }
    >({
      query: ({ id, ...data }) => {
        const formData = new FormData();

        return {
          url: `/scheduled-messages/${id}`,
          method: 'PATCH',
          body: convertToFormData(data, formData),
          formData: true,
        };
      },
      invalidatesTags: ['ScheduledMessages'],
    }),
    deleteScheduledMessage: builder.mutation<{}, string>({
      query: (scheduledMessageId) => ({
        url: `scheduled-messages/${scheduledMessageId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['ScheduledMessages'],
    }),
  }),
});

export const {
  useGetScheduledMessagesQuery,
  useGetScheduledMessageQuery,
  useCreateScheduledMessageMutation,
  usePatchScheduledMessageMutation,
  useDeleteScheduledMessageMutation,
  useCreateNextMorningScheduledMessageMutation,
} = scheduledMessageApi;
