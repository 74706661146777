import React, { useMemo } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { useFieldArray } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import StandardInput from '@@/components/Inputs/StandardInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';

export const schema = yup
  .object({
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    program: yup
      .array()
      .of(
        yup
          .object({
            month: yup
              .string()
              .matches(/^[0-9]{2}\/[0-9]{4}$/, 'Le format doit être MM/YYYY')
              .required(),
            recommendations: yup
              .array()
              .of(
                yup.object({
                  description: yup.string().required(),
                  objective: yup.string().required(),
                }),
              )
              .required(),
          })
          .required(),
      )
      .required(),
  })
  .required();

const Month: React.FC<{
  index: number;
  removeMonth: Function;
  register: any;
  control: any;
  watch: any;
  errors: any;
}> = ({ index, removeMonth, register, control, watch, errors }) => {
  const {
    fields: recommendations,
    append: appendRecommendation,
    remove: removeRecommendation,
  } = useFieldArray({
    control,
    name: `program.${index}.recommendations`,
  });

  const program = watch(`program.${index}`);

  const monthName = useMemo(() => {
    if (!program?.month) {
      return '';
    }
    return moment(program?.month, 'MM/YYY').format('MMM YY');
  }, [program?.month]);

  return (
    <div className="border border-cyan-400 py-10 px-5 border-dotted">
      <div className="flex flex-row items-center space-x-4">
        <StandardInput
          register={register}
          id={`program.${index}.month`}
          label={monthName}
          placeholder="Mois"
          type="text"
          error={errors?.program?.[index]?.month}
        />
        <button type="button" onClick={() => removeMonth(index)}>
          <RiDeleteBinLine size={24} />
        </button>
      </div>

      {recommendations.map((rfield, rindex) => (
        <div
          key={rindex}
          className="flex flex-row items-center space-x-4 mt-12"
        >
          <div className="flex flex-col items-center space-y-12 w-[500px]">
            <StandardInput
              register={register}
              id={`program.${index}.recommendations.${rindex}.description`}
              label={`${monthName} - description ${rindex + 1}`}
              error={
                errors?.program?.[index]?.recommendations?.[rindex]?.description
              }
              placeholder="Description"
              type="textarea"
            />
            <StandardInput
              register={register}
              id={`program.${index}.recommendations.${rindex}.objective`}
              label={`${monthName} - objectif ${rindex + 1}`}
              error={
                errors?.program?.[index]?.recommendations?.[rindex]?.objective
              }
              placeholder="Objectif"
              type="textarea"
            />
          </div>
          <button type="button" onClick={() => removeRecommendation(rindex)}>
            <RiDeleteBinLine size={24} />
          </button>
        </div>
      ))}

      <button
        type="button"
        className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-sm flex flex-row items-center w-fit"
        onClick={() => appendRecommendation({})}
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-2">Ajouter une recommendation</span>
      </button>
    </div>
  );
};

const PlanForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const {
    fields: months,
    append: appendMonth,
    remove: removeMonth,
  } = useFieldArray({
    control,
    name: 'program',
  });

  const program = watch('program');
  const nextMonth = useMemo(() => {
    if (program?.length) {
      const lastMonth = moment(program[program.length - 1].month, 'MM/YYYY');
      return lastMonth.add(1, 'months').format('MM/YYYY');
    }
  }, [program]);

  return (
    <div className="flex flex-col mt-6 pb-8 w-fit">
      <div className="flex flex-col space-y-10 my-8">
        <DatePickerInput
          id="startDate"
          control={control}
          label="Date de début"
        />
        <DatePickerInput id="endDate" control={control} label="Date de fin" />
      </div>

      <p className="mb-6 font-main text-lg text-main underline">Programme :</p>
      <ul className="flex flex-col space-y-12">
        {months.map((field, index) => (
          <Month
            key={index}
            index={index}
            removeMonth={removeMonth}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
          />
        ))}
      </ul>

      <button
        type="button"
        className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-sm flex flex-row items-center w-fit"
        onClick={() =>
          appendMonth({
            month: nextMonth,
          })
        }
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-2">Ajouter un mois</span>
      </button>
    </div>
  );
};

export default PlanForm;
