import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetStripeCouponsQuery } from '@@/services/stripe';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectCouponInput: React.FC<{
  id: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, label, placeholder, error, register }) => {
  const { data: coupons } = useGetStripeCouponsQuery();

  const selectCouponsInput = useMemo(() => {
    if (coupons) {
      return [...coupons]
        .map((coupon) => ({
          id: coupon.id,
          label: coupon.name,
        }))
        .sort((c1, c2) => {
          if (c1.label < c2.label) {
            return -1;
          }

          if (c1.label > c2.label) {
            return 1;
          }

          return 0;
        });
    }

    return [];
  }, [coupons]);

  if (!coupons) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectCouponsInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectCouponInput;
