import React, { useMemo } from 'react';
import * as yup from 'yup';
import {
  selectPrescriptionTypes,
  PrescriptionTypes,
} from '@@/constants/prescription';
import RadioInput from '@@/components/Inputs/RadioInput';
import StandardInput from '@@/components/Inputs/StandardInput';
import { BiologicalPrescriptionForm } from '@@/components/Forms/BiologicalPrescriptionForm';
import { ImageryPrescriptionForm } from '@@/components/Forms/ImageryPrescriptionForm';
import { FreePrescriptionForm } from '@@/components/Forms/FreePrescriptionForm';
import SelectPrescriptionTemplateInput from '@@/components/Inputs/SelectPrescriptionTemplateInput';

export const schema = yup
  .object({
    type: yup.string().required(),
    title: yup.string(),
    biologyItems: yup.array().when('type', {
      is: PrescriptionTypes.BIOLOGY,
      then: yup
        .array()
        .of(
          yup
            .object({
              category: yup.string().required(),
              denomination: yup.string().required(),
              comment: yup.string(),
            })
            .required(),
        )
        .required(),
    }),
    imageryItems: yup.array().when('type', {
      is: PrescriptionTypes.IMAGERY,
      then: yup
        .array()
        .of(
          yup
            .object({
              category: yup.string().required(),
              denomination: yup.string().required(),
              comment: yup.string(),
            })
            .required(),
        )
        .required(),
    }),
    freeItems: yup.array().when('type', {
      is: PrescriptionTypes.FREE,
      then: yup
        .array()
        .of(
          yup
            .object({
              text: yup.string().required(),
              comment: yup.string(),
            })
            .required(),
        )
        .required(),
    }),
  })
  .required();

const PrescriptionForm: React.FC<{
  form: any;
  isNewPrescription: boolean;
}> = ({ form, isNewPrescription }) => {
  const { control, watch, register } = form;
  const type = watch('type');

  const prescriptionForm = useMemo(() => {
    switch (type) {
      case PrescriptionTypes.BIOLOGY: {
        return <BiologicalPrescriptionForm form={form} />;
      }

      case PrescriptionTypes.IMAGERY: {
        return <ImageryPrescriptionForm form={form} />;
      }

      case PrescriptionTypes.FREE: {
        return <FreePrescriptionForm form={form} />;
      }

      default:
        return null;
    }
  }, [type, form]);

  const maybeFillWithPrescriptionTemplates = useMemo(() => {
    if (!isNewPrescription) {
      return null;
    }

    return (
      <div className="mt-8 w-[300px]">
        <SelectPrescriptionTemplateInput
          register={register}
          id="templateId"
          label="Utiliser un modèle ?"
          placeholder="Choisissez un modèle"
          type={type}
          cb={(template: Unpatient.Prescription) => {
            switch (type) {
              case PrescriptionTypes.BIOLOGY: {
                form.setValue('biologyItems', template.biologyItems);
                break;
              }

              case PrescriptionTypes.IMAGERY: {
                form.setValue('imageryItems', template.imageryItems);
                break;
              }

              case PrescriptionTypes.FREE: {
                form.setValue('freeItems', template.freeItems);
                break;
              }

              default:
                break;
            }
          }}
        />
      </div>
    );
  }, [type, isNewPrescription]);

  return (
    <div className="">
      {!isNewPrescription && (
        <div className="my-6">
          <StandardInput
            register={register}
            id="title"
            label="Titre de la prescription"
            type="text"
          />
        </div>
      )}
      <RadioInput
        control={control}
        id="type"
        options={selectPrescriptionTypes}
        disabled={!isNewPrescription}
      />
      {maybeFillWithPrescriptionTemplates}
      {prescriptionForm}
    </div>
  );
};

export default PrescriptionForm;
