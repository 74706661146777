import * as yup from 'yup';
import { useFieldArray } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { selectInputSpecialities } from '@@/constants/practitioner';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectInput from '@@/components/Inputs/SelectInput';
import PhonePickerInput from '@@/components/Inputs/PhonePickerInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';
import SectionTitle from '@@/components/SectionTitle';

export const schema = yup
  .object({
    firstName: yup.string().required('Veuillez entrer votre prénom'),
    lastName: yup.string().required('Veuillez entrer votre nom'),
    email: yup
      .string()
      .email('Veuillez entrer un email valide')
      .required('Veuillez entrer votre email'),
    phone: yup.string().required('Veuillez entrer votre numéro de téléphone'),
    address: yup.string(),
    city: yup.string(),
    postcode: yup.string(),
    country: yup.string(),
    rpps: yup.string(),
    am: yup.string(),
    signature: yup.string(),
    speciality: yup.string(),
    secureEmail: yup.string(),
    diploma: yup.array().of(
      yup.object({
        title: yup.string(),
        date: yup.date(),
      }),
    ),
  })
  .required();

const PractitionnerForm: React.FC<{ form: any }> = ({ form }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const {
    fields: diplomas,
    append: appendDiploma,
    remove: removeDiploma,
  } = useFieldArray({
    control,
    name: 'diplomas',
  });

  return (
    <form className="max-w-5xl">
      <SectionTitle title="Etat Civil" className="mb-10" />
      <div className="grid grid-cols-3 gap-12">
        <StandardInput
          register={register}
          id="firstName"
          label="Prénom (*)"
          type="text"
          placeholder="Prénom (*)"
          error={errors.firstName}
        />
        <StandardInput
          register={register}
          id="lastName"
          label="Nom (*)"
          type="text"
          placeholder="Nom (*)"
          error={errors.lastName}
        />
        <StandardInput
          register={register}
          id="email"
          label="Email (*)"
          type="text"
          placeholder="Email (*)"
          error={errors.email}
        />
        <PhonePickerInput
          control={control}
          id="phone"
          error={errors.phone}
          label="Téléphone (*)"
        />
        <StandardInput
          register={register}
          id="address"
          label="Adresse"
          type="text"
          placeholder="Adresse"
          error={errors.address}
        />
        <StandardInput
          register={register}
          id="postcode"
          label="Code postal"
          type="text"
          placeholder="Code postal"
          error={errors.postcode}
        />
        <StandardInput
          register={register}
          id="city"
          label="Ville"
          type="text"
          placeholder="Ville"
          error={errors.city}
        />
        <StandardInput
          register={register}
          id="country"
          label="Pays"
          type="text"
          placeholder="Pays"
          error={errors.country}
        />
      </div>

      <SectionTitle title="Pratique médicale" className="mt-16 mb-12" />
      <div className="grid grid-cols-2 gap-12">
        <div className="-mt-6">
          <SelectInput
            register={register}
            id={`speciality`}
            label="Spécialité"
            options={selectInputSpecialities}
            placeholder="Choisissez votre spécialité"
          />
        </div>
        <StandardInput
          register={register}
          id="rpps"
          label="Num. RPPS"
          type="text"
          placeholder="Num. RPPS"
          error={errors.rpps}
        />
        <StandardInput
          register={register}
          id="am"
          label="Num. AM ou FINESS"
          type="text"
          placeholder="Num. AM ou FINESS"
          error={errors.am}
        />
        <StandardInput
          register={register}
          id="cdom"
          label="Num. Ordre"
          type="text"
          placeholder="Num. Ordre"
          error={errors.cdom}
        />
        <StandardInput
          register={register}
          id="secureEmail"
          label="Messagerie sécurisée"
          type="text"
          placeholder="Messagerie sécurisée"
          error={errors.secureEmail}
        />

        <StandardInput
          register={register}
          id="videoRoomLink"
          label="Lien de téléconsultation"
          type="text"
          placeholder="Lien de téléconsultation"
          error={errors.videoRoomLink}
        />
      </div>

      <div className="flex flex-col mt-10">
        <p className="mb-8 font-main text-lg text-main underline">
          Diplôme(s) :
        </p>
        <ul className="flex flex-col space-y-12">
          {diplomas.map((field, index) => (
            <div key={index} className="flex flex-row items-center space-x-4">
              <StandardInput
                register={register}
                id={`diplomas.${index}.title`}
                label={`Intitulé diplôme ${index + 1}`}
                placeholder={`Intitulé diplôme ${index + 1}`}
                type="text"
              />

              <div className="grow-0">
                <DatePickerInput
                  id={`diplomas.${index}.date`}
                  control={control}
                  label="Date d'obtention"
                />
              </div>

              <button type="button" onClick={() => removeDiploma(index)}>
                <RiDeleteBinLine size={24} />
              </button>
            </div>
          ))}
        </ul>

        <button
          type="button"
          className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base flex flex-row items-center w-fit"
          onClick={() => appendDiploma('')}
        >
          <IoIosAddCircleOutline size={24} />
          <span className="ml-2">Ajouter un diplôme</span>
        </button>
      </div>
    </form>
  );
};

export default PractitionnerForm;
