import { api } from '@@/services/api';

export const practitionerWaitingListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPractitionerWaitingListEntry: builder.mutation<
      Unpatient.PractitionerWaitingListItem,
      Partial<Unpatient.PractitionerWaitingListItem>
    >({
      query: (body) => {
        return {
          url: 'practitioner-waiting-list',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [],
    }),
  }),
});

export const { useCreatePractitionerWaitingListEntryMutation } =
  practitionerWaitingListApi;
