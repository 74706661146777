import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetUserDocumentsQuery } from '@@/services/document';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPatientDocumentInput: React.FC<{
  id: string;
  patientId: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, patientId, label, placeholder, error, register }) => {
  const { data: documents } = useGetUserDocumentsQuery(patientId);

  const selectDocumentsInput = useMemo(() => {
    if (documents) {
      return [...documents]
        .map((doc) => ({
          id: doc.id,
          label: doc.label || doc.name,
        }))
        .sort((c1, c2) => {
          if (c1.label < c2.label) {
            return -1;
          }

          if (c1.label > c2.label) {
            return 1;
          }

          return 0;
        });
    }

    return [];
  }, [documents]);

  if (!documents) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectDocumentsInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectPatientDocumentInput;
