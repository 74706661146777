export enum TaskTypes {
  RDV_CREATION = 'rdv_creation',
  RDV_DELETION = 'rdv_deletion',
  RDV_PATCH = 'rdv_patch',
  TRANSCRIPT_FETCH = 'transcript_fetch',
  PATIENT_REMINDER = 'patient_reminder',
  CONTACT_SEARCH = 'contact_search',
  OTHER = 'other',
}

export const translateTaskType = (type: string) => {
  switch (type) {
    case TaskTypes.RDV_CREATION:
      return 'Création de RDV';
    case TaskTypes.RDV_PATCH:
      return 'Modification de RDV';
    case TaskTypes.RDV_DELETION:
      return 'Suppression de RDV';
    case TaskTypes.TRANSCRIPT_FETCH:
      return 'Récupération de compte-rendu';
    case TaskTypes.PATIENT_REMINDER:
      return 'Relance patient';
    case TaskTypes.CONTACT_SEARCH:
      return 'Rechercher des contacts';
    case TaskTypes.OTHER:
      return 'Autre';
    default:
      return type;
  }
};

export const selectInputTaskTypes = Object.values(TaskTypes).map((type) => ({
  id: type,
  label: translateTaskType(type),
}));

export enum TaskStatuses {
  TO_VALIDATE = 'to_validate',
  TO_DO = 'to_do',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export const translateTaskStatus = (status: string) => {
  switch (status) {
    case TaskStatuses.TO_VALIDATE:
      return 'A valider';

    case TaskStatuses.TO_DO:
      return 'A faire';

    case TaskStatuses.IN_PROGRESS:
      return 'En cours';

    case TaskStatuses.DONE:
      return 'Terminé';
    default:
      return status;
  }
};

export const selectInputTaskStatuses = Object.values(TaskStatuses).map(
  (status) => ({
    id: status,
    label: translateTaskStatus(status),
  }),
);
