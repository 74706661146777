import React from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';

export const schema = yup
  .object({
    password: yup.string().required('Veuillez entrer votre mot de passe'),
  })
  .required();

const ChangePasswordForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <StandardInput
      register={register}
      id="password"
      label="Mot de passe (*)"
      placeholder="Mot de passe"
      error={errors?.password}
      type="password"
    />
  );
};

export default ChangePasswordForm;
