import React, { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getTitle, Genders } from '@@/constants/medicalRecord';
import { mainColor, smallFontSize, bigFontSize } from '@@/constants/pdf';
import { getAge } from '@@/utils/date';
import {
  PrescriptionTypes,
  translateBiologogyCategory,
  translateBiologogyDecomination,
  translateImageryCategory,
  translateImageryDecomination,
} from '@@/constants/prescription';
import Header from '@@/components/PDF/Header';
import Footer from '@@/components/PDF/Footer';

const styles = StyleSheet.create({
  page: {},
  date: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    marginRight: '16px',
    textAlign: 'right',
  },
  title: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: bigFontSize,
    marginTop: '16px',
    marginLeft: '16px',
  },
  patientBlock: {
    marginTop: '16px',
    marginLeft: '16px',
  },
  text: {
    color: mainColor,
    fontFamily: 'Helvetica',
    fontSize: smallFontSize,
    marginTop: '4px',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: bigFontSize,
    color: mainColor,
  },
  italic: {
    marginTop: '4px',
    fontFamily: 'Helvetica-Oblique',
    fontSize: smallFontSize,
    color: mainColor,
  },
  prescriptionBlock: {
    margin: '16px',
    marginTop: '24px',
  },
  prescriptionTitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: bigFontSize,
    color: mainColor,
    marginBottom: '16px',
    textDecoration: 'underline',
  },
  block: {
    marginTop: '12px',
    marginBottom: '12px',
  },
});

const Prescription: React.FC<{
  prescription?: Unpatient.Prescription;
  patient?: Unpatient.User;
  practitioner?: Unpatient.User;
  signature?: string;
}> = ({ prescription, patient, practitioner, signature }) => {
  const prescriptionItems = useMemo(() => {
    switch (prescription?.type) {
      case PrescriptionTypes.BIOLOGY:
        return (
          <View style={styles.prescriptionBlock}>
            <Text style={styles.prescriptionTitle}>
              Examen(s) biologique(s) :
            </Text>
            {prescription.biologyItems?.map((item, index) => (
              <View key={index} style={styles.block} wrap={false}>
                <Text style={styles.text}>
                  #{index + 1}/ {translateBiologogyCategory(item.category)}
                  &nbsp;:&nbsp;
                  {translateBiologogyDecomination(
                    item.category,
                    item.denomination,
                  )}
                </Text>
                <Text style={styles.italic}>{item.comment}</Text>
              </View>
            ))}
          </View>
        );
      case PrescriptionTypes.IMAGERY:
        return (
          <View style={styles.prescriptionBlock}>
            <Text style={styles.prescriptionTitle}>Examen(s) d'imagerie :</Text>
            {prescription.imageryItems?.map((item, index) => (
              <View key={index} style={styles.block} wrap={false}>
                <Text style={styles.text}>
                  #{index + 1}/ {translateImageryCategory(item.category)} :{' '}
                  {translateImageryDecomination(
                    item.category,
                    item.denomination,
                  )}
                </Text>
                <Text style={styles.italic}>{item.comment}</Text>
              </View>
            ))}
          </View>
        );
      case PrescriptionTypes.FREE:
        return (
          <View style={styles.prescriptionBlock}>
            {prescription.freeItems?.map((item, index) => (
              <View key={index} style={styles.block} wrap={false}>
                <Text style={styles.text}>{item.text}</Text>
                <Text style={styles.italic}>{item.comment}</Text>
              </View>
            ))}
          </View>
        );
      default:
        return null;
    }
  }, [prescription]);

  if (!patient?.medicalRecord?.birthDate) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Header practitioner={practitioner} />
          <View style={{ margin: '16px' }}>
            {!patient?.medicalRecord?.birthDate && (
              <Text style={styles.text}>
                - Veuillez entrer la date de naissance du patient dans son
                dossier médical
              </Text>
            )}
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header practitioner={practitioner} />

        <Text style={styles.date}>
          Le {moment(prescription?.createdAt).format('DD/MM/YYYY')},
        </Text>

        <Text style={styles.title}>Ordonnance à destination de :</Text>
        <View style={styles.patientBlock}>
          <Text style={styles.bold}>
            {getTitle(patient?.medicalRecord?.gender)}&nbsp;{patient?.firstName}
            &nbsp;
            {patient?.lastName}
          </Text>
          <Text style={styles.text}>
            né{patient?.medicalRecord?.gender === Genders.F ? '(e) ' : ' '}
            le&nbsp;
            {moment(patient?.medicalRecord?.birthDate).format('DD/MM/YYYY')}
            ,&nbsp;
            {getAge(patient?.medicalRecord?.birthDate)} ans
          </Text>
        </View>

        {prescriptionItems}

        <Footer practitioner={practitioner} signature={signature} />
      </Page>
    </Document>
  );
};

export default Prescription;
