import React, { useState, useCallback } from 'react';
import cx from 'classnames';

const EditableCell: React.FC<any> = ({ getValue, row, column, table }) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue || undefined);

  const onChange = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const onBlur = () => {
    if (value !== initialValue) {
      table.options.meta?.updateData(
        table.getRow(row.index).original.id,
        column.id,
        value,
      );
    }
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    table.options.meta?.updateData(
      table.getRow(row.index).original.id,
      column.id,
      e.target.value,
    );
  };

  if (column.columnDef.meta?.type === 'select') {
    const customClassName = column.columnDef.meta.getClassName
      ? column.columnDef.meta.getClassName(table.getRow(row.index).original)
      : '';

    return (
      <select
        onChange={onSelectChange}
        value={initialValue}
        className={cx(
          customClassName || 'bg-transparent text-sm',
          column.columnDef.meta?.width || 'w-18',
        )}
      >
        {column.columnDef.meta?.options?.map(
          (option: { id: string; label: string }) => (
            <option key={option.id} value={option.id} className="text-sm">
              {option.label}
            </option>
          ),
        )}
      </select>
    );
  }

  if (column.columnDef.meta?.type === 'textarea') {
    return (
      <textarea
        className={cx(
          'bg-gray-100 text-center text-sm rounded-lg p-0.5',
          column.columnDef.meta?.width || 'w-12',
        )}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        rows={column.columnDef.meta?.rows || 3}
      />
    );
  }

  return (
    <input
      className={cx(
        'bg-transparent text-center text-sm mx-1 my-0.5',
        column.columnDef.meta?.width || 'w-12',
      )}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type={column.columnDef.meta?.type || 'text'}
    />
  );
};
export default EditableCell;
