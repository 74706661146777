import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import {
  useGetBiologyCategoriesQuery,
  useGetBiologyExamsQuery,
} from '@@/services/prescription';
import SelectInput from '@@/components/Inputs/SelectInput';
import StandardInput from '@@/components/Inputs/StandardInput';

const BiologicalExam: React.FC<{
  form: any;
  index: number;
  removeItem: (arg: number) => void;
  categories?: { id: string; label: string }[];
}> = ({ form, index, removeItem, categories }) => {
  const { register, watch } = form;
  const category = watch(`biologyItems[${index}].category`);
  const { data: exams } = useGetBiologyExamsQuery(category || '', {
    skip: !category,
  });

  return (
    <div>
      <div className="flex flex-row justify-between mb-6 text-white font-main bg-cyan-400 p-2 rounded-lg">
        <p className=" text-lg font-semibold">Examen biologique #{index + 1}</p>
        <button type="button" onClick={() => removeItem(index)}>
          <RiDeleteBinLine size={24} />
        </button>
      </div>
      <div className="flex flex-row space-x-10">
        <div className="flex flex-col space-y-8">
          {!!categories && (
            <SelectInput
              register={register}
              id={`biologyItems[${index}].category`}
              label="Categorie de l'examen"
              options={[{ id: '', label: 'Choisissez une catégorie' }].concat(
                categories,
              )}
            />
          )}

          {!!exams && (
            <SelectInput
              register={register}
              id={`biologyItems[${index}].denomination`}
              label="Libellé de l'examen"
              options={exams}
            />
          )}
        </div>
        {!!exams && (
          <div className="mt-6 w-96">
            <StandardInput
              register={register}
              id={`biologyItems[${index}].comment`}
              label="Commentaire"
              type="textarea"
              placeholder="Commentaire"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const BiologicalPrescriptionForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const { control } = form;
  const { data: categories } = useGetBiologyCategoriesQuery();

  const {
    fields: biologyItems,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'biologyItems',
  });

  return (
    <div className="flex flex-col mt-6 w-fit">
      <p className="mb-8 font-main text-lg text-main underline">
        Examen(s) biologique(s) :
      </p>
      <ul className="flex flex-col space-y-12">
        {biologyItems.map((field, index) => (
          <BiologicalExam
            key={field.id}
            form={form}
            index={index}
            removeItem={removeItem}
            categories={categories}
          />
        ))}
      </ul>

      <button
        type="button"
        className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base flex flex-row items-center w-fit"
        onClick={() => appendItem({})}
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-2">Ajouter un examen</span>
      </button>
    </div>
  );
};
