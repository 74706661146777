import React, { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetMeQuery, useGetPatientQuery } from '@@/services/user';
import { useGetChatsStatusQuery } from '@@/services/whatsapp';
import { BiClinic } from 'react-icons/bi';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { BiConversation } from 'react-icons/bi';
import { SiBaremetrics } from 'react-icons/si';
import { LiaFaxSolid } from 'react-icons/lia';
import { TfiTimer } from 'react-icons/tfi';
import { TbMessage2Plus } from 'react-icons/tb';
import { LuContact2 } from 'react-icons/lu';
import { GoTasklist } from 'react-icons/go';

import { Roles } from '@@/constants/user';
import { Statuses } from '@@/constants/whatsapp';

const handleLinkStyle = (
  pathname: string,
  targetPathName: string,
  wsStatus?: string,
) => {
  if (wsStatus && wsStatus !== Statuses.READY) {
    return 'text-red-500 underline flex flex-row items-center mb-6';
  }

  if (pathname.includes(targetPathName)) {
    return 'text-main underline flex flex-row items-center mb-6';
  } else {
    return 'flex flex-row items-center mb-6';
  }
};

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { userId, patientId } = useParams();
  const { data: me } = useGetMeQuery();
  const { data: wsStatus } = useGetChatsStatusQuery();
  const { data: patient } = useGetPatientQuery(patientId || userId || '', {
    skip: !patientId && !userId,
  });

  const phone = useMemo(() => patient?.wsPhone || patient?.phone, [patient]);

  return (
    <div className="bg-white font-main">
      <div className="flex flex-col p-4">
        <Link
          className={handleLinkStyle(location.pathname, '/patients')}
          to="/patients"
        >
          <BiClinic size={30} />
          <span className="ml-1 text-sm">Patients</span>
        </Link>

        <Link
          className={handleLinkStyle(location.pathname, '/calendar')}
          to={`/calendar`}
        >
          <IoCalendarClearOutline size={30} />
          <span className="ml-1 text-sm">Calendrier</span>
        </Link>
        <Link
          className={handleLinkStyle(
            location.pathname,
            '/conversations',
            wsStatus?.status,
          )}
          to={phone ? `/conversations/c.us/${phone}` : `/conversations`}
        >
          <BiConversation size={30} />
          <span className="ml-1 text-sm">Conversations</span>
        </Link>

        <Link
          className={handleLinkStyle(location.pathname, '/scheduled-messages')}
          to={`/scheduled-messages`}
        >
          <TfiTimer size={30} />
          <span className="ml-1 text-sm">Messages programmés</span>
        </Link>

        <Link
          className={handleLinkStyle(location.pathname, '/template-messages')}
          to={`/template-messages`}
        >
          <TbMessage2Plus size={30} />
          <span className="ml-1 text-sm">Messages types</span>
        </Link>

        <Link
          className={handleLinkStyle(
            location.pathname,
            '/practitioner-contacts',
          )}
          to={`/practitioner-contacts`}
        >
          <LuContact2 size={30} />
          <span className="ml-1 text-sm">Contacts</span>
        </Link>

        <Link
          className={handleLinkStyle(location.pathname, '/tasks')}
          to={`/tasks`}
        >
          <GoTasklist size={30} />
          <span className="ml-1 text-sm">Tâches</span>
        </Link>

        <Link
          className={handleLinkStyle(location.pathname, '/doctolib')}
          to={`/doctolib/appointments`}
        >
          <img src="/logo-doctolib.png" className="w-7" />
          <span className="ml-1 text-sm">Doctolib</span>
        </Link>

        <Link
          className={handleLinkStyle(location.pathname, '/faxes')}
          to={`/faxes`}
        >
          <LiaFaxSolid size={30} />
          <span className="ml-1 text-sm">FAXs</span>
        </Link>

        {me?.role === Roles.ADMIN && (
          <Link
            className={handleLinkStyle(location.pathname, '/metrics')}
            to={`/metrics`}
          >
            <SiBaremetrics size={30} />
            <span className="ml-1 text-sm">Metrics</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
