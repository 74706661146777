import React from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';

export const schema = yup
  .object({
    title: yup.string().required('Veuillez renseigner le titre du message'),
    body: yup.string().required('Veuillez renseigner le corps du message'),
  })
  .required();

const TemplateMessageForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="flex flex-col mt-8 space-y-8">
      <StandardInput
        register={register}
        id="title"
        label="Title (*)"
        type="text"
        placeholder="Title (*)"
        error={errors.title}
      />
      <StandardInput
        register={register}
        id="body"
        label="Message (*)"
        type="textarea"
        placeholder="Message (*)"
        error={errors.body}
      />
    </div>
  );
};

export default TemplateMessageForm;
