import React from 'react';
import moment from 'moment';
import { useGetAppointmentsQuery } from '@@/services/doctolib';

const AppointmentCard: React.FC<{ appointment: Doctolib.Appointment }> = ({
  appointment,
}) => {
  return (
    <div className="inline-block align-top m-2 w-[300px]">
      <p className="py-2 px-4 bg-teal-700 text-white rounded-t-xl">
        {moment(appointment.start_date).calendar()}
      </p>
      <div className="border border-gray-200">
        <div className="m-2">
          <div className="flex flex-row items-center">
            <img
              alt={`profile-${appointment.profile.name_with_title}`}
              className="rounded-full w-16 h-16"
              src={`https://media.doctolib.com/image/upload/q_auto:eco,f_auto,dpr_2/w_62,h_62,c_fill,g_face/${appointment.profile.cloudinary_public_id}`}
            />
            <div className="ml-2">
              <p className="font-bold">{appointment.profile.name_with_title}</p>
              <p>{appointment.profile.speciality}</p>
            </div>
          </div>

          <p className="my-2 italic">
            {appointment.visit_motive.name || 'Consultation sans motif'}
          </p>
        </div>

        <p className="py-2 border-t text-center">
          <span className="mx-2">
            {appointment.patient.first_name} {appointment.patient.last_name}
          </span>
        </p>
      </div>
    </div>
  );
};

const DoctolibAppointmentsPage: React.FC = () => {
  const { data: appointments, isLoading } = useGetAppointmentsQuery();

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      {appointments?.map((appointment) => (
        <AppointmentCard key={appointment.id} appointment={appointment} />
      ))}
    </div>
  );
};

export default DoctolibAppointmentsPage;
