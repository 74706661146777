import React, { useMemo, useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdOutlineEdit } from 'react-icons/md';
import {
  useGetTasksQuery,
  usePatchTaskMutation,
  useDeleteTaskMutation,
  TaskInputType,
} from '@@/services/task';
import {
  selectInputTaskTypes,
  TaskStatuses,
  TaskTypes,
  selectInputTaskStatuses,
} from '@@/constants/task';
import { useGetMeQuery } from '@@/services/user';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import EditableCell from '@@/components/Table/EditableCell';
import { Filter, nameMatchFilter } from '@@/components/Table/Filter';
import { translateSpeciality } from '@@/constants/practitioner';

const columnHelper = createColumnHelper<Unpatient.Task>();

const TasksPage: React.FC = () => {
  const { data: me } = useGetMeQuery();
  const { data: tasks } = useGetTasksQuery();
  const [deleteTask, { isSuccess: isDeletionSuccess }] =
    useDeleteTaskMutation();

  const [patchTask, { isSuccess: isPatchSuccess }] = usePatchTaskMutation();

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success('La tâche a bien été supprimée');
      window.location.reload();
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success('La tâche a bien été modifiée');
      window.location.reload();
    }
  }, [isPatchSuccess]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => <p className=""></p>,
        cell: EditableCell,
        meta: {
          width: 'w-0',
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: EditableCell,
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(selectInputTaskTypes),
          width: 'w-40',
          getClassName: (row: Unpatient.Task) => {
            switch (row.type) {
              case TaskTypes.CONTACT_SEARCH:
                return 'bg-purple-500 p-1 rounded-full text-white text-sm text-wrap h-10 text-center';
              case TaskTypes.PATIENT_REMINDER:
                return 'bg-rose-500 p-1 rounded-full text-white text-sm text-center';
              case TaskTypes.TRANSCRIPT_FETCH:
                return 'bg-sky-500 p-1 rounded-full text-white text-sm text-wrap h-10 text-center';
              case TaskTypes.RDV_CREATION:
                return 'bg-pink-500 p-1 rounded-full text-white text-sm text-center';
              case TaskTypes.RDV_PATCH:
                return 'bg-amber-500 p-1 rounded-full text-white text-sm text-center';
              case TaskTypes.RDV_DELETION:
                return 'bg-red-500 p-1 rounded-full text-white text-sm text-center';
              case TaskTypes.OTHER:
                return 'bg-gray-500 p-1 rounded-full text-white text-sm text-center';
              default:
                return '';
            }
          },
        },
      }),
      columnHelper.accessor('status', {
        header: 'Statut',
        cell: EditableCell,
        filterFn: (row, columnId, filterValue) => {
          if (!filterValue) {
            return true;
          }

          const cellValue = row.original[columnId as keyof Unpatient.Task];

          return !!selectInputTaskStatuses
            .find((i) => i.id === cellValue)
            ?.label?.toLowerCase()
            ?.includes(filterValue?.toLowerCase());
        },
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(selectInputTaskStatuses),
          width: 'w-21',
          getClassName: (row: Unpatient.Task) => {
            if (row.status === TaskStatuses.DONE) {
              return 'bg-green-500 p-1 rounded-full text-white text-sm text-center';
            }

            if (!row.deadline) {
              return 'bg-yellow-500 p-1 rounded-full text-white text-sm text-center';
            }

            const now = moment();
            const deadlineMoment = moment(row.deadline);
            if (
              deadlineMoment.isSame(now, 'week') ||
              deadlineMoment.diff(now, 'days') < 4
            ) {
              return 'bg-red-500 p-1 rounded-full text-white text-sm text-center';
            }

            if (deadlineMoment.diff(now, 'days') < 30) {
              return 'bg-orange-500 p-1 rounded-full text-white text-sm text-center';
            }

            return 'bg-yellow-500 p-1 rounded-full text-white text-sm text-center';
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'ownerId',
        header: 'Responsable',
        filterFn: nameMatchFilter,
        cell: (row) => {
          const { ownerId, creatorId } = row.getValue();

          const owner = ownerId || creatorId;

          if (!owner) {
            return null;
          }

          return (
            <div className="mx-2 text-sm">
              {ownerId.firstName} {ownerId.lastName}
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'patientId',
        header: 'Patient',
        filterFn: nameMatchFilter,
        cell: (row) => {
          const { patientId } = row.getValue();

          if (!patientId) {
            return null;
          }

          return (
            <Link
              className="mx-2 text-sm underline"
              to={`/conversations/c.us/${patientId.wsPhone || patientId.phone}`}
            >
              {patientId.firstName} {patientId.lastName}
            </Link>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'practitionerContactId',
        header: 'Contact',
        filterFn: nameMatchFilter,
        cell: (row) => {
          const { practitionerContactId } = row.getValue();

          if (!practitionerContactId) {
            return null;
          }

          return (
            <div className="mx-2">
              <Link
                className="text-sm underline"
                to={`/practitioner-contacts/${practitionerContactId.id}`}
              >
                {practitionerContactId.name}&nbsp;
              </Link>
              <p className="text-amber-700">
                {translateSpeciality(practitionerContactId?.speciality)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor('request', {
        id: 'request',
        header: () => <p className="mx-2 text-xs sm:text-sm">Demande</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 4,
          width: 'w-[250px]',
        },
      }),
      columnHelper.accessor('response', {
        id: 'response',
        header: () => <p className="mx-2 text-xs sm:text-sm">Réponse</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 4,
          width: 'w-[250px]',
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'edit',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div className="mx-2">
              <Link
                to={`/tasks/${id}`}
                className="flex flex-row items-center justify-center w-fit border-2 border-green-500 bg-green-500 rounded-3xl p-1"
                rel="noopener noreferrer"
              >
                <MdOutlineEdit color="white" />
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'delete',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div className="mx-2">
              <button
                onClick={() => {
                  confirm('Voulez-vous vraiment supprimer cette tâche ?') &&
                    deleteTask(id);
                }}
                className="flex flex-row items-center justify-center w-fit border-2 border-red-500 bg-red-500 rounded-3xl p-1"
              >
                <TiDeleteOutline color="white" />
              </button>
            </div>
          );
        },
      }),
    ],
    [deleteTask],
  );

  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const toggleShowCompletedTasks = useCallback(() => {
    setShowCompletedTasks((showCompletedTasks) => !showCompletedTasks);
  }, [setShowCompletedTasks]);

  const data = useMemo(() => {
    if (!tasks) {
      return [];
    }

    return tasks
      .filter((task) => {
        if (showCompletedTasks) {
          return true;
        }
        return task.status !== TaskStatuses.DONE;
      })
      .sort((a, b) => {
        if (a.status === TaskStatuses.DONE && b.status !== TaskStatuses.DONE) {
          return 1;
        }

        if (b.status === TaskStatuses.DONE && a.status !== TaskStatuses.DONE) {
          return -1;
        }

        if (a.ownerId?.id === me?.id && b.ownerId?.id !== me?.id) {
          return -1;
        }

        if (b.ownerId?.id === me?.id && a.ownerId?.id !== me?.id) {
          return 1;
        }

        if (a.deadline && !b.deadline) {
          return -1;
        }

        if (b.deadline && !a.deadline) {
          return 1;
        }

        const dateA = moment(a.deadline);
        const dateB = moment(b.deadline);

        if (dateA.isBefore(dateB)) {
          return -1;
        }

        if (dateA.isAfter(dateB)) {
          return 1;
        }

        return 0;
      });
  }, [tasks, showCompletedTasks, me?.id]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    meta: {
      updateData: (id: string, columnId: string, value: string) => {
        const body = {
          [columnId]: value,
        } as unknown as TaskInputType;

        patchTask({ ...body, id });
      },
    },
  });

  return (
    <div className="m-4 w-full">
      <div className="block w-fit ml-auto">
        <Link to="/tasks/new" className="bg-cyan-400 p-2 rounded-lg h-fit">
          <span className="text-white">Nouvelle tâche</span>
        </Link>
      </div>

      <table className="my-8">
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-center mx-2">
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <span className="pl-2">↑</span>,
                          desc: <span className="pl-2">↓</span>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} />
                      ) : null}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-center mx-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <button
        className="block mx-auto p-2 mb-10"
        onClick={toggleShowCompletedTasks}
      >
        <span className="font-main text-main underline">
          {showCompletedTasks
            ? 'Cacher les tâches finalisées'
            : 'Afficher les tâches finalisées'}
        </span>
      </button>
    </div>
  );
};

export default TasksPage;
