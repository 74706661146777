import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export type ReferralFormType = Omit<
  Unpatient.Referral,
  'referredPractitioner'
> & {
  referredPractitioner: string;
};

export const referralApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReferral: builder.query<Unpatient.Referral, string>({
      query: (referralId) => `referrals/${referralId}`,
      providesTags: ['Referrals'],
    }),
    getUserReferrals: builder.query<Unpatient.Referral[], string>({
      query: (userId) => `users/${userId}/referrals`,
      providesTags: ['Referrals'],
    }),
    createReferral: builder.mutation<
      Unpatient.Referral,
      Partial<ReferralFormType>
    >({
      query: (body) => {
        const formData = new FormData();

        return {
          url: `referrals`,
          method: 'POST',
          body: convertToFormData(
            { ...body, documents: body.documents?.map((d) => d.id) },
            formData,
          ),
          formData: true,
        };
      },
      invalidatesTags: ['Referrals'],
    }),
    deleteReferral: builder.mutation<{}, string>({
      query: (referralId) => ({
        url: `referrals/${referralId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Referrals'],
    }),
    patchReferral: builder.mutation<
      Unpatient.Referral,
      Partial<ReferralFormType>
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();

        if (!body.documents?.length) {
          formData.append('documents[]', '');
        }

        return {
          url: `/referrals/${id}`,
          method: 'PATCH',
          body: convertToFormData(
            { ...body, documents: body.documents?.map((d) => d.id) },
            formData,
          ),
          formData: true,
        };
      },
      invalidatesTags: ['Referrals'],
    }),
    exportDocuments: builder.query<string, string>({
      query: (referralId) => ({
        url: `referrals/${referralId}/export-documents`,
        responseHandler: async (response) => {
          const contentType = response.headers.get('Content-Type');
          const blob = await response.blob();

          return URL.createObjectURL(
            contentType ? new Blob([blob], { type: contentType }) : blob,
          );
        },
      }),
    }),
  }),
});

export const {
  useGetReferralQuery,
  useGetUserReferralsQuery,
  useCreateReferralMutation,
  useDeleteReferralMutation,
  usePatchReferralMutation,
  useLazyExportDocumentsQuery,
} = referralApi;
