import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { mainColor, biggerFontSize, bigFontSize } from '@@/constants/pdf';

const styles = StyleSheet.create({
  container: {
    margin: '16px 16px 16px auto',
  },
  doctorName: {
    fontFamily: 'Helvetica-Bold',
    fontSize: biggerFontSize,
    color: mainColor,
  },
  italic: {
    fontFamily: 'Helvetica-Oblique',
    fontSize: bigFontSize,
    color: mainColor,
  },
  signature: {
    width: '150px',
    height: 'auto',
  },
});

const Footer: React.FC<{
  practitioner?: Unpatient.User;
  signature?: string;
}> = ({ practitioner, signature }) => {
  if (!practitioner) {
    return null;
  }

  return (
    <View style={styles.container} fixed>
      <Text style={styles.doctorName}>
        Dr {practitioner.firstName} {practitioner.lastName}
      </Text>
      <Text style={styles.italic}>Signature sécurisée</Text>
      {!!signature && <Image style={styles.signature} src={signature} />}
    </View>
  );
};

export default Footer;
