import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetAllPractitionersQuery } from '@@/services/user';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPractitionerInput: React.FC<{
  id: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, label, placeholder, error, register }) => {
  const { data: practitioners } = useGetAllPractitionersQuery();

  const selectPractionersInput = useMemo(() => {
    if (practitioners) {
      return [...practitioners]
        .map((patient) => ({
          id: patient.id,
          label: `${patient.lastName.toUpperCase()} ${patient.firstName}`,
        }))
        .sort((c1, c2) => {
          if (c1.label < c2.label) {
            return -1;
          }

          if (c1.label > c2.label) {
            return 1;
          }

          return 0;
        });
    }

    return [];
  }, [practitioners]);

  if (!practitioners) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectPractionersInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectPractitionerInput;
