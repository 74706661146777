import React, { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import cx from 'classnames';
import AppointmentPage from '@@/pages/Doctolib/AppointmentsPage';
import PatientsPage from '@@/pages/Doctolib/PatientsPage';
import ConversationsPage from '@@/pages/Doctolib/ConversationsPage';
import DocumentsPage from '@@/pages/Doctolib/DocumentsPage';

const DoctolibPage: React.FC = () => {
  const location = useLocation();

  const header = useMemo(() => {
    return (
      <div className="m-2 mb-4 pb-3 flex flex-row border-b border-b-gray-300">
        <Link
          className={cx(
            'py-2 px-4 mr-2 rounded-lg text-white',
            ['/doctolib/appointments', '/doctolib'].includes(location.pathname)
              ? 'bg-cyan-900'
              : 'bg-cyan-500',
          )}
          to="/doctolib/appointments"
          rel="noopener noreferrer"
        >
          RDVs
        </Link>
        <Link
          className={cx(
            'py-2 px-4 mr-2 rounded-lg text-white',
            location.pathname === '/doctolib/patients'
              ? 'bg-cyan-900'
              : 'bg-cyan-500',
          )}
          to="/doctolib/patients"
          rel="noopener noreferrer"
        >
          Patients
        </Link>
        <Link
          className={cx(
            'py-2 px-4 mr-2 rounded-lg text-white',
            location.pathname === '/doctolib/messages'
              ? 'bg-cyan-900'
              : 'bg-cyan-500',
          )}
          to="/doctolib/messages"
          rel="noopener noreferrer"
        >
          Conversations
        </Link>
        <Link
          className={cx(
            'py-2 px-4 mr-2 rounded-lg text-white',
            location.pathname === '/doctolib/documents'
              ? 'bg-cyan-900'
              : 'bg-cyan-500',
          )}
          to="/doctolib/documents"
          rel="noopener noreferrer"
        >
          Documents
        </Link>
      </div>
    );
  }, [location.pathname]);

  const body = useMemo(() => {
    switch (location.pathname) {
      case '/doctolib/patients':
        return <PatientsPage />;
      case '/doctolib/documents':
        return <DocumentsPage />;
      case '/doctolib/messages':
        return <ConversationsPage />;
      default:
        return <AppointmentPage />;
    }
  }, [location.pathname]);

  return (
    <div>
      {header}
      {body}
    </div>
  );
};

export default DoctolibPage;
