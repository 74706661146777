import React, { useMemo } from 'react';
import moment from 'moment';
import { useGetStripeMetricsQuery } from '@@/services/stripe';
import { useGetActivityMetricsQuery } from '@@/services/whatsapp';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
} from 'recharts';

const MetricsPage: React.FC = () => {
  const { data: stripeMetrics } = useGetStripeMetricsQuery();
  const { data: activityMetrics } = useGetActivityMetricsQuery();

  const stripeData1 = useMemo(() => {
    if (!stripeMetrics) {
      return [];
    }
    return stripeMetrics.invoices?.map((metric) => {
      return {
        name: moment(
          `${metric._id.month} ${metric._id.year}`,
          'MM YYYY',
        ).format('MMM YY'),
        amountPaid: metric.amountPaid,
        customers: metric.customers,
      };
    });
  }, [stripeMetrics]);

  const totalPayment = useMemo(
    () =>
      stripeMetrics?.invoices.reduce((accum, inv) => {
        return accum + inv.amountPaid;
      }, 0),
    [stripeMetrics],
  );

  const stripeData2 = useMemo(() => {
    if (!stripeMetrics) {
      return [];
    }
    return stripeMetrics.subscriptions?.map((metric) => {
      return {
        name: metric._id,
        value: metric.count,
        fill: metric._id === 'active' ? '#8884d8' : '#FF0000',
      };
    });
  }, [stripeMetrics]);

  const nbCustomers = useMemo(
    () =>
      stripeMetrics?.subscriptions.reduce((accum, sub) => {
        return sub._id === 'active' ? accum + sub.count : accum;
      }, 0),
    [stripeMetrics],
  );

  const activityData = useMemo(() => {
    if (!activityMetrics) {
      return [];
    }
    return activityMetrics.messages.map((metric, i) => {
      return {
        name: moment(
          `${metric._id.month} ${metric._id.year}`,
          'MM YYYY',
        ).format('MMM YY'),
        messages: metric.count,
        documents: activityMetrics.documents[i]?.count || 0,
      };
    });
  }, [activityMetrics]);

  const totalMessages = useMemo(
    () =>
      activityMetrics?.messages.reduce((accum, month) => {
        return accum + month.count;
      }, 0),
    [activityMetrics],
  );

  const totalDocuments = useMemo(
    () =>
      activityMetrics?.documents.reduce((accum, month) => {
        return accum + month.count;
      }, 0),
    [activityMetrics],
  );

  return (
    <div className="relative m-4 w-full">
      <div className="mb-6 font-main text-main ">
        <p className="underline text-lg">Evolution Paiements x Clients</p>

        <p className="mt-1">
          Montant total perçu :&nbsp;
          {totalPayment?.toLocaleString('fr', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </p>
      </div>

      <LineChart
        width={1000}
        height={400}
        data={stripeData1}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 30,
        }}
      >
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={-45} textAnchor="end" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="top" align="right" />

        <Line
          type="monotone"
          dataKey="amountPaid"
          stroke="#8884d8"
          yAxisId="left"
        />
        <Line
          type="monotone"
          dataKey="customers"
          stroke="#FF0000"
          yAxisId="right"
        />
      </LineChart>

      <p className="mt-12 mb-6 font-main text-main underline text-lg">
        Souscriptions ({nbCustomers} actives)
      </p>

      <PieChart width={400} height={200}>
        <Pie
          data={stripeData2}
          dataKey="value"
          nameKey="name"
          outerRadius={100}
          label
        />
        <Tooltip />
      </PieChart>

      <p className="mt-12 mb-6 font-main text-main underline text-lg">
        {totalMessages?.toLocaleString('fr', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
        &nbsp;Messages et&nbsp;
        {totalDocuments?.toLocaleString('fr', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
        &nbsp;Documents
      </p>

      <LineChart
        width={1000}
        height={400}
        data={activityData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 30,
        }}
      >
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={-45} textAnchor="end" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="top" align="right" />

        <Line
          type="monotone"
          dataKey="messages"
          stroke="#8884d8"
          yAxisId="left"
        />
        <Line
          type="monotone"
          dataKey="documents"
          stroke="#FF0000"
          yAxisId="right"
        />
      </LineChart>

      <div className="h-16" />
    </div>
  );
};

export default MetricsPage;
