import React from 'react';
import { Link } from 'react-router-dom';
import { MdCheck } from 'react-icons/md';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Lottie from 'react-lottie';
import steto from '@@/assets/steto.json';
import AB from '@@/assets/AB.png';
import AL from '@@/assets/AL.png';
import SG from '@@/assets/SG.jpeg';

const Profile = ({
  name,
  desc,
  src,
}: {
  name: string;
  desc: string;
  src?: any;
}) => {
  return (
    <div className="flex flex-row items-center">
      {src ? (
        <img
          src={src}
          alt={`${name} - photo`}
          className="w-24 h-24 rounded-full"
        />
      ) : (
        <div className="w-24 h-24 rounded-full bg-gray-200" />
      )}

      <div className="ml-2 font-main max-w-[250px]">
        <p className="font-bold">{name}</p>
        <p className="text-sm">{desc}</p>
      </div>
    </div>
  );
};

const ManifestoPage: React.FC = () => {
  return (
    <div className="bg-cyan-100 p-10 h-fit">
      <div className="mx-auto sm:mx-52 font-main text-black">
        <Link className="block -ml-2" to="/join-us">
          <IoIosArrowRoundBack size={50} />
        </Link>
        <h1 className="text-xl sm:text-3xl font-bold -mt-2 mb-8">Manifesto.</h1>

        <h1 className="text-3xl font-bold">
          Inventons la Médecine de Demain Ensemble
        </h1>

        <div className="my-6">
          <p className="">
            Nous pensons que le futur de la médecine s'écrit maintenant et que
            l'IA y joue un grand rôle. Nous pensons que c'est aux médecins
            d'écrire les prochaines pages des solutions qui vont servir les
            patients et notre profession.
          </p>

          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: steto,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={200}
            width={200}
          />

          <p className="mt-8 font-bold text-xl sm:text-3xl">
            Nous voulons permettre aux médecins de suivre leurs patients
          </p>

          <div className="bg-black max-w-[300px] p-4 mx-auto my-10 rounded-lg">
            <div className="mb-6 flex flex-row items-center">
              <MdCheck className="text-cyan-500 mr-1" size={80} />
              <p className="text-white">
                Sans faire de paperasse (promis, avec ça on ne plaisante pas)
              </p>
            </div>

            <div className="mb-6 flex flex-row items-center">
              <MdCheck className="text-cyan-500 mr-1" size={80} />
              <p className="text-white">
                Avec des outils qui permettent d'avoir une relation de confiance
              </p>
            </div>
            <div className="flex flex-row items-center">
              <MdCheck className="text-cyan-500 mr-1" size={60} />
              <p className="text-white">
                En y mettant l'excellence des soins à la française
              </p>
            </div>
          </div>

          <p className="my-6 font-bold text-xl sm:text-3xl">
            La grande majorité des morts prématurées sont évitables
          </p>

          <p>
            Nous voyons tous, tous les jours, des patients perdus dans le
            système de soins; des gens qui ne savent pas quoi faire d'un
            symptôme et qui ne sont pas soignés à temps, des malades qui faute
            d'avoir compris leur pathologie développent toutes les
            complications, des personnes qui pleurent non pas à cause de leurs
            problèmes de santé mais à cause du système lui même
          </p>

          <p className="mt-4">
            Nous souhaitons éviter les souffrances humaines liées à ces aspects.
            Nous pensons pour cela qu'il faut réinventer la manière de suivre
            les patients. Nous voulons aussi arrêter de voir des confrères
            souffrir de burn-out parce que la gestion des soins est inhumaine.
            Oui, on préfère voir les collègues en blouse qu'en PLS sur le divan
            d'un psy.
          </p>

          <p className="mt-4">
            Nous imaginons un futur de la santé où des soins de très haute
            qualité délivrés par des médecins assistés sur les tâches non
            médicales pourront être accessibles à tous.
          </p>
        </div>

        <Link
          className="block mx-auto w-fit bg-black font-bold py-3 px-10 rounded-3xl"
          to="/join-us"
        >
          <span className="text-white">Médecins, rejoignez-nous</span>
        </Link>

        <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 gap-6">
          <Profile
            name="Anne Laure Rousseau"
            desc="Médecin à l'Hôpital Européen Georges Pompidou"
            src={AL}
          />
          <Profile
            name="Stéphane Giraud"
            desc="Médecin généraliste-interniste, Expert en téléconsultation"
            src={SG}
          />
          <Profile name="Alexandre Brel" desc="Directeur Technique" src={AB} />
          <Profile name="Vous" desc="" />
        </div>
      </div>
    </div>
  );
};

export default ManifestoPage;
