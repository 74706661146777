import React from 'react';
import moment from 'moment';
import { useGetConversationsQuery } from '@@/services/doctolib';

const ConversationCard: React.FC<{ conversation: Doctolib.Conversation }> = ({
  conversation,
}) => {
  console.log(conversation);
  return (
    <div className="inline-block align-top m-2 w-[300px] p-2 border border-gray-200 rounded-xl">
      <p className="font-bold">{conversation.profile.display_name}</p>
      <p>
        Pour {conversation.master_patient.first_name}&nbsp;
        {conversation.master_patient.last_name}
      </p>
      <p className="truncate">
        Message chiffré par Tanker:&nbsp;
        {conversation.last_message.tanker_encrypted_body}
      </p>
    </div>
  );
};

const ConversationsPage: React.FC = () => {
  const { data: conversations, isLoading } = useGetConversationsQuery();

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      {conversations?.map((conversation) => (
        <ConversationCard key={conversation.id} conversation={conversation} />
      ))}
    </div>
  );
};

export default ConversationsPage;
