import React, { useMemo, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { CiEdit, CiExport } from 'react-icons/ci';
import { useGetPatientQuery, useGetMeQuery } from '@@/services/user';
import { useGetPlanQuery } from '@@/services/plan';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import Plan from '@@/components/PDF/Plan';

const PlanPage: React.FC = () => {
  const { patientId, planId } = useParams();
  const { data: patient } = useGetPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: plan } = useGetPlanQuery(planId || '', { skip: !planId });
  const { data: me } = useGetMeQuery();

  const planPDF = useMemo(
    () => (
      <Plan
        plan={plan}
        patient={patient}
        practitioner={plan?.practitioner || me}
      />
    ),
    [plan, patient, plan?.practitioner, me],
  );

  const [pdf, updatePdf] = usePDF({
    document: planPDF,
  });

  useEffect(() => {
    updatePdf(planPDF);
  }, [updatePdf, planPDF]);

  const downloadButton = useMemo(() => {
    if (pdf.loading || !pdf.url || !planId) {
      return null;
    }

    return (
      <a
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        download={`Plan-de-soins-${patient?.firstName}-${patient?.lastName}.pdf`}
        href={pdf.url}
      >
        <CiExport size={22} />
        <span className="ml-1">{pdf.error ? pdf.error : 'Exporter'}</span>
      </a>
    );
  }, [pdf, planId]);

  if (!plan || !patient || !me) {
    return null;
  }

  return (
    <div className="my-4 w-full font-main relative h-full">
      <div className="flex flex-row justify-between mx-4 text-main h-full">
        <PDFViewer style={{ width: '800px', height: '100%' }}>
          {planPDF}
        </PDFViewer>
        <div className="flex flex-row space-x-4">
          {downloadButton}
          <Link
            className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
            to={`/patients/${patientId}/plans/${planId}/edit`}
          >
            <CiEdit size={22} />
            <span className="ml-1">Editer</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PlanPage;
